import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
library.add(fas);

function TimeLogTab() {
  return (
    <>
      <div className="row">
        <div class="col-md-12">
          <h6 className="page-title">Time Log</h6>
        </div>

        <div class="col-md-12">
          <Table responsive>
            <thead>
              <tr>
                <th>Employees</th>
                <th>Time Logged </th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              <tr id="cat-1">
                <td>XYZ</td>
                <td>24 HR</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default TimeLogTab;
