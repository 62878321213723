import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import SubTaskModal from "../../../../Modals/SubTaskModal/SubTaskModal";
library.add(fas);

function SubTaskTab() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="row">
        <div class="col-md-12">
          <p
            className="lg-text text-success"
            onClick={() => setModalShow(true)}
          >
            <FontAwesomeIcon className="me-2" icon="fa-solid fa-plus " /> Add
            Sub Task
          </p>

          <SubTaskModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>

        <div class="col-md-12">
          <h6 className="xl-text">
            <FontAwesomeIcon icon="fa-solid fa-square-check" /> Sub Task{" "}
          </h6>
          <li>
            <ul class="list-group b-t" id="sub-task-list"></ul>
          </li>
        </div>
      </div>
    </>
  );
}

export default SubTaskTab;
