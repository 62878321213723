import React, { useContext, useState, useEffect } from "react";
import { Form, Modal } from 'react-bootstrap'
import '../../../CommonCss/ModalCommonCss.css'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditLottieModal from '../../../commonLottie/EditLottieModal'
import { Context } from "../../../../../utils/context";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from 'react-select';
import AddAssitModal from "./AddAssitModal";
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";



const EditModal = (props) => {

    const id = props?.id;
    console.log("idididi", id);

    const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });
    const [showAddAsset, setShowAddAsset] = useState(false);

    const { postData, getData, Select2Data } = useContext(Context);
    const { register, handleSubmit, reset, setValue, control, watch, formState: { errors }, getValues } = useForm();
    const { fields } = useFieldArray({ control, name: "assets" });


    const [departments, setDepartments] = useState([]);
    const [users, setUsers] = useState([]);
    const [assets, setAssets] = useState([]);

    const onSubmit = async (data) => {
        console.log("data:----", data);

        try {
            const sendData = new FormData();
            sendData.append("department_id", data?.department_id?.value);
            sendData.append("employee_id", data?.employee_id?.value);

            // data?.asset_id.forEach(asset => sendData.append("asset_id", asset.value));

            const allAssets = data.assets.map((item) => ({
                id: item.id,
                asset_id: item.asset_id?.value,
            })) || [];

            sendData.append("assets", JSON.stringify(allAssets));

            console.log("sendData", sendData);


            const response = await postData(`/hrms/assign-asset/${id}`, sendData);
            if (response?.success) {
                setShowLottie({ show: true, msg: response?.message, success: response?.success });
                reset();
            } else {
                setShowLottie({ show: true, msg: response?.message, success: response?.success });
            }
            setTimeout(() => {
                setShowLottie({ show: false, msg: "", success: false });
                props?.onHide();
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    };

    const getEditData = async () => {
        console.log("id :---", id);
        const response = await getData(`/hrms/assign-asset/${id}`);
        if (response?.success) {
            reset(response?.data)
        }
    };

    console.log("getValues", getValues());


    const getDepartments = async () => {
        const response = await getData("/masters/alldepartments");
        if (response?.success) {
            setDepartments(await Select2Data(response?.data, "department_id"));
        }
    };

    const getEmployees = async (id) => {
        const response = await getData(`/masters/allemployeebydept/${id}`);
        if (response?.success) {
            setUsers(await Select2Data(response?.data, "employee_id"));
        }
    };

    const getShifts = async () => {
        const response = await getData("/hrms-masters/all-assets");
        if (response?.success) {
            setAssets(await Select2Data(response?.data, "asset_id"));
        }
    };

    useEffect(() => {
        getDepartments();
        getShifts();
    }, []);

    useEffect(() => {
        getEditData();
    }, [id]);


    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='text-center'>
                        <h4 className='modal-title-common'>Edit Assets</h4>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='below-bodyy'>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Department</Form.Label>
                                    <Controller
                                        name="department_id" // name of the field
                                        {...register("department_id", {
                                            required: "Select Employee",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                isDisabled
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.department_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={departments}
                                                onChange={(item) => {
                                                    setValue("department_id", item);
                                                    getEmployees(item?.value);
                                                    setValue("employee_id", "")
                                                }}
                                            />
                                        )}
                                    />

                                    {errors?.department_id && (
                                        <span className="text-danger">
                                            {errors?.department_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Employee</Form.Label>
                                    <Controller
                                        name="employee_id" // name of the field
                                        {...register("employee_id", {
                                            required: "Select Employee",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                isDisabled
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.employee_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={users}
                                            />
                                        )}
                                    />

                                    {errors?.employee_id && (
                                        <span className="text-danger">
                                            {errors?.employee_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </div>

                            {fields.map((asset, index) => (
                                <div key={index}>
                                    <Form.Group className="mb-3" controlId="assignedAssets">
                                        <Form.Label>Assigned Assets</Form.Label>

                                        <Controller
                                            name="assets[${index}].asset_id" // name of the field
                                            {...register(`assets[${index}].asset_id`, {
                                                required: "Select Assets",
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.assets?.[index]?.asset_id
                                                                ? "red"
                                                                : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={assets}

                                                />
                                            )}
                                        />
                                        {errors.assets?.[index]?.asset_id && (
                                            <span className="text-danger">{errors.assets[index].asset_id.message}</span>
                                        )}

                                        <div className="text-end" onClick={() => { setShowAddAsset(true) }}>
                                            <label className="assign-more mt-1">Add New Assets
                                                <FontAwesomeIcon className='ms-3' icon={faCirclePlus}
                                                />
                                            </label>
                                        </div>
                                    </Form.Group>
                                </div>
                            ))}



                            <div className='text-center mt-5 mb-5'>
                                <button className='cancel-button me-2' type="button" onClick={props.onHide}>Cancel</button>
                                <button className='save-button' type="submit">Save</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <AddedLottieModal
                pageTitle="Designation"
                message={showLottie.msg}
                show={showLottie.show}
                success={showLottie.success}
                onHide={() => setShowLottie({ show: false, msg: "", success: false })}
            />

            <AddAssitModal
                show={showAddAsset}
                onHide={() => { setShowAddAsset(false); getShifts() }}
            />

        </section>
    )
}

export default EditModal