import React, { useState, useRef } from "react";
import "../../style/Modals/Modals.css";
import "../../style/Common/Common.css";
import "../../style/Form/Form.css";
import "../../style/Tables/Tables.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import JoditEditor from "jodit-react";
library.add(fas);

function NewDiscussionModal(props) {
    const editor = useRef(null);
    const [content, setContent] = useState("");
    
  return (
    <>
    <Modal
      {...props}
      size="lg"
      className="table_modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        New Discussion
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="field_top_space">
          <div className="row">
            <div className="col-md-12">
              <Form>
                <Form.Group controlId="Category">
                  <Form.Label className="">Category</Form.Label>
                  <Form.Select as="select" name="Category">
                    <option value="1">--</option>
                    <option value="2">Java</option>
                    <option value="3">Development</option>
                    <option value="5">Digital Marketing</option>
                    <option value="6">Mobile App Development</option>
                    <option value="7">Internal Development</option>
                    <option value="8">WordPress</option>
                    <option value="9">E-Commerce Development</option>
                    <option value="10">Internal Designing</option>
                    <option value="11">Designing</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>

        <div className="field_top_space">
          <div className="row">
            <div className="col-md-12">
              <Form>
                <Form.Group controlId="Title">
                  <Form.Label className="required">Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="Title"
                    placeholder="Enter Title"
                  />
                  <Form.Control type="hidden" name="Title" />
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>

        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Project Summary
                                  </Form.Label>
                                  <JoditEditor
                                    ref={editor}
                                    value={content}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) =>
                                      setContent(newContent)
                                    } // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => { }}
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        </div>

       

        

        <Form>
          <Button className="btn btn-success save-btn my-2" type="submit">
            <FontAwesomeIcon icon="fa-solid fa-check" /> Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  </>
  )
}

export default NewDiscussionModal