import React, { useState, useCallback, useRef, useContext, useEffect } from "react";

import JoditEditor from "jodit-react";

// Addproject css link
import "./Addproject.css";
import "../style/Common/Common.css"
import "../style/Form/Form.css"
import "../style/Modals/Modals.css"
import "../style/Tables/Tables.css"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { useDropzone } from "react-dropzone";
import ProjectCategoryModal from "../Modals/ProjectCategoryModal/ProjectCategoryModal"
import AddEmployeeInfoModal from "../Modals/AddEmployeeInfoModal/AddEmployeeInfoModal"
import AddClientInfoModal from "../Modals/AddClientInfoModal/AddClientInfoModal";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../../utils/context";

library.add(fas);

function Addproject() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const { postData, getData } = useContext(Context);

  const [Category, setCategory] = useState([]);
  const [Client, setClient] = useState([]);
  const [ProjectStatus, setProjectStatus] = useState([]);
  const [Currency, setCurrency] = useState([]);
  const [Users, setUsers] = useState([]);

  const [UsersOption, setUsersOption] = useState([]);
  const [selectedservices, setSelectedservices] = useState([]);


  const onDrop = useCallback((acceptedFiles) => {
    // Handle file upload logic here
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // const handleSubmit = () => {
  //   // Handle form submission logic here
  //   console.log("Form submitted!");
  // };

  const editor = useRef(null);
  const [content, setContent] = useState("");



  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState({
    name: "",
    without_deadline: "",
    manual_time_logs: "",
    users_id: "",
    client_id: "",
    currency_id: "",
    note: "",
    summary: "",
    client_manage_task: "",
    project_status_id: "",
    deadline: new Date(),
    start_date: new Date(),
    project_category_id: "",
    project_budget: "",
    hours_allocated: "",
    file: null
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const summaryEditorRef = useRef(null);
  const noteEditorRef = useRef(null);





  const handleChange = (e, contentName) => {
    // if (e?.name == "users_id") {
    //   // const selectedUserIds = e ? e.map(option => option.value) : [];
    //   // setFormData(prevFormData => ({
    //   //     ...prevFormData,
    //   //     [e.name]: selectedUserIds
    //   // }));
    //   setFormData({ ...formData, [e.name]: e });
    // } else
    if (e?.name == "project_category_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "client_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "currency_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "project_status_id") {
      setFormData({ ...formData, [e.name]: e });
    }

    else {
      // Handle other types of input changes
      if (e && e.target) {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
          setFormData({ ...formData, [name]: checked ? "1" : "0" });
        } else if (type === "date") {
          const dateValue = new Date(value);
          setFormData({ ...formData, [name]: dateValue });
        } else if (type === "file") {
          if (files.length > 0) {
            setFormData({ ...formData, [name]: files[0] });
          }
        } else {
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setFormData({ ...formData, [contentName]: e });
      }
    }
  };

  //   const handleChange = (e, contentName) => {
  //     if (e?.name === "users_id") {
  //         // Extract an array of selected user IDs
  //         const selectedUserIds = e ? e.map(option => option.value) : [];
  //         setFormData(prevFormData => ({
  //             ...prevFormData,
  //             [e.name]: selectedUserIds
  //         }));
  //     }  else
  //        if(e?.name == "project_category_id"){
  //         setFormData({ ...formData, [e.name]: e });
  //       }else if(e?.name == "client_id"){
  //         setFormData({ ...formData, [e.name]: e });
  //       }else if(e?.name == "currency_id"){
  //         setFormData({ ...formData, [e.name]: e });
  //       }else if(e?.name == "project_status_id"){
  //         setFormData({ ...formData, [e.name]: e });
  //       }
  //     else {
  //         // Handle other types of input changes
  //         if (e && e.target) {
  //             const { name, value, type, checked, files } = e.target;
  //             if (type === "checkbox") {
  //                 setFormData(prevFormData => ({
  //                     ...prevFormData,
  //                     [name]: checked ? "1" : "0"
  //                 }));
  //             } else if (type === "date") {
  //                 const dateValue = new Date(value);
  //                 setFormData(prevFormData => ({
  //                     ...prevFormData,
  //                     [name]: dateValue
  //                 }));
  //             } else if (type === "file") {
  //                 if (files.length > 0) {
  //                     setFormData(prevFormData => ({
  //                         ...prevFormData,
  //                         [name]: files[0]
  //                     }));
  //                 }
  //             } else {
  //                 setFormData(prevFormData => ({
  //                     ...prevFormData,
  //                     [name]: value
  //                 }));
  //             }

  //         }
  //     }
  // };




  const handleService = async (e) => {
    setSelectedservices(e);
  };

  useEffect(() => {
    // const total = selectedservices.reduce((acc, service) => {
    //   return acc + service.price;
    // }, 0);
    const updatedFormData = {
      ...formData,
      ["users_id"]: selectedservices,
      // ["total"]: total,
    };
    setFormData(updatedFormData);
  }, [selectedservices]);

  // console.log(" user" + formData.users_id)
  const users_idData = formData.users_id;
  // const usersIdValues = users_idData?.map(item => item.value);

  // console.log("array" +usersIdValues);

  console.log(formData);
  const handleSubmit = async (e) => {
    // console.log("kalyani");
    e.preventDefault();

    const validationErrors = validateForm();
    console.log(formData)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        console.log(formData.file);
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("without_deadline", formData.without_deadline);
        formDataToSend.append("manual_time_logs", formData.manual_time_logs);
        // formDataToSend.append("users_id", formData.users_id.value);
        // formDataToSend.append("users_id",  JSON.stringify(UsersOption));
        if (Array.isArray(users_idData)) {
          const usersIdValues = users_idData.map(item => item.value);
          usersIdValues.forEach(value => {
            formDataToSend.append("users_id", value);
          });
          console.log(usersIdValues); // [14, 2, 4]
        } else {
          console.error('users_idData is not an array.');
        }

        formDataToSend.append("client_id", formData.client_id.value);
        formDataToSend.append("currency_id", formData.currency_id.value);
        formDataToSend.append("note", formData.note);
        formDataToSend.append("summary", formData.summary);
        formDataToSend.append("client_manage_task", formData.client_manage_task);
        formDataToSend.append(
          "project_status_id",
          formData.project_status_id.value
        );
        formDataToSend.append("deadline", formData.deadline);
        formDataToSend.append("start_date", formData.start_date);
        formDataToSend.append("project_category_id", formData.project_category_id.value);
        formDataToSend.append("project_budget", formData.project_budget);
        formDataToSend.append("hours_allocated", formData.hours_allocated);
        formDataToSend.append("file", formData.file);

        console.log(formDataToSend);
        const response = await postData("/projectmanagement/project/", formDataToSend);
        // if (response.success) {
        //   setShowModal({ code: response.code, message: response.message  });
        //   setTimeout(() => {
        //     setShowModal(0);
        //   }, 1000);
        // } else {
        //   setShowModal({ code: response.code, message: response.message });
        //   setTimeout(() => {
        //     setShowModal(0);
        //   }, 1000);
        // }

        // await setErrors();
        if (response?.success) {
          console.log("Form Submited :- ", response);
        } else {
          console.error("Submission failed:", response?.error);
          // Handle submission failure
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const GetAllCategary = async () => {
    const response = await getData("/project_management/allprojectcategory");

    const options = [];
    if (response.success) {
      response.data.forEach((data) => {
        options.push({
          value: data.id,
          name: "project_category_id",
          label: data.name,
        });
      });
    }
    setCategory(options);
  };
  useEffect(() => {
    GetAllCategary();

  }, []);


  //   const handleMultiChange = ( selectedOptions) => {
  //     // If selectedOptions is null or undefined, set an empty array
  //     if (selectedOptions?.name === "user_id") {
  //       // If selectedOptions is null or undefined, set an empty array
  //       const selectedCategoryIds = selectedOptions ? selectedOptions.map(option => option.value) : [];

  //       // Update the formData with the selected category IDs
  //       setFormData(prevFormData => ({
  //           ...prevFormData,
  //           [selectedOptions.name]: selectedCategoryIds
  //       }));
  //   }
  // };

  // const handleMultiChange = (selectedOption) => {
  //   setUsers(selectedOption);

  //   const facilityNames = selectedOption?.map(facility => facility?.label);
  //   setFormData({ ...formData, user: facilityNames });
  // };

  const GetAllClient = async () => {
    const response = await getData("/project_management/allclients");

    const options = [];
    if (response.success) {
      response.data.forEach((data) => {
        options.push({
          value: data.id,
          name: "client_id",
          label: data.client_name,
        });
      });
    }
    setClient(options);
  };


  useEffect(() => {
    GetAllClient();
  }, []);


  const GetAllProjectStatus = async () => {
    const response = await getData("/project_management/allprojectstatus");

    const options = [];
    if (response.success) {
      response.data.forEach((data) => {
        options.push({
          value: data.id,
          name: "project_status_id",
          label: data.name,
        });
      });
    }
    setProjectStatus(options);
  };

  useEffect(() => {
    GetAllProjectStatus();

  }, []);

  const GetAllCurrency = async () => {
    const response = await getData("/project_management/allcurrency");

    const options = [];
    if (response.success) {
      response.data.forEach((data) => {
        options.push({
          value: data.id,
          name: "currency_id",
          label: data.name,
        });
      });
    }
    setCurrency(options);
  };


  useEffect(() => {
    GetAllCurrency();
  }, []);

  const GetAllUser = async () => {
    const response = await getData("/project_management/allusers");

    const options = [];
    if (response.success) {
      response.data.forEach((data) => {
        options.push({
          value: data.id,
          name: "users_id",
          label: data.name,
        });
      });
    }
    setUsers(options);
  };

  // const GetAllUser = async () => {
  //   try {
  //     const response = await getData("/project_management/allusers");

  //     if (response?.success) {
  //       const facilitiesData = response.data.data;

  //       if (Array.isArray(facilitiesData)) {
  //         const options = facilitiesData?.map(user => ({
  //           value: user?.id,
  //           label: user?.name,
  //         }));
  //         setUsers(options);
  //       } else {
  //         console.error("Invalid data format for facilities. Expected an array.");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching facilities:", error);
  //   }
  // };


  useEffect(() => {
    GetAllUser();
  }, []);

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "project Name is required";
    }

    // if (!formData.without_deadline) {
    //   errors.without_deadline = "Description is required";
    // }
    // if (!formData.manual_time_logs) {
    //   errors.manual_time_logs = "Hostel is required";
    // }

    if (!formData.users_id) {
      errors.users_id = "Project members is required";
    }

    if (!formData.client_id) {
      errors.client_id = "client is required";
    }

    if (!formData.currency_id) {
      errors.currency_id = "currency is required";
    }
    if (!formData.note) {
      errors.note = "Note is required";
    }

    if (!formData.summary) {
      errors.summary = "Project Summery is required";
    }

    if (!formData.client_manage_task) {
      errors.client_manage_task = "Task is required";
    }

    if (!formData.deadline) {
      errors.deadline = "end date is required";
    }
    if (!formData.start_date) {
      errors.start_date = "start date is required";
    }

    if (!formData.project_category_id) {
      errors.project_category_id = "project categary is required";
    }

    if (!formData.project_budget) {
      errors.project_budget = "project budget is required";
    }


    if (!formData.hours_allocated) {
      errors.hours_allocated = "Hours allocated  allocation is required";
    }
    if (!formData.project_status_id) {
      errors.project_status_id = "Project Status is required";
    }


    // if (!formData.file) {
    //   errors.file = "file is required";
    // }


    return errors;
  }
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  }




  return (
    <>
      <div className="Add_Project_main">
        <div className="container-fluid p-0">
          <div className="add_project">
            {/* heading section start */}
            <div className="bg-title">
              <div className="row ">
                <h5 className="page-title">
                  <FontAwesomeIcon icon="fa-solid fa-layer-group" /> Projects
                </h5>
              </div>
            </div>
            {/* heading section end */}

            {/*Add_project_from section start */}
            <div className="Add_project_from">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-inverse">
                    <div className="panel-heading">
                      <div className="heading-holder">
                        <h6 className="heading-text"> Add Project</h6>
                      </div>
                      <div className="Btn-right">
                        <Dropdown>
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            className="btn-sm btn-info waves-effect dropdown-toggle waves-light"
                          >
                            Template
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              Test
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              Demo
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <div className="panel-body">
                      <div className="form-body">
                        <h5 className="box-title ">Project Info</h5>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-6">
                              <Form>
                                <Form.Group controlId="project_name">
                                  <Form.Label className="required">
                                    Project Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter project name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name="name"
                                  />
                                </Form.Group>
                                {errors?.name && (
                                  <span style={errorStyle}>{errors?.name}</span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-6">
                              <Form>
                                <Form.Group controlId="category_id">
                                  <Form.Label className="control-label">
                                    Project Category
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-gear"
                                      className="text-info gear-icon"
                                      onClick={() => setModalShow(true)}

                                    />
                                  </Form.Label>
                                  {/* <Form.Select
                                    as="select"
                                    name="project_category_id"
                                    value={formData.project_category_id}
                                    onChange={handleChange} // Pass the handleChange function directly
                                  > */}
                                  {/* <option value="">Select category...</option> Add a default option */}
                                  {/* {Category.map(option => (
                                      // <option key={option.value} value={option.value}>
                                      //   {option.label}
                                      // </option>
                                    ))} */}
                                  {/* </Form.Select> */}
                                  <Select
                                    name="project_category_id"
                                    className="input-mandatory"
                                    // onChange={handleChange}
                                    onChange={(selectedOption) => handleChange(selectedOption, "project_category_id")}
                                    value={formData.project_category_id}
                                    // options={Category?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "project_category_id",
                                    //   label: Option.name,
                                    // }))}
                                    options={Category}
                                  />
                                </Form.Group>

                                <ProjectCategoryModal
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                />
                                {errors?.project_category_id && (
                                  <span style={errorStyle}>{errors?.project_category_id}</span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="start_date">
                                  <Form.Label className="required">
                                    Start Date
                                  </Form.Label>
                                  <div>
                                    <DatePicker
                                      selected={startDate}

                                      // value={formData.start_date}
                                      // onChange={(date) => {setStartDate(date); handleChange(date); } }
                                      // onChange={(date) => { setStartDate(date); handleChange({ target: { name: "start_date", value: date } }); }}

                                      name="start_date"
                                      id="start_date"
                                      autoComplete="off"
                                      className="form-control"
                                      onChange={(date) => {
                                        // Format the date to "MM/DD/YYYY" format
                                        const formattedDate = date.toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: '2-digit',
                                          day: '2-digit'
                                        });

                                        setStartDate(date); // Update local state
                                        handleChange({ target: { name: "start_date", value: formattedDate } }); // Update formData state
                                      }}
                                    />
                                  </div>
                                </Form.Group>
                                {errors?.start_date && (
                                  <span style={errorStyle}>{errors?.start_date}</span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4" id="deadlineBox">
                              <Form>
                                <Form.Group controlId="deadline">
                                  <Form.Label className="required">
                                    End Date
                                  </Form.Label>
                                  <div>
                                    <DatePicker
                                      selected={endDate}
                                      // value={formData.deadline}
                                      // // onChange={(date) => setEndDate(date)}
                                      // // onChange={(date) => {
                                      // //   setEndDate(date); // Update local state
                                      // //   handleChange(date); // Update formData state
                                      // // }}
                                      // onChange={(date) => { setEndDate(date); handleChange({ target: { name: "deadline", value: date } }); }}
                                      name="deadline"
                                      id="deadline"
                                      autoComplete="off"
                                      className="form-control"
                                      onChange={(date) => {
                                        // Format the date to "MM/DD/YYYY" format
                                        const formattedDate = date.toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: '2-digit',
                                          day: '2-digit'
                                        });

                                        setEndDate(date); // Update local state
                                        handleChange({ target: { name: "deadline", value: formattedDate } }); // Update formData state
                                      }}

                                    />
                                  </div>
                                </Form.Group>
                                {errors?.deadline && (
                                  <span style={errorStyle}>{errors?.deadline}</span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4">
                              <Form.Group>
                                <div className="checkbox checkbox-info">
                                  <Form.Check
                                    type="checkbox"
                                    id="without_deadline"
                                    name="without_deadline"
                                    // value="true"
                                    label="Without deadline"
                                    value={formData.without_deadline}
                                    onChange={handleChange}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <Form.Group controlId="">
                              <div className=" checkbox-info">
                                <Form.Check
                                  type="checkbox"
                                  id="Allow_manual_time_logs"
                                  // name="Allow manual time logs?"
                                  // value="true"
                                  name="manual_time_logs"
                                  label="Allow manual time logs?"
                                  value={formData.manual_time_logs}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="required">
                                    Add Project Members{" "}
                                  </Form.Label>
                                  <FontAwesomeIcon
                                    className="btn btn-xs btn-success btn-outline plus-icon"
                                    icon="fa-solid fa-plus"
                                    onClick={() => setModalShow2(true)}

                                  />
                                  <Select
                                    name="users_id"
                                    className="input-mandatory"
                                    // onChange={handleChange}
                                    // value={formData.users_id}
                                    // options={Users?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "users_id",
                                    //   label: Option.name,
                                    // }))}
                                    // onChange={(selectedOption) =>
                                    //   handleChange(selectedOption)
                                    // }                                       
                                    options={Users}
                                    isMulti
                                    value={selectedservices}
                                    onChange={handleService}
                                  />

                                </Form.Group>

                                <AddEmployeeInfoModal
                                  show={modalShow2}
                                  onHide={() => setModalShow2(false)}
                                />
                                {errors?.users_id && (
                                  <span style={errorStyle}>{errors?.users_id}</span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        {/*   Project Summary summer code section start */}
                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Project Summary
                                  </Form.Label>
                                  <JoditEditor
                                    ref={summaryEditorRef}
                                    value={formData.summary}
                                    // value={content}
                                    name="summary"
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) =>
                                      setContent(newContent)
                                    } // preferred to use only this option to update the content for performance reasons
                                    // onChange={(newContent) => {handleChange(newContent) }}
                                    // onChange={handleChange}
                                    onChange={(newContent) => handleChange(newContent, "summary")}

                                  />
                                </Form.Group>
                                {errors?.summary && (
                                  <span style={errorStyle}>{errors?.summary}</span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>
                        {/*   Project Summary summer code section end */}

                        {/*   Note Summary summer code section start */}
                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Note
                                  </Form.Label>
                                  <JoditEditor
                                    ref={noteEditorRef}
                                    name="note"
                                    value={formData.note}
                                    // value={content}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) =>
                                      setContent(newContent)
                                    } // preferred to use only this option to update the content for performance reasons
                                    // onChange={(newContent) => handleChange(newContent)}
                                    // onChange={handleChange}
                                    onChange={(newContent) => handleChange(newContent, "note")}

                                  />
                                </Form.Group>
                                {errors?.note && (
                                  <span style={errorStyle}>{errors?.note}</span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>
                        {/*   Note Summary summer code section end */}

                        <div className="heading">
                          <h5 class="box-title ">Client Info</h5>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-6">
                              <Form>
                                <Form.Group controlId="category_id">
                                  <Form.Label className="control-label">
                                    Select Client
                                    <FontAwesomeIcon
                                      className="btn btn-xs btn-success btn-outline plus-icon"
                                      icon="fa-solid fa-plus"
                                      onClick={() => setModalShow3(true)}

                                    />
                                  </Form.Label>


                                  <Select
                                    name="client_id"
                                    className="input-mandatory"
                                    onChange={handleChange}
                                    value={formData.client_id}
                                    // options={Category?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "project_category_id",
                                    //   label: Option.name,
                                    // }))}
                                    options={Client}
                                  />
                                  {/* <Form.Select as="select" name="client_id" value={formData.client_id} onChange={handleChange}> */}
                                  {/* <option value="1">Laravel</option>
                                    <option value="2">Java</option>
                                    <option value="3">Development</option>
                                    <option value="5">Digital Marketing</option>
                                    <option value="6">
                                      Mobile App Development
                                    </option>
                                    <option value="7">
                                      Internal Development
                                    </option>
                                    <option value="8">WordPress</option>
                                    <option value="9">
                                      E-Commerce Development
                                    </option>
                                    <option value="10">
                                      Internal Designing
                                    </option>
                                    <option value="11">Designing</option>*/}

                                </Form.Group>

                                <AddClientInfoModal
                                  show={modalShow3}
                                  onHide={() => setModalShow3(false)}
                                />
                                {errors?.client_id && (
                                  <span style={errorStyle}>{errors?.client_id}</span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-6">
                              <Form.Group>
                                <div className="checkbox checkbox-info">
                                  <Form.Check
                                    type="checkbox"
                                    id="Client_manage"
                                    // name="Client can manage tasks of this project"
                                    name="client_manage_task"
                                    // value="true"
                                    value={formData.client_manage_task}
                                    onChange={handleChange}
                                    label="Client can manage tasks of this project"
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>

                        <div className="heading">
                          <h5 class="box-title ">Budget Info</h5>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Project Budget
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="project_budget"
                                    placeholder="Enter Project Budget"
                                    value={formData.project_budget}
                                    onChange={handleChange}
                                  />
                                  {/* <Form.Control
                                    type="hidden"
                                    name="project_Budget"
                                  /> */}
                                </Form.Group>
                                {errors?.project_budget && (
                                  <span style={errorStyle}>{errors?.project_budget}</span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label"  >
                                    Currency
                                  </Form.Label>


                                  <Select
                                    name="currency_id"
                                    className="input-mandatory"
                                    onChange={handleChange}
                                    value={formData.currency_id}
                                    // options={Category?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "project_category_id",
                                    //   label: Option.name,
                                    // }))}
                                    options={Currency}
                                  />
                                  {/* <Form.Select as="select" name="currency_id" value={formData.currency_id}
                                    onChange={handleChange}  > */}
                                  {/* <option value="1">Rupee (INR)</option>
                                    <option value="2">Java</option>
                                    <option value="3">Development</option>
                                    <option value="5">Digital Marketing</option>
                                    <option value="6">
                                      Mobile App Development
                                    </option>
                                    <option value="7">
                                      Internal Development
                                    </option>
                                    <option value="8">WordPress</option>
                                    <option value="9">
                                      E-Commerce Development
                                    </option>
                                    <option value="10">
                                      Internal Designing
                                    </option>
                                    <option value="11">Designing</option> */}

                                </Form.Group>
                                {errors?.currency_id && (
                                  <span style={errorStyle}>{errors?.currency_id}</span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Hours Allocated
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="hours_allocated"
                                    placeholder="Enter Hours Allocated"
                                    value={formData.hours_allocated}
                                    onChange={handleChange}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name="Hours_Allocated"
                                  />
                                </Form.Group>
                                {errors?.hours_allocated && (
                                  <span style={errorStyle}>{errors?.hours_allocated}</span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label" >
                                    Project Status
                                  </Form.Label>

                                  <Select
                                    name="project_status_id"
                                    className="input-mandatory"
                                    // onChange={handleChange}
                                    onChange={(selectedOption) => handleChange(selectedOption, "project_status_id")}
                                    value={formData.project_status_id}
                                    // options={Category?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "project_category_id",
                                    //   label: Option.name,
                                    // }))}
                                    options={ProjectStatus}
                                  />

                                  {/* <Form.Select as="select"
                                    name="send_task_notification_to_client" // Add a name to identify this input in the formData
                                    onChange={handleChange} // Assign handleChange function to handle changes
                                    value={formData.send_task_notification_to_client} > */}
                                  {/* <option value="1">Not Started </option>
                                    <option value="2">Java</option>
                                    <option value="3">Development</option>
                                    <option value="5">Digital Marketing</option>
                                    <option value="6">
                                      Mobile App Development
                                    </option>
                                    <option value="7">
                                      Internal Development
                                    </option>
                                    <option value="8">WordPress</option>
                                    <option value="9">
                                      E-Commerce Development
                                    </option>
                                    <option value="10">
                                      Internal Designing
                                    </option>
                                    <option value="11">Designing</option> */}

                                </Form.Group>
                                {errors?.project_status_id && (
                                  <span style={errorStyle}>{errors?.project_status_id}</span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div className="field_top_space" id="file-upload-box">
                          <div className="row" id="file-dropzone">
                            <div className="col-md-12">
                              <Form.Group>
                                <div
                                  {...getRootProps()}
                                  className={`dropzone ${isDragActive ? "active" : ""
                                    }`}
                                >
                                  <Form.Control
                                    {...getInputProps()}
                                    name="file"
                                    id="file"
                                    type="file"
                                    onChange={handleChange}
                                  />
                                  {/* <input {...getInputProps()} /> */}
                                  <div className="dz-default dz-message">
                                    <span>Drop files here to upload</span>
                                  </div>
                                </div>
                              </Form.Group>
                              {/* {errors?.file && (
                                            <span style={errorStyle}>{errors?.file}</span>
                                          )} */}
                            </div>
                          </div>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-actions">
                                <Button
                                  type="submit"
                                  id="save-form"
                                  variant="success"
                                  onClick={handleSubmit}
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-check" />
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Add_project_from section end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Addproject;