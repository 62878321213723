import { Modal } from "react-bootstrap";
import "../../../../CommonCss/ModalCommonCss.css";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../../../../utils/context";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../../../common/ModalSave";
import AddedLottieModal from "../../../../commonLottie/AddedLottieModal";

const AddCourse = (props) => {
  const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });
  const { postData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const sendData = new FormData();
      sendData.append("name", data?.name);

      const response = await postData(`/hrms-masters/course`, sendData);
      console.log(response);

      if (response?.success) {
        props.onHide();
        setShowLottie({ show: true, msg: response?.message, success: true });
        reset();
      } else {
        setShowLottie({ show: true, msg: response?.message, success: false });
      }

      // Automatically reset showLottie after 1000 milliseconds
      setTimeout(() => {
        setShowLottie({ show: false, msg: "", success: false });
      }, 1000);
    } catch (error) {
      console.log(error);
      setShowLottie({ show: false, msg: "Error Submitting", success: false });
    }
  };

  return (
    <section className="modal-common-class-hrms">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
      >
        <Modal.Body>
          <div className="text-center">
            <h4 className="modal-title-common">Add Course</h4>
          </div>

          <Container>
            <Row>
              <Col md={12}>
                <Container>

                  <Form onSubmit={handleSubmit(onSubmit)} role="form">
                    <Row>
                      <Col lg={8} md={10} className=" mx-auto Add-content">
                        <Row>
                          <div className="main-form-section mt-5">
                            <Form.Label>Course Name</Form.Label>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  placeholder="Course"
                                  className={classNames("", {
                                    "is-invalid": errors?.name,
                                  })}
                                  {...register("name", {
                                    required: "Course is required",
                                    pattern: {
                                      value: /^[A-Za-z\s.-]+$/, // Updated regex to allow letters, spaces, periods, and hyphens
                                      message: "must only contain letters, spaces, '.', or '-'", // Updated error message
                                    },
                                  })}
                                  onKeyDown={(e) => {
                                    // Allow: backspace, tab, enter, escape, space, period, and hyphen
                                    if (
                                      e.key === "Backspace" ||
                                      e.key === "Tab" ||
                                      e.key === "Enter" ||
                                      e.key === "Escape" ||
                                      e.key === " " ||
                                      e.key === "." ||
                                      e.key === "-"
                                    ) {
                                      return;
                                    }

                                    // Allow letters (A-Z, a-z)
                                    if (/^[A-Za-z]$/.test(e.key)) {
                                      return;
                                    }

                                    e.preventDefault();
                                  }}
                                />
                              </InputGroup>
                              {errors.name && (
                                <span className="text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </Form.Group>
                          </div>
                        </Row>

                        <Row className="mt-5 pb-3">
                          <div className="d-flex justify-content-center">
                            <Link>
                              <button
                                className="cancel-button me-2"
                                onClick={() => { props.onHide(); reset() }}
                              >
                                Cancel
                              </button>
                            </Link>

                            <button
                              className="save-button"
                              name={"save"}
                              type="submit"
                            >
                              Save
                            </button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Form>

                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <AddedLottieModal
        pageTitle="Course"
        message={showLottie.msg}
        show={showLottie.show}
        success={showLottie.success}
        onHide={() => setShowLottie({ show: false, msg: "", success: false })}
      />
    </section>
  );
};

export default AddCourse;
