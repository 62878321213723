export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const RequiredIs = {
  roles: [1, 2, 3, 4, 5, 6, 7],
};

export const Roles = {
  SuperAdmin: 1,
  Admin: 2,
  TeleCaller: 3,
  SalesCoOrdinater: 4,
  SalesExecutive: 5,
  Complaint: 6,
  ServiceEngineer: 7,
  ProjectManager: 8,
  Employee: 9,
  TeamLeader: 10,
  HRMS: 11,
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const Select3Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
    contact_no: data?.contact_no,
    email: data?.email,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const IDS = {
  Role: {
    List: 1,
    Add: 2,
    Edit: 3,
    Delete: 4,
  },
  User: {
    List: 5,
    Add: 6,
    Edit: 7,
    Delete: 8,
  },
  Country: {
    List: 9,
    Add: 10,
    Edit: 11,
    Delete: 12,
  },
  State: {
    List: 13,
    Add: 14,
    Edit: 15,
    Delete: 16,
  },
  City: {
    List: 17,
    Add: 18,
    Edit: 19,
    Delete: 20,
  },
  Zone: {
    List: 21,
    Add: 22,
    Edit: 23,
    Delete: 24,
  },
  Pincode: {
    List: 25,
    Add: 26,
    Edit: 27,
    Delete: 28,
  },
  Education: {
    List: 29,
    Add: 30,
    Edit: 31,
    Delete: 32,
  },
  OrderStatus: {
    List: 33,
    Add: 34,
    Edit: 35,
    Delete: 36,
  },
  PaymentStatus: {
    List: 37,
    Add: 38,
    Edit: 39,
    Delete: 40,
  },
  GSTList: {
    List: 41,
    Add: 42,
    Edit: 43,
    Delete: 44,
  },
  TicketStatus: {
    List: 45,
    Add: 46,
    Edit: 47,
    Delete: 48,
  },
  TicketPriorities: {
    List: 49,
    Add: 50,
    Edit: 51,
    Delete: 52,
  },
  TicketTypes: {
    List: 53,
    Add: 54,
    Edit: 55,
    Delete: 56,
  },
  TicketChanel: {
    List: 57,
    Add: 58,
    Edit: 59,
    Delete: 60,
  },
  Department: {
    List: 61,
    Add: 62,
    Edit: 63,
    Delete: 64,
  },
  Designation: {
    List: 65,
    Add: 66,
    Edit: 67,
    Delete: 68,
  },
  Skills: {
    List: 69,
    Add: 70,
    Edit: 71,
    Delete: 72,
  },
  LeaveType: {
    List: 73,
    Add: 74,
    Edit: 75,
    Delete: 76,
  },
  Leave: {
    List: 77,
    Add: 78,
    Edit: 79,
    Delete: 80,
  },
  Routelist: {
    List: 81,
    Add: 82,
    Edit: 83,
    Delete: 84,
  },
  ProductList: {
    List: 85,
    Add: 86,
    Edit: 87,
    Delete: 88,
  },
  LeadStatus: {
    List: 89,
    Add: 90,
    Edit: 91,
    Delete: 92,
  },
  LeadStatusDetails: {
    List: 93,
    Add: 94,
    Edit: 95,
    Delete: 96,
  },
  Document: {
    List: 97,
    Add: 98,
    Edit: 99,
    Delete: 100,
  },
  Service: {
    List: 101,
    Add: 102,
    Edit: 103,
    Delete: 104,
  },
  Manager: {
    List: 105,
    Add: 106,
    Edit: 107,
    Delete: 108,
  },
  SalesCoOrdinater: {
    List: 109,
    Add: 110,
    Edit: 111,
    Delete: 112,
  },
  SalesExecutive: {
    List: 113,
    Add: 114,
    Edit: 115,
    Delete: 116,
  },
  TicketGenerates: {
    List: 117,
    Add: 118,
    Edit: 119,
    Delete: 120,
  },
  CountryCode: {
    List: 121,
    Add: 122,
    Edit: 123,
    Delete: 124,
  },
  Initial: {
    List: 125,
    Add: 126,
    Edit: 127,
    Delete: 128,
  },
  Currency: {
    List: 129,
    Add: 130,
    Edit: 131,
    Delete: 132,
  },
  ComplaintType: {
    List: 133,
    Add: 134,
    Edit: 135,
    Delete: 136,
  },
  VisitType: {
    List: 137,
    Add: 138,
    Edit: 139,
    Delete: 140,
  },
  ComplaintSource: {
    List: 141,
    Add: 142,
    Edit: 143,
    Delete: 144,
  },
  ComplaintPriorities: {
    List: 145,
    Add: 146,
    Edit: 147,
    Delete: 148,
  },
  ComplaintStatus: {
    List: 149,
    Add: 150,
    Edit: 151,
    Delete: 152,
  },
  Holiday: {
    List: 153,
    Add: 154,
    Edit: 155,
    Delete: 156,
  },
  TaskCategory: {
    List: 157,
    Add: 158,
    Edit: 159,
    Delete: 160,
  },
  ProjectCategory: {
    List: 161,
    Add: 162,
    Edit: 163,
    Delete: 164,
  },
  DiscussionCategory: {
    List: 165,
    Add: 166,
    Edit: 167,
    Delete: 168,
  },
  Project: {
    List: 169,
    Add: 170,
    Edit: 171,
    Delete: 172,
  },
  Task: {
    List: 173,
    Add: 174,
    Edit: 175,
    Delete: 176,
  },
  Client: {
    List: 177,
    Add: 178,
    Edit: 179,
    Delete: 180,
  },
  VisitRate: {
    List: 181,
    Add: 182,
    Edit: 183,
    Delete: 184,
  },
  Team: {
    List: 189,
    Add: 190,
    Edit: 191,
    Delete: 192,
  },
  EmployeeType: {
    List: 193,
    Add: 194,
    Edit: 195,
    Delete: 196,
  },
  Asset: {
    List: 197,
    Add: 198,
    Edit: 199,
    Delete: 200,
  },
  Shift: {
    List: 201,
    Add: 202,
    Edit: 203,
    Delete: 204,
  },
  Qualification: {
    List: 205,
    Add: 206,
    Edit: 207,
    Delete: 208,
  },
  University: {
    List: 209,
    Add: 210,
    Edit: 211,
    Delete: 212,
  },
  Course: {
    List: 213,
    Add: 214,
    Edit: 215,
    Delete: 216,
  },
  LeaveSetup: {
    List: 217,
    Add: 218,
    Edit: 219,
    Delete: 220,
  },
  AssignAssetsList: {
    List: 221,
    Add: 222,
    Edit: 223,
    Delete: 224,
  },
  AssignShiftList: {
    List: 225,
    Add: 226,
    Edit: 227,
    Delete: 228,
  },
  Calender: {
    List: 229,
    Add: 230,
    Edit: 231,
    Delete: 232,
  },
  Attendance: {
    List: 233,
    Add: 234,
    Edit: 235,
    Delete: 236,
  },
  EmploymentType: {
    List: 237,
    Add: 238,
    Edit: 239,
    Delete: 240,
  },
  CompanyPrefix: {
    List: 241,
    Add: 242,
    Edit: 243,
    Delete: 244,
  },
};
