import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
// Lead Dashborad
import Lead from "./Lead";
const IndexLead = () => {
  return (
    <>
      <Routes>
        {/* Lead Dashborad */}
        <Route path="/lead-dashboard" element={<Lead />} />
        {/* Lead Dashborad */}
      </Routes>
    </>
  );
};

export default IndexLead;
