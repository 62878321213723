import React from "react";
import LoginPage from "./LoginPage/LoginPage.js";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
// import { Context } from "../../utils/context.js";
import { Context } from "../../../utils/context.js";
import { Roles } from "../../../utils/common.js";

const Login = () => {
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();

  if (signin) {
    switch (usertype) {
      case Roles.SuperAdmin:
        navigate("/super-admin/dashboard");
        break;
      case Roles.Admin:
        navigate("/advanceDashboard");
        break;
      case Roles.TeleCaller:
        navigate("/lead-dashboard");
        break;
      case Roles.Complaint:
        navigate("/complaint-manage");
        break;
      case Roles.HRMS:
        navigate("/hrms-admin/hrms-dashboard");
        break;

      case Roles.ProjectManager:
        navigate("/projectDashboard");
        break;
      case Roles.ProjectManager:
        navigate("/projectDashboard");
        break;
      case Roles.ProjectManager:
        navigate("/projectDashboard");
        break;

      default:
        return <LoginPage />;
        break;
    }
  } else {
    return <LoginPage />;
  }
};

export default Login;
