import React from "react";
import { Modal, Button } from "react-bootstrap";

const SuceesfullyModal = (props) => {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="main-success-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="success-button">
          <p> Complaint Successful</p>
          {/* <p>{props.show === true ? "Added" : "Edited"}</p> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuceesfullyModal;
