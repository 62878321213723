import React, { useState } from "react";
import { Button, Offcanvas, Tab, Tabs } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../Modals/DeleteModal/DeleteModal";
import { Link } from "react-router-dom";
import TaskTab from "../Veiw_Details/Task_board/TaskBoardInnerTab/TaskTab/TaskTab";
import SubTaskTab from "../Veiw_Details/Task_board/TaskBoardInnerTab/SubTaskTab/SubTaskTab";
import FilesTab from "../Veiw_Details/Task_board/TaskBoardInnerTab/FilesTab/FilesTab";
import TimeLogTab from "../Veiw_Details/Task_board/TaskBoardInnerTab/TimeLogTab/TimeLogTab";
import CommentsTab from "../Veiw_Details/Task_board/TaskBoardInnerTab/CommentsTab/CommentsTab";
import NotesTab from "../Veiw_Details/Task_board/TaskBoardInnerTab/NotesTab/NotesTab";
import HistoryTab from "../Veiw_Details/Task_board/TaskBoardInnerTab/HistoryTab/HistoryTab";
library.add(fas);

function OffcanvasView() {
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  return (
    <>
      <div className="projectmain-sec  offcanvas-teb">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-9">
              <div className=" View_details_header second-sect1  details_info">
                {/* offcanvas heading start code by smita  */}
                <div className="topp-divv">
                  <div className="row">
                    <div className="col-md-6">
                      <Button
                        className="btn btn-success save-btn my-2"
                        type="button" // Change type to "button" to prevent form submission
                      >
                        <FontAwesomeIcon
                          className="me-2"
                          icon="fa-solid fa-check "
                        />
                        Mark As Complete
                      </Button>
                    </div>
                    <div className="col-md-6 text-end">
                      <div className="btn-group">
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item Add" role="presentation">
                            <Link to={"/task/new-task"}>
                              <Button className="nav-link text-success ">
                                <FontAwesomeIcon
                                  className=" me-2"
                                  icon="fa-solid fa-edit  "
                                />
                                Edit
                              </Button>
                            </Link>
                          </li>

                          <li
                            className="nav-item not_started"
                            role="presentation"
                          >
                            {" "}
                            <Link to={""}>
                              <Button
                                className="nav-link"
                                onClick={() => setDeleteModalShow(true)}
                              >
                                Reminder
                                <FontAwesomeIcon
                                  className="text-dark ms-2"
                                  icon="fa-solid fa-bell"
                                />
                              </Button>
                            </Link>
                          </li>
                          <DeleteModal
                            show={deleteModalShow}
                            onHide={() => setDeleteModalShow(false)}
                          />

                          <li className="nav-item Pinned" role="presentation">
                            <Link to={""}>
                              <Button
                                className="nav-link"
                                onClick={() => setDeleteModalShow(true)}
                              >
                                <FontAwesomeIcon
                                  className=""
                                  icon="fa-solid fa-thumbtack"
                                />
                              </Button>
                            </Link>
                          </li>
                          <DeleteModal
                            show={deleteModalShow}
                            onHide={() => setDeleteModalShow(false)}
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* offcanvas heading end code by smita  */}

                {/* label heading start (smita) */}
                <div className="row  mt-3">
                  <div className="col-xs-12">
                    <h6 className="xl-text">Demo</h6>
                    <p className="lg-text">
                      <FontAwesomeIcon
                        className="me-2"
                        icon="fa-solid fa-layers "
                      />{" "}
                      Royal Studio
                    </p>

                    <h6 className="xs-text ">
                      <label className="label label-default text-dark  me-1">
                        Designing
                      </label>

                      <label className="font-light label  label-warning                             ">
                        <span className=" text-dark">Priority &gt;</span> Medium
                      </label>
                    </h6>
                  </div>
                </div>
                {/* label heading start (smita) */}

                {/* offcanvas tabs start (smita ) */}
                <div className="view_details_content mt-4">
                  <div className="row">
                    <Tabs
                      defaultActiveKey="Task"
                      id="justify-tab-example"
                      className="tabs mb-3"
                      justify
                    >
                      {/* Task tab start code by smita  */}
                      <Tab
                        eventKey="Task"
                        title={
                          <span>
                            <FontAwesomeIcon
                              className=" me-1"
                              icon="fa-solid fa-list-check"
                            />
                            Task
                          </span>
                        }
                      >
                        <TaskTab />
                      </Tab>
                      {/* Task tab end code by smita  */}

                      {/* Sub Task(0) tab content start (smita ) */}
                      <Tab
                        eventKey="Sub Task(0)"
                        title={
                          <span>
                            <FontAwesomeIcon
                              className="me-1"
                              icon="fa-solid fa-list-check"
                            />{" "}
                            Sub Task(0)
                          </span>
                        }
                      >
                        <SubTaskTab />
                      </Tab>
                      {/* Sub Task(0) tab content end (smita ) */}

                      {/* File (0) tab content start (smita) */}
                      <Tab
                        eventKey="File (0)"
                        title={
                          <span>
                            <FontAwesomeIcon
                              className="me-1"
                              icon="fa-solid fa-file"
                            />{" "}
                            File (0)
                          </span>
                        }
                      >
                        <FilesTab />
                      </Tab>
                      {/* File (0) tab content end (smita) */}

                      {/* Time Logs content start (smita) */}
                      <Tab
                        eventKey="Time Logs"
                        title={
                          <span>
                            <FontAwesomeIcon
                              className="me-1"
                              icon="fa-solid fa-clock"
                            />{" "}
                            Time Logs
                          </span>
                        }
                      >
                        <TimeLogTab />
                      </Tab>
                      {/*  Time Logs tab content start (smita )*/}

                      {/* Comment (0) tab content start (smita) */}
                      <Tab
                        eventKey="Comment (0)"
                        title={
                          <span>
                            <FontAwesomeIcon
                              className="me-1"
                              icon="fa-solid fa-comment"
                            />
                            Comment (0)
                          </span>
                        }
                      >
                        <CommentsTab />
                      </Tab>
                      {/* Comment (0) tab content start (smita) */}

                      {/* Notes (0) tab content start (smita) */}
                      <Tab
                        eventKey="Notes (0)"
                        title={
                          <span>
                            <FontAwesomeIcon
                              className="me-1"
                              icon="fa-solid fa-note-sticky"
                            />
                            Notes (0)
                          </span>
                        }
                      >
                        <NotesTab />
                      </Tab>
                      {/* Notes (0) tab content end (smita) */}

                      {/* History tab content start (smita ) ) */}
                      <Tab eventKey="History" className="me-2" title="History">
                        <HistoryTab />
                      </Tab>
                      {/* History tab content end (smita) ) */}
                    </Tabs>
                  </div>
                </div>
                {/* view details tab end code by smita */}
                {/* offcanvas tabs end (smita ) */}
              </div>
            </div>

            <div className=" col-md-3">
              <div className="details_info">
                <div className="row">
                  <div className="col-md-12  ">
                    <h6 className="lg-text" for="">
                      Status
                    </h6>
                    <p className="text-danger  lg-text" id="columnStatus">
                      <FontAwesomeIcon
                        className="xs-text text-danger me-2"
                        icon="fa-solid fa-circle"
                      />
                      Incomplete
                    </p>
                  </div>

                  <div className="col-md-12">
                    <hr />

                    <h6 className="lg-text" for="">
                      Assigned To
                    </h6>
                    <img
                      src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                      data-toggle="tooltip"
                      data-original-title="Hitesh Kulkarni"
                      data-placement="right"
                      className="img-circle"
                      width="35"
                      height="35"
                      alt=""
                    />
                    <hr />
                  </div>

                  <div className="col-md-12">
                    <h6 className="lg-text" for="">
                      Assigned By
                    </h6>
                    <img
                      src="https://teampro.profcymaglobal.com/user-uploads/avatar/648273098fef2d63d27046e2f0e07d0f.png"
                      className="img-circle"
                      width="35"
                      height="35"
                      alt=""
                    />
                    <p className="sm-text">Smita Madan Lad</p>
                    <hr />
                  </div>

                  <div className="col-md-12  ">
                    <h6 className="lg-text" for="">
                      Start Date
                    </h6>
                    <p className=" sm-text text-success">10-02-2024</p>
                    <hr />
                  </div>

                  <div className="col-md-12 ">
                    <h6 className="lg-text" for="">
                      Due Date
                    </h6>
                    <p className=" sm-text">11-02-2024</p>
                    <hr />
                  </div>

                  <div className="col-md-12 ">
                    <h6 className="lg-text" for="">
                      Estimate
                    </h6>
                    <br />

                    <p className=" sm-text">2 hrs 4 mins </p>
                    <hr />
                  </div>

                  <div className="col-md-12 ">
                    <h6 className="lg-text" for="">
                      Hours Logged
                    </h6>
                    <span>
                      <p className=" sm-text">0 hrs</p>
                    </span>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OffcanvasView;
