import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../CommonCss/CommonCss.css";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import {
  faArrowUpWideShort,
  faEye,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import DeleteModal from "../../../common/DeleteModal";

const Employee = () => {
  const [deleteModalShow, setDeleteModalShow] = useState({
    show: false,
    heading: "",
    id: "",
  });

  const {
    postData,
    getData,
    Select2Data,
    Per_Page_Dropdown,
    editStatusData,
    deleteData,
  } = useContext(Context);
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  const [counts, setCounts] = useState();

  const getDataAllCounts = async () => {
    const response = await getData(`/hrms/employee/counts`);
    await setCounts(response?.data);
  };

  //  column hide and show.....
  const [short, setShort] = useState({
    col1: false,
    col2: false,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setShort((prev) => ({
      col1: columnName === "col1" ? !prev.col1 : false,
      col2: columnName === "col2" ? !prev.col2 : false,
    }));
  };

  const getDataAll = async () => {
    const response = await getData(
      `/hrms/employee?page=${currentPage}&per_page=${perPage}&term=${search}&short_name=${short.col1}&short_created=${short.col2}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    getDataAllCounts();
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, search, show, show1, short]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/hrms/employee/${id}`);
    setChangeStatus(response);
  };

  const handleDeleteRecord = async (id) => {
    const response = await deleteData(`/hrms/employee/${id}`);
    if (response?.success) {
      setDeleteModalShow(true);
      getDataAll();
    }
  };

  useEffect(() => {
    getDataAll();
    getDataAllCounts();
  }, []);
  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <Row>
          <Col xxl={8} xl={6} lg={4} md={12} sm={12} xs={12}>
            <p className="main-table-head">Employee </p>
          </Col>
          <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={12}>
            <div className="add d-md-flex justify-content-lg-end">
              <Link to="/hrms-admin/add-employee">
                <Button className="custom-apply-leaves-btn me-2">
                  Add Employee
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                </Button>
              </Link>
              <Dropdown>
                <Dropdown.Toggle
                  className="custom-apply-leaves-btn"
                  id="dropdown-basic"
                >
                  Sort By
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col1")}
                        href="#"
                      >
                        Designation
                        {short?.col1 ? (
                          <>
                            <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" /> Zz-Aa
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />  Aa-Zz
                          </>
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col2")}
                        href="#"
                      >
                        Employee
                        {short?.col2 ? (
                          <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />
                        ) : (
                          // <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />
                          <></>
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <div className="card-section">
          <div className="row">
            {/* Total Present Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/employee-cards/card1.png"
                    }
                    alt="Card 1"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Total <br />
                    Employee
                  </p>
                </div>
                <div className="count-container">
                  <p>{counts?.allEmployees}</p>
                </div>
              </div>
            </div>

            {/* Total Absent Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/employee-cards/card2.png"
                    }
                    alt="Card 2"
                  />
                </div>
                <div className="text-container">
                  <p>
                    On Role <br />
                    Employee
                  </p>
                </div>
                <div className="count-container">
                  <p>{counts?.onRole}</p>
                </div>
              </div>
            </div>

            {/* Late Marks Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/employee-cards/card3.png"
                    }
                    alt="Card 3"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Intern <br />
                    Employee
                  </p>
                </div>
                <div className="count-container">
                  <p>{counts?.onIntern}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grey-bggg">
          <Row>
            <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={12}>
              <div class="show-entries mt-2">
                <label for="entries">Show</label>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setperPage(e.target.value)}
                >
                  {option?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <label for="entries" className="ms-2">
                  Entries
                </label>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="sowing me-2">
                <p className="text-end showing-txttt mt-2">{`Showing ${Math.min(
                  (currentPage - 1) * perPage + 1
                )} to ${Math.min(
                  currentPage * perPage,
                  data?.data?.totalEntries
                )} of ${data?.data?.totalEntries} Entries`}</p>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12}>
              <div className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search Name Here"
                    aria-label="Search Here"
                    aria-describedby="basic-addon1"
                    id=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
            <Col xxl={3} xl={6} lg={6} md={12} sm={6} xs={12}>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setSearch("");
                    setReset(!reset);
                  }}
                  className="btn reset-btnn mb-3"
                >
                  Reset
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="common-table-hrms">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Employee Id</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Employee Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              {data &&
                data?.data?.data?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.e_employment_detail?.employee_id}</td>
                    <td>{item?.name}</td>
                    <td>{item?.e_employment_detail?.designation?.name}</td>
                    <td>{item?.e_employment_detail?.department?.name}</td>
                    <td>{item?.e_employment_detail?.employee_type?.name}</td>
                    <td>
                      <Form>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Active"
                          checked={item.status}
                          onChange={() => {
                            ChangeStatus(item.id);
                          }}
                        />
                      </Form>
                    </td>
                    <td>
                      <div>
                        <Link to={`/hrms-admin/view-employee/${item?.id}`}>
                          <FontAwesomeIcon
                            icon={faEye}
                            className="table-iconnnn "
                          />
                        </Link>
                        <Link to={`/hrms-admin/edit-employee/${item?.id}`}>
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="table-iconnnn ms-2 "
                          />
                        </Link>
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() =>
                            setDeleteModalShow({
                              show: true,
                              heading: "Employee",
                              id: item?.id,
                            })
                          }
                          className="table-iconnnn ms-2"
                        />
                      </div>
                    </td>

                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>

      <DeleteModal
        heading={deleteModalShow?.heading}
        show={deleteModalShow?.show}
        onHide={() => setDeleteModalShow({ show: false, heading: "", id: "" })}
        handleDelete={() => handleDeleteRecord(deleteModalShow?.id)}
      />
    </section>
  );
};

export default Employee;
