import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";

// Addproject css link
import "../style/Common/Common.css";
import "../style/Form/Form.css";
import "../style/Modals/Modals.css";
import "../style/Tables/Tables.css";
import ProjectCategoryModal from "../Modals/ProjectCategoryModal/ProjectCategoryModal";
import AddEmployeeInfoModal from "../Modals/AddEmployeeInfoModal/AddEmployeeInfoModal";
import AddClientInfoModal from "../Modals/AddClientInfoModal/AddClientInfoModal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Dropdown from "react-bootstrap/Dropdown";
import { Form, ProgressBar } from "react-bootstrap";
import { Button } from "react-bootstrap";

import { useDropzone } from "react-dropzone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../../utils/context";
library.add(fas);

function Editproject() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const { postData, getData } = useContext(Context);
  const { id } = useParams();

  const [Category, setCategory] = useState([]);
  const [Client, setClient] = useState([]);
  const [ProjectStatus, setProjectStatus] = useState([]);
  const [Currency, setCurrency] = useState([]);
  const [Users, setUsers] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState();

  const [data, setData] = useState();
  const [selectedservices, setSelectedservices] = useState([]);

  const [formData, setFormData] = useState({
    name: " ",
    without_deadline: "",
    manual_time_logs: "",
    users_id: "",
    client_id: "",
    currency_id: "",
    note: "",
    summary: "",
    client_manage_task: "",
    project_status_id: "",
    deadline: new Date(),
    start_date: new Date(),
    project_category_id: "",
    project_budget: " ",
    hours_allocated: "",
    file: null,
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const summaryEditorRef = useRef(null);
  const noteEditorRef = useRef(null);

  // const GetEditData = async () => {
  //   const response = await getData(`/projectmanagement/project/${id}`);

  //   setFormData(response?.data);
  // };

  const GetEditData = async () => {
    const response = await getData(`/projectmanagement/project/${id}`);
    setData(response);

    // setFormData(response?.data);
    if (response?.success) {
      const clientData = response.data;

      // Update formData state with initial values
      setFormData({
        ...formData,
        name: clientData.name,
        without_deadline: clientData.without_deadline,
        manual_time_logs: clientData.manual_time_logs,
        client_id: {
          value: clientData?.project_client_info?.client_id,
          name: "client_id",
          label: clientData?.project_client_info?.client?.client_name,
        },

        users_id: {
          value: clientData?.project_member?.users_id,
          name: "users_id",
          label: clientData?.project_member?.user?.name,
        },
        // users_id: clientData?.project_member?.users_id.map((userId, index) => ({
        //   value: userId,
        //   name: `user_id_${index}`, // Or whatever identifier you prefer
        //   label: clientData?.project_member?.user?.name[index]
        // })),
        // users_id: Array.isArray(clientData?.project_member?.user) ?
        // clientData?.project_member?.user.map((user, index) => ({
        //   value: user.id,
        //   name: `user_id_${index}`, // Or whatever identifier you prefer
        //   label: user.name
        // })) : [{ value: '', name: '', label: '' }],
        // currency_id: clientData.project_client_info.currency.name,
        currency_id: {
          value: clientData?.project_client_info.currency_id,
          name: "currency_id",
          label: clientData?.project_client_info?.currency?.name,
        },

        note: clientData.note,
        summary: clientData.summary,
        client_manage_task: clientData.project_client_info.client_manage_task,
        project_status_id: {
          value: clientData?.project_client_info?.project_status_id,
          name: "project_status_id",
          label: clientData?.project_client_info?.project_status?.name,
        },

        deadline: clientData.deadline,
        start_date: clientData.start_date,

        project_category_id: {
          value: clientData?.project_category_id,
          name: "project_category_id",
          label: clientData?.project_category?.name,
        },
        project_budget: clientData.project_client_info.project_budget,
        hours_allocated: clientData.project_client_info.hours_allocated,
      });
    } else {
      console.error("Failed to fetch  data:", response?.error);
    }

    const res = await getData("/project_management/allprojectcategory");
    const options = [];
    if (res.success) {
      res?.data?.map((data) => {
        options.push({
          value: data.id,
          name: "project_category_id",
          label: data?.name,
        });
      });
    }

    setCategory(options);

    const res1 = await getData("/project_management/allprojectstatus");
    const options1 = [];
    if (res1.success) {
      res1?.data?.map((data) => {
        options1.push({
          value: data.id,
          name: "project_status_id",
          label: data?.name,
        });
      });
    }

    setProjectStatus(options1);

    const res2 = await getData("/project_management/allcurrency");
    const options2 = [];
    if (res2.success) {
      res2?.data?.map((data) => {
        options2.push({
          value: data.id,
          name: "currency_id",
          label: data.name,
        });
      });
    }
    console.log(res2);
    setCurrency(options2);

    const res3 = await getData("/project_management/allclients");
    const options3 = [];
    if (res3.success) {
      res3?.data?.map((data) => {
        options3.push({
          value: data.id,
          name: "client_id",
          label: data.client_name,
        });
      });
    }
    setClient(options3);

    const res4 = await getData("/project_management/allusers");
    const options4 = [];
    if (res4.success) {
      res4?.data?.map((data) => {
        options4.push({
          value: data.id,
          name: "users_id",
          label: data.name,
        });
      });
    }
    setUsers(options4);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const handleService = async (e) => {
    setSelectedservices(e);
  };

  useEffect(() => {
    // const total = selectedservices.reduce((acc, service) => {
    //   return acc + service.price;
    // }, 0);
    const updatedFormData = {
      ...formData,
      ["users_id"]: selectedservices,
      // ["total"]: total,
    };
    setFormData(updatedFormData);
  }, [selectedservices]);

  const users_idData = formData.users_id;
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Read the selected file and set the preview
    const reader = new FileReader();
    reader.onload = () => {
      document.querySelector(
        ".dz-default.dz-message span"
      ).innerText = `Selected file: ${file.name}`;
    };
    reader.readAsDataURL(file);
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Handle file upload logic here
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // const handleSubmit = () => {
  //   // Handle form submission logic here
  //   console.log("Form submitted!");
  // };

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const handleChange = (e, contentName) => {
    // if (e?.name == "users_id") {
    //   setFormData({ ...formData, [e.name]: e });
    // } else
    if (e?.name == "project_category_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "client_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "currency_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name == "project_status_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      // Handle other types of input changes
      if (e && e.target) {
        const { name, value, type, checked, files } = e.target;
        if (type === "checkbox") {
          setFormData({ ...formData, [name]: checked ? "1" : "0" });
        } else if (type === "date") {
          const dateValue = new Date(value);
          setFormData({ ...formData, [name]: dateValue });
        } else if (type === "file") {
          if (files.length > 0) {
            setFormData({ ...formData, [name]: files[0] });
          }
        } else {
          setFormData({ ...formData, [name]: value });
        }
      } else {
        setFormData({ ...formData, [contentName]: e });
      }
    }
  };

  console.log(formData);
  const handleSubmit = async (e) => {
    // console.log("kalyani");
    e.preventDefault();

    const validationErrors = validateForm();
    // console.log("kalyani")
    console.log(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        //  console.log("kalyani");
        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("without_deadline", formData.without_deadline);
        formDataToSend.append("manual_time_logs", formData.manual_time_logs);
        // formDataToSend.append("users_id", formData.users_id.value);

        if (Array.isArray(users_idData)) {
          const usersIdValues = users_idData.map((item) => item.value);
          usersIdValues.forEach((value) => {
            formDataToSend.append("users_id", value);
          });
          console.log(usersIdValues); // [14, 2, 4]
        } else {
          console.error("users_idData is not an array.");
        }
        formDataToSend.append("client_id", formData.client_id.value);
        formDataToSend.append("currency_id", formData.currency_id.value);
        formDataToSend.append("note", formData.note);
        formDataToSend.append("summary", formData.summary);
        formDataToSend.append(
          "client_manage_task",
          formData.client_manage_task
        );
        formDataToSend.append(
          "project_status_id",
          formData.project_status_id.value
        );
        formDataToSend.append("deadline", formData.deadline);
        formDataToSend.append("start_date", formData.start_date);
        formDataToSend.append(
          "project_category_id",
          formData.project_category_id.value
        );
        formDataToSend.append("project_budget", formData.project_budget);
        formDataToSend.append("hours_allocated", formData.hours_allocated);
        formDataToSend.append("file", formData.file);

        const response = await postData(
          `/projectmanagement/project/${id}`,
          formDataToSend
        );
        // if (response.success) {
        //   setShowModal({ code: response.code, message: response.message  });
        //   setTimeout(() => {
        //     setShowModal(0);
        //   }, 1000);
        // } else {
        //   setShowModal({ code: response.code, message: response.message });
        //   setTimeout(() => {
        //     setShowModal(0);
        //   }, 1000);
        // }

        // await setErrors();
        if (response?.success) {
          console.log("Form Submited :- ", response);
        } else {
          console.error("Submission failed:", response?.error);
          // Handle submission failure
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name) {
      errors.name = "project Name is required";
    }

    // if (!formData.without_deadline) {
    //   errors.without_deadline = "Description is required";
    // }
    // if (!formData.manual_time_logs) {
    //   errors.manual_time_logs = "Hostel is required";
    // }

    if (!formData.users_id) {
      errors.users_id = "Project Members is required";
    }

    if (!formData.client_id) {
      errors.client_id = "client is required";
    }

    if (!formData.currency_id) {
      errors.currency_id = "currency is required";
    }
    if (!formData.note) {
      errors.note = "Hostel is required";
    }

    if (!formData.summary) {
      errors.summary = "note is required";
    }

    if (!formData.client_manage_task) {
      errors.client_manage_task = "Task is required";
    }

    if (!formData.deadline) {
      errors.deadline = "end date is required";
    }
    if (!formData.start_date) {
      errors.start_date = "start date is required";
    }

    if (!formData.project_category_id) {
      errors.project_category_id = "project categary is required";
    }

    if (!formData.project_budget) {
      errors.project_budget = "project budget is required";
    }

    if (!formData.hours_allocated) {
      errors.hours_allocated = "Hostel allocation is required";
    }
    if (!formData.project_status_id) {
      errors.project_status_id = "project Status is required";
    }

    // if (!formData.file) {
    //   errors.file = "file is required";
    // }

    return errors;
  };
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  return (
    <>
      <div className="Add_Project_main">
        <div className="container-fluid p-0">
          <div className="add_project">
            {/* heading section start */}
            <div className="bg-title">
              <div className="row ">
                <h5 className="page-title">
                  <FontAwesomeIcon icon="fa-solid fa-layer-group" /> Projects
                </h5>
              </div>
            </div>
            {/* heading section end */}

            {/*Add_project_from section start */}
            <div className="Add_project_from">
              <div className="row">
                <div className="col-md-12">
                  <div className="panel panel-inverse">
                    <div className="panel-heading">
                      <div className="heading-holder">
                        <h6 className="heading-text">
                          {" "}
                          Update Project Details
                        </h6>
                      </div>
                    </div>

                    <div className="panel-body">
                      <div className="form-body">
                        <h5 className="box-title ">Project Info</h5>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-6">
                              <Form>
                                <Form.Group controlId="project_name">
                                  <Form.Label className="required">
                                    Project Name
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter project name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name="template_id"
                                  />
                                </Form.Group>
                                {errors?.name && (
                                  <span style={errorStyle}>{errors?.name}</span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-6">
                              <Form>
                                <Form.Group controlId="category_id">
                                  <Form.Label className="control-label">
                                    Project Category
                                    <FontAwesomeIcon
                                      icon="fa-solid fa-gear"
                                      className="text-info gear-icon"
                                      onClick={() => setModalShow(true)}
                                    />
                                  </Form.Label>
                                  <Select
                                    name="project_category_id"
                                    className="input-mandatory"
                                    onChange={handleChange}
                                    // onChange={(selectedOption) => handleChange(selectedOption, "project_category_id")}
                                    value={formData?.project_category_id}
                                    options={Category}
                                  />
                                </Form.Group>

                                <ProjectCategoryModal
                                  show={modalShow}
                                  onHide={() => setModalShow(false)}
                                />
                                {errors?.project_category_id && (
                                  <span style={errorStyle}>
                                    {errors?.project_category_id}
                                  </span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="start_date">
                                  <Form.Label className="required">
                                    Start Date
                                  </Form.Label>
                                  <div>
                                    <DatePicker
                                      selected={startDate}
                                      // onChange={(date) => setStartDate(date)}
                                      value={formData.start_date}
                                      // onChange={(date) => {setStartDate(date); handleChange(date); } }
                                      onChange={(date) => {
                                        setStartDate(date);
                                        handleChange({
                                          target: {
                                            name: "start_date",
                                            value: date,
                                          },
                                        });
                                      }}
                                      name="start_date"
                                      id="start_date"
                                      autoComplete="off"
                                      className="form-control"
                                    />
                                  </div>
                                </Form.Group>
                                {errors?.start_date && (
                                  <span style={errorStyle}>
                                    {errors?.start_date}
                                  </span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4" id="deadlineBox">
                              <Form>
                                <Form.Group controlId="end_date">
                                  <Form.Label className="required">
                                    End Date
                                  </Form.Label>
                                  <div>
                                    <DatePicker
                                      selected={endDate}
                                      // onChange={(date) => setEndDate(date)}
                                      value={formData.deadline}
                                      // onChange={(date) => setEndDate(date)}
                                      // onChange={(date) => {
                                      //   setEndDate(date); // Update local state
                                      //   handleChange(date); // Update formData state
                                      // }}
                                      onChange={(date) => {
                                        setEndDate(date);
                                        handleChange({
                                          target: {
                                            name: "deadline",
                                            value: date,
                                          },
                                        });
                                      }}
                                      name="deadline"
                                      id="deadline"
                                      autoComplete="off"
                                      className="form-control"
                                    />
                                  </div>
                                </Form.Group>
                                {errors?.deadline && (
                                  <span style={errorStyle}>
                                    {errors?.deadline}
                                  </span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4">
                              <Form.Group>
                                <div className="checkbox checkbox-info">
                                  <Form.Check
                                    type="checkbox"
                                    id="without_deadline"
                                    name="without_deadline"
                                    // value="true"
                                    label="Without deadline"
                                    checked={formData.without_deadline === 1}
                                    // value={formData.without_deadline}
                                    onChange={handleChange}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <Form.Group controlId="">
                              <div className=" checkbox-info">
                                <Form.Check
                                  type="checkbox"
                                  id="Allow_manual_time_logs"
                                  name="manual_time_logs"
                                  label="Allow manual time logs?"
                                  value={formData.manual_time_logs == 1}
                                  onChange={handleChange}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="required">
                                    Add Project Members
                                  </Form.Label>
                                  <FontAwesomeIcon
                                    className="btn btn-xs btn-success btn-outline plus-icon"
                                    icon="fa-solid fa-plus"
                                    onClick={() => setModalShow2(true)}
                                  />
                                  <Select
                                    name="users_id"
                                    className="input-mandatory"
                                    // onChange={handleChange}
                                    // value={formData.users_id}
                                    // options={Users?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "users_id",
                                    //   label: Option.name,
                                    // }))}
                                    // onChange={(selectedOption) =>
                                    //   handleChange(selectedOption)
                                    // }
                                    isMulti
                                    value={selectedservices}
                                    onChange={handleService}
                                    options={Users}
                                  />

                                  {/* <Form.Control as="select" name=""> */}
                                  {/* <option value="1">Laravel</option>
                                    <option value="2">Java</option>
                                    <option value="3">Development</option>
                                    <option value="5">Digital Marketing</option>
                                    <option value="6">
                                      Mobile App Development
                                    </option>
                                    <option value="7">
                                      Internal Development
                                    </option>
                                    <option value="8">WordPress</option>
                                    <option value="9">
                                      E-Commerce Development
                                    </option>
                                    <option value="10">
                                      Internal Designing
                                    </option>
                                    <option value="11">Designing</option> */}
                                </Form.Group>

                                <AddEmployeeInfoModal
                                  show={modalShow2}
                                  onHide={() => setModalShow2(false)}
                                />
                                {errors?.users_id && (
                                  <span style={errorStyle}>
                                    {errors?.users_id}
                                  </span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        {/*   Project Summary summer code section start */}
                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Project Summary
                                  </Form.Label>
                                  <JoditEditor
                                    // ref={editor}
                                    // value={content}
                                    // tabIndex={1} // tabIndex of textarea
                                    // onBlur={(newContent) =>
                                    //   setContent(newContent)
                                    // } // preferred to use only this option to update the content for performance reasons
                                    // onChange={(newContent) => {}}
                                    ref={summaryEditorRef}
                                    value={formData.summary}
                                    // value={content}
                                    name="summary"
                                    tabIndex={1} // tabIndex of textarea
                                    // onBlur={(newContent) =>
                                    //   setContent(newContent)
                                    // } // preferred to use only this option to update the content for performance reasons
                                    // onChange={(newContent) => {handleChange(newContent) }}
                                    // onChange={handleChange}
                                    onChange={(newContent) =>
                                      handleChange(newContent, "summary")
                                    }
                                  />
                                </Form.Group>
                                {errors?.summary && (
                                  <span style={errorStyle}>
                                    {errors?.summary}
                                  </span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>
                        {/*   Project Summary summer code section end */}

                        {/*   Note Summary summer code section start */}
                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Note
                                  </Form.Label>
                                  <JoditEditor
                                    // ref={editor}
                                    // value={content}
                                    // tabIndex={1} // tabIndex of textarea
                                    // onBlur={(newContent) =>
                                    //   setContent(newContent)
                                    // } // preferred to use only this option to update the content for performance reasons
                                    // onChange={(newContent) => {}}

                                    ref={noteEditorRef}
                                    name="note"
                                    value={formData.note}
                                    // value={content}
                                    tabIndex={1} // tabIndex of textarea
                                    // onBlur={(newContent) =>
                                    //   setContent(newContent)
                                    // } // preferred to use only this option to update the content for performance reasons
                                    // onChange={(newContent) => handleChange(newContent)}
                                    // onChange={handleChange}
                                    onChange={(newContent) =>
                                      handleChange(newContent, "note")
                                    }
                                  />
                                </Form.Group>
                                {errors?.note && (
                                  <span style={errorStyle}>{errors?.note}</span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>
                        {/*   Note Summary summer code section end */}

                        {/* Project Completion Status section start */}

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form.Group>
                                <Form.Label>
                                  Project Completion Status
                                </Form.Label>
                                {/* Progress bar */}

                                {/* Input to adjust progress */}
                                <input
                                  type="range"
                                  min="0"
                                  max="100"
                                  step="1"
                                  style={{ width: "100%" }} // Set width to 100% to fill the container
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                        {/* Project Completion Status section end */}

                        <div className="heading">
                          <h5 class="box-title ">Client Info</h5>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-6">
                              <Form>
                                <Form.Group controlId="category_id">
                                  <Form.Label className="control-label">
                                    Select Client
                                    <FontAwesomeIcon
                                      className="btn btn-xs btn-success btn-outline plus-icon"
                                      icon="fa-solid fa-plus"
                                      onClick={() => setModalShow3(true)}
                                    />
                                  </Form.Label>

                                  <Select
                                    name="client_id"
                                    className="input-mandatory"
                                    // onChange={handleChange}
                                    onChange={(selectedOption) =>
                                      handleChange(selectedOption, "client_id")
                                    }
                                    // value={formData.client_id}
                                    value={formData?.client_id}
                                    // options={Category?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "project_category_id",
                                    //   label: Option.name,
                                    // }))}
                                    options={Client}
                                  />
                                </Form.Group>

                                <AddClientInfoModal
                                  show={modalShow3}
                                  onHide={() => setModalShow3(false)}
                                />
                                {errors?.client_id && (
                                  <span style={errorStyle}>
                                    {errors?.client_id}
                                  </span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-6">
                              <Form.Group>
                                <div className="checkbox checkbox-info">
                                  <Form.Check
                                    type="checkbox"
                                    // id="Client_manage"
                                    // name="Client can manage tasks of this project"
                                    // value="true"
                                    // label="Client can manage tasks of this project"
                                    id="Client_manage"
                                    // name="Client can manage tasks of this project"
                                    name="client_manage_task"
                                    // value="true"
                                    value={formData.client_manage_task == 1}
                                    onChange={handleChange}
                                    label="Client can manage tasks of this project"
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>

                        <div className="heading">
                          <h5 class="box-title ">Budget Info</h5>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Project Budget
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    // name="project_Budget_name"
                                    // placeholder="Enter Project Budget"
                                    name="project_budget"
                                    placeholder="Enter Project Budget"
                                    value={formData.project_budget}
                                    onChange={handleChange}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name="project_Budget"
                                  />
                                </Form.Group>
                                {errors?.project_budget && (
                                  <span style={errorStyle}>
                                    {errors?.project_budget}
                                  </span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Currency
                                  </Form.Label>

                                  <Select
                                    name="currency_id"
                                    className="input-mandatory"
                                    // onChange={handleChange}
                                    onChange={(selectedOption) =>
                                      handleChange(
                                        selectedOption,
                                        "currency_id"
                                      )
                                    }
                                    // value={formData.client_id}
                                    value={formData.currency_id}
                                    // options={Category?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "project_category_id",
                                    //   label: Option.name,
                                    // }))}
                                    options={Currency}
                                  />
                                  {/* <Form.Select as="select" name="category_id"> */}
                                  {/* <option value="1">Rupee (INR)</option>
                                    <option value="2">Java</option>
                                    <option value="3">Development</option>
                                    <option value="5">Digital Marketing</option>
                                    <option value="6">
                                      Mobile App Development
                                    </option>
                                    <option value="7">
                                      Internal Development
                                    </option>
                                    <option value="8">WordPress</option>
                                    <option value="9">
                                      E-Commerce Development
                                    </option>
                                    <option value="10">
                                      Internal Designing
                                    </option>
                                    <option value="11">Designing</option> */}
                                </Form.Group>
                                {errors?.currency_id && (
                                  <span style={errorStyle}>
                                    {errors?.currency_id}
                                  </span>
                                )}
                              </Form>
                            </div>

                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Hours Allocated
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    // name="Hours_Allocated"
                                    // placeholder="Enter Hours Allocated"
                                    name="hours_allocated"
                                    placeholder="Enter Hours Allocated"
                                    value={formData?.hours_allocated}
                                    onChange={handleChange}
                                  />
                                  <Form.Control
                                    type="hidden"
                                    name="Hours_Allocated"
                                  />
                                </Form.Group>
                                {errors?.hours_allocated && (
                                  <span style={errorStyle}>
                                    {errors?.hours_allocated}
                                  </span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-4">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Project Status
                                  </Form.Label>

                                  <Select
                                    name="project_status_id"
                                    className="input-mandatory"
                                    // onChange={handleChange}
                                    onChange={(selectedOption) =>
                                      handleChange(
                                        selectedOption,
                                        "project_status_id"
                                      )
                                    }
                                    // value={formData.client_id}
                                    value={formData.project_status_id}
                                    // options={Category?.map((Option) => ({
                                    //   value: Option.id,
                                    //   name: "project_category_id",
                                    //   label: Option.name,
                                    // }))}
                                    options={ProjectStatus}
                                  />
                                  {/* <Form.Select as="select" name=""> */}
                                  {/* <option value="1">Not Started </option>
                                    <option value="2">Java</option>
                                    <option value="3">Development</option>
                                    <option value="5">Digital Marketing</option>
                                    <option value="6">
                                      Mobile App Development
                                    </option>
                                    <option value="7">
                                      Internal Development
                                    </option>
                                    <option value="8">WordPress</option>
                                    <option value="9">
                                      E-Commerce Development
                                    </option>
                                    <option value="10">
                                      Internal Designing
                                    </option>
                                    <option value="11">Designing</option> */}
                                </Form.Group>
                                {errors?.project_status_id && (
                                  <span style={errorStyle}>
                                    {errors?.project_status_id}
                                  </span>
                                )}
                              </Form>
                            </div>
                          </div>
                        </div>

                        <div className="field_top_space" id="file-upload-box">
                          <div className="row" id="file-dropzone">
                            <div className="col-md-12">
                              <Form.Group>
                                <div
                                  {...getRootProps()}
                                  className={`dropzone ${
                                    isDragActive ? "active" : ""
                                  }`}
                                >
                                  <Form.Control
                                    {...getInputProps()}
                                    name="file"
                                    id="file"
                                    type="file"
                                    onChange={handleChange}
                                  />
                                  <div className="dz-default dz-message">
                                    <span>Drop files here to upload</span>
                                  </div>
                                </div>
                              </Form.Group>
                              {/* {errors?.file && (
                                            <span style={errorStyle}>{errors?.file}</span>
                                          )} */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <Form>
                                <Form.Group controlId="">
                                  <Form.Label className="control-label">
                                    Client Feedback
                                  </Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    placeholder="Enter feedback here..."                                  
                                    onChange={handleChange}
                                    rows={3} // Set the number of rows for the textarea
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        </div> */}

                        <div className="field_top_space">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-actions">
                                <Button
                                  type="submit"
                                  id="save-form"
                                  variant="success"
                                  onClick={handleSubmit}
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-check" />
                                  Update
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*Add_project_from section end */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Editproject;
