import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";

import Offcanvas from "react-bootstrap/Offcanvas";

import Card from "react-bootstrap/Card";
import Select from "react-select";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const [role, setRole] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [formData, setFormData] = useState({
    role_id: "",
    department_id: "",
    name: "",
    mobile_number: "",
    email: "",
    password: "",
    cpassword: "",
    image: null,
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    if (e?.name === "role_id") {
      setFormData({ ...formData, [e.name]: e });
    } else if (e?.name === "department_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: file,
    }));
  };

  const {
    control,
    register,
    // handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");

  console.log(email, "email");
  console.log(contact, "contact");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEmail("");
    setContact("");

    const validationErrors = await Validation.FormValidation({
      classname: ".employeedetailclass",
    });

    const validationErrorsSelect = Validation.SelectValidation({
      classname: ".employeedetailclass",
    });

    if (validationErrors && validationErrorsSelect) {
      try {
        console.log("formData", formData);

        const formDataToSend = new FormData();
        formDataToSend.append("role_id", formData.role_id.value);
        formDataToSend.append("department_id", formData.department_id.value);
        formDataToSend.append("name", formData.name);
        formDataToSend.append("mobile_number", formData.mobile_number);
        formDataToSend.append("email", formData.email);
        formDataToSend.append("password", formData.password);
        formDataToSend.append("cpassword", formData.cpassword);
        if (formData.image) {
          formDataToSend.append("image", formData.image);
        }

        const response = await postData(
          "/employee/employee_details",
          formDataToSend
        );
        if (response.success) {
          setShowModal({ code: response.code, message: response.message });
          setTimeout(() => {
            setShowModal(0);
            props.handleClose();
          }, 1000);
        } else {
          if (response?.message?.email) {
            setEmail(response?.message?.email);
          }
          if (response?.message?.mobile_number) {
            setContact(response?.message?.mobile_number);
          }
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const GetAllRoles = async () => {
    const response = await getData("/masters/allroles");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "role_id",
          label: data.name,
        });
      });
    }
    setRole(option);
  };

  const GetAllDepartments = async () => {
    const response = await getData("/masters/alldepartments");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data?.id,
          name: "department_id",
          label: data?.name,
        });
      });
    }
    setDepartments(option);
  };

  useEffect(() => {
    GetAllRoles();
    GetAllDepartments();
  }, []);

  // Contact Validation
  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
          {/* <Offcanvas.Title>Add Employee</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Employee Details</Card.Title>
              <hr />
              <Container>
                <Row>
                  <Col md={12}>
                    <Container>
                      <Row className="">
                        <Col xxl={12} xl={12} md={10}>
                          <Form
                            onSubmit={handleSubmit}
                            role="form"
                            className="employeedetailclass"
                          >
                            <Row>
                              {/* <Col
                                  lg={8}
                                  md={10}
                                  className=" mx-auto Add-content"
                                >
                                  <Row> */}

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Department{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="department_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData.department_id}
                                        options={departments}
                                      />
                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="row justify-content-center mb-2">
                                    <Form.Label column sm={3}>
                                      Employee Role{" "}
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Select
                                        name="role_id"
                                        className="input-mandatory"
                                        onChange={handleChange}
                                        value={formData.role_id}
                                        options={role}
                                      />

                                      <span className="error-message"></span>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Employee Name
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Employee Name"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Employee Mobile
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="tel"
                                            name="mobile_number"
                                            value={formData.mobile_number}
                                            onChange={handleChange}
                                            placeholder="Employee Mobile"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                            onKeyPress={handleKeyPressContact}
                                            maxLength={10}
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                      <div
                                        style={{
                                          color: "Red",
                                          fontSize: "16px",
                                          padding: "10px",
                                        }}
                                      >
                                        {contact}
                                      </div>
                                    </Col>
                                  </Row>{" "}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Employee Email
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            placeholder="Employee Email"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                      <div
                                        style={{
                                          color: "Red",
                                          fontSize: "16px",
                                          padding: "10px",
                                        }}
                                      >
                                        {email}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Employee Image
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Form.Control
                                          type="file"
                                          name="image"
                                          accept="image/*"
                                          onChange={onFileSelect}
                                          className="input-mandatory"
                                        />
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Employee Password
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            placeholder="Employee Password"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Conform Password
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="cpassword"
                                            value={formData.cpassword}
                                            onChange={handleChange}
                                            placeholder="Re-enter password"
                                            id="inputEmail3"
                                            className="input-mandatory"
                                          />
                                        </InputGroup>
                                        <span className="error-message"></span>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              {/* </Row> */}

                              <Row className="mt-5 pb-3">
                                <div className="d-flex justify-content-center">
                                  <Link>
                                    <CancelButton
                                      name={"cancel"}
                                      handleClose={props.handleClose}
                                    />
                                  </Link>
                                  <SaveButton
                                    name={"save"}
                                    handleSubmit={handleSubmit}
                                  />
                                </div>
                              </Row>
                              {/* </Col> */}
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
