import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const DeleteModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-success-modal"
      >
        <div className="success-button">
          <p> Complaint Deleated Successful</p>
          {/* <p>{props.show === true ? "Added" : "Edited"}</p> */}
        </div>
      </Modal>
    </>
  );
};

export default DeleteModal;
