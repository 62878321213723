import React, { useState } from "react";
import "./Veiw_Details.css";
import "../projectsec.css";
import "../style/Modals/Modals.css";
import "../style/Form/Form.css";
import "../style/Common/Common.css";
import "../style/Tables/Tables.css";
import { Dropdown } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Files from "./Files/Files";
import Milestone from "./Milestone/Milestone";
import Tasks from "./Tasks/Tasks";
import Members from "./Members/Members";
import Overviews from "./Overviews/Overviews";
import Task_board from "./Task_board/Task_board";
import Invoice from "./Invoice/Invoice";
import TimeLog from "./TimeLog/TimeLog";
import Discussion from "./Discussion/Discussion";
import Expenses from "./Expenses/Expenses";
import BurndownChart from "./BurndownChart/BurndownChart";
import Payments from "./Payments/Payments";
import GrantChart from "./GrantChart/GrantChart";
import DeleteModal from "../Modals/DeleteModal/DeleteModal";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function Veiw_Details() {
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("Not Started");

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  return (
    <>
      <section className="Veiw_Details_main">
        <div className="projectmain-sec">
          <div className="container-fluid p-0">
            <div className=" View_details_header second-sect1 ">
              {/* view details heading start code by smita  */}
              <div className="topp-divv">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="page-title">
                      <FontAwesomeIcon
                        className=" me-2"
                        icon="fa-solid fa-layer-group"
                      />{" "}
                      Project #206 - Demo
                    </h5>
                  </div>
                  <div className="col-md-6 text-end">
                    <div className="btn-group">
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item Pinned" role="presentation">
                          <Link to={""}>
                            <button
                              className="nav-link"
                              onClick={() => setDeleteModalShow(true)}
                            >
                              <FontAwesomeIcon
                                className=""
                                icon="fa-solid fa-thumbtack"
                              />
                            </button>
                            <DeleteModal
                              show={deleteModalShow}
                              onHide={() => setDeleteModalShow(false)}
                            />
                          </Link>
                        </li>
                        <li className="nav-item Project" role="presentation">
                          <Link to="/project_template">
                            <button className="nav-link">
                              <FontAwesomeIcon
                                className=" me-2"
                                icon="fa-solid fa-plus"
                              />
                              Add Payment
                            </button>
                          </Link>
                        </li>
                        <li
                          className="nav-item not_started"
                          role="presentation"
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              className="nav-link dropdown-button lg-text"
                              variant="primary"
                              id="status-dropdown"
                            >
                              {selectedStatus}
                              <FontAwesomeIcon
                                className="ms-2"
                                icon={faCircle}
                                color={
                                  selectedStatus === "In Progress"
                                    ? "blue"
                                    : selectedStatus === "Finished"
                                    ? "green"
                                    : selectedStatus === "Canceled"
                                    ? "red"
                                    : "orange"
                                }
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  handleStatusChange("In Progress")
                                }
                              >
                                In Progress{" "}
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon={faCircle}
                                  color="blue"
                                />
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleStatusChange("Finished")}
                              >
                                Finished{" "}
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon={faCircle}
                                  color="green"
                                />
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleStatusChange("Canceled")}
                              >
                                Canceled{" "}
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon={faCircle}
                                  color="red"
                                />
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  handleStatusChange("Not Started")
                                }
                              >
                                Not Started{" "}
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon={faCircle}
                                  color="orange"
                                />
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleStatusChange("On Hold")}
                              >
                                On Hold{" "}
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon={faCircle}
                                  color="orange"
                                />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>

                        <li className="nav-item Add" role="presentation">
                          <Link to="/project/view-details/edit-project">
                            <button className="nav-link text-success">
                              <FontAwesomeIcon
                                className=" me-2"
                                icon="fa-solid fa-edit"
                              />
                              Edit
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* view details heading end code by smita  */}

              {/* view details tab start code by smita */}
              <div className="view_details_content">
                <div className="row">
                  <Tabs
                    defaultActiveKey="Overview"
                    id="justify-tab-example"
                    className="tabs mb-3"
                    justify
                  >
                    {/* overview tab start code by smita  */}
                    <Tab
                      eventKey="Overview"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className=" me-1"
                            icon="fa-solid fa-table-cells-large"
                          />
                          Overview
                        </span>
                      }
                    >
                      <Overviews />
                    </Tab>
                    {/* overview tab end code by smita  */}

                    {/* Members tab content start (smita ) */}
                    <Tab
                      eventKey="Members"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-users"
                          />
                          Members
                        </span>
                      }
                    >
                      <Members />
                    </Tab>
                    {/* Members tab content end (smita ) */}

                    {/* Milestones tab content start (smita) */}
                    <Tab
                      eventKey="Milestones"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-flag"
                          />
                          Milestones
                        </span>
                      }
                    >
                      <Milestone />
                    </Tab>
                    {/* Milestones tab content end (smita) */}

                    {/* Tasks tab content start (smita) */}
                    <Tab
                      eventKey="Tasks"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-square-check"
                          />
                          Tasks
                        </span>
                      }
                    >
                      <Tasks />
                    </Tab>
                    {/*  Tasks tab content start (smita )*/}

                    {/* Task_Board tab content start (smita) */}
                    <Tab
                      eventKey="Task_Board"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-table-columns"
                          />
                          Task Board
                        </span>
                      }
                    >
                      <Task_board />
                    </Tab>
                    {/* Task_Board tab content start (smita) */}

                    {/* Files tab content start (Saurav) */}
                    <Tab
                      eventKey="Files"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-file"
                          />
                          Files
                        </span>
                      }
                    >
                      <Files />
                    </Tab>
                    {/* Files tab content end (Saurav) */}

                    {/* Invoices tab content start (saurav) ) */}
                    <Tab
                      eventKey="Invoices"
                      className="me-2"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-file"
                          />
                          Invoices
                        </span>
                      }
                    >
                      <Invoice />
                    </Tab>
                    {/* Invoices tab content end (saurav) ) */}

                    {/* Time Logs tab content start (smita ) */}
                    <Tab
                      eventKey="Time_Logs"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-clock"
                          />
                          Time Logs
                        </span>
                      }
                    >
                      <TimeLog />
                    </Tab>
                    {/*  Time Logs tab content end (smita ) */}

                    {/* Discussion tab content start (smita ) */}
                    <Tab
                      eventKey="Discussion"
                      title={
                        <span>
                          <FontAwesomeIcon
                            className="me-1"
                            icon="fa-solid fa-comment"
                          />
                          Discussion
                        </span>
                      }
                    >
                      <Discussion />
                    </Tab>
                    {/* Discussion tab content end (smita ) */}

                    <Tab
                      eventKey="drops"
                      title={
                        <FontAwesomeIcon
                          className="drops "
                          icon="fa-solid fa-gears"
                          onClick={handleDropdownToggle}
                        />
                      }
                    >
                      <Dropdown
                        show={showDropdown}
                        onClose={() => setShowDropdown(false)}
                      >
                        <Dropdown.Menu className="menu-div">
                          <Dropdown.Item
                            href=""
                            onClick={() => setSelectedItem("Burndown")}
                            className="lg-text"
                          >
                            <FontAwesomeIcon
                              className="settt-icc me-2"
                              icon="fa-solid fa-chart-line"
                            />
                            Burndown Chart
                          </Dropdown.Item>
                          <Dropdown.Item
                            href=""
                            onClick={() => setSelectedItem("Expenses")}
                            className="lg-text"
                          >
                            <FontAwesomeIcon
                              className="settt-icc me-2"
                              icon="fa-solid fa-shopping-cart"
                            />
                            Expenses
                          </Dropdown.Item>
                          <Dropdown.Item
                            href=""
                            onClick={() => setSelectedItem("Payments")}
                            className="lg-text"
                          >
                            <FontAwesomeIcon
                              className="settt-icc me-2"
                              icon="fa-solid fa-money-bill"
                            />
                            Payments
                          </Dropdown.Item>
                          <Dropdown.Item
                            href=""
                            onClick={() => setSelectedItem("GanttChart")}
                            className="lg-text"
                          >
                            <FontAwesomeIcon
                              className="settt-icc me-2"
                              icon="fa-solid fa-bar-chart"
                            />
                            Gantt Chart
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Tab>
                  </Tabs>
                </div>
                {/* view details tab end code by smita */}

                {/* show drop tab containt start (smita ) */}
                <div className="drop_content_container">
                  {/* Conditional rendering based on selectedItem */}
                  {selectedItem === "Burndown" && (
                    // Content for Burndown Chart
                    <BurndownChart />
                  )}
                  {selectedItem === "Expenses" && (
                    // Content for Expenses
                    <Expenses />
                  )}

                  {selectedItem === "Payments" && (
                    // Content for Payments
                    <Payments />
                  )}

                  {selectedItem === "GanttChart" && (
                    // Content for  Gantt Chart
                    <GrantChart />
                  )}
                </div>
                {/* show drop tab containt start (smita ) */}
              </div>
              {/* view details tab end code by smita */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Veiw_Details;
