import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";
import { Context } from "../../utils/context";
import Select from "react-select";
import Cookies from "js-cookie";

const Service = (props) => {
  const { postData, getData } = useContext(Context);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const handleSubmitClose = () => setSubmit(false);
  const handleSubmitShow = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 5000);
  };
  const [services, setServices] = useState([]);
  const [selectedservices, setSelectedservices] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    id: "",
    lead_id: props.lead_id,
    services: [],
    total: "",
  });

  const getSelectData = async () => {
    const res = await getData("/masters/allservice");
    const option = [];
    if (res?.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "service_id",
          label: data.name,
          price: Number(data.price),
        });
      });
    }
    setServices(option);
  };

  useEffect(() => {
    getSelectData();
  }, []);

  const handleService = async (e) => {
    setSelectedservices(e);
  };

  useEffect(() => {
    const total = selectedservices.reduce((acc, service) => {
      return acc + service.price;
    }, 0);
    const updatedFormData = {
      ...formData,
      services: selectedservices,
      total: total,
    };
    setFormData(updatedFormData);
  }, [selectedservices]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    try {
      const response = await postData(
        "/lead_management/lead-service",
        formData
      );
      if (response?.success) {
        setFormData({
          ...formData,
          id: response.data.id ? response.data.id : "",
        });
        handleSubmitShow();
        setErrors({});
      } else {
        if (response?.message?.errors?.lead_id) {
          props.handleErrorShow();
        } else {
          setErrors(response?.message?.errors);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const getEditData = async () => {
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      const response = await getData(
        `/lead_management/lead-service/${lead_id}`
      );
      setFormData(response?.data);
      setSelectedservices(response.data.services);
    }
  };

  useEffect(() => {
    getSelectData();
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      getEditData();
    } else {
      setFormData({
        id: "",
        lead_id: props.lead_id,
        services: [],
        total: "",
      });
      setSelectedservices([]);
    }
  }, [props?.formStatus]);

  return (
    <div className="main-box mt-2">
      <div className="box-title-div">
        <div className="row">
          <div className="col-md-10 pe-0">
            <div className="sec-1">
              <p className="call-statusp">Service You Want </p>
            </div>
          </div>
          <div className="col-md-2 ps-0">
            <div className="sec-2">
              <div className="d-flex justify-content-center">
                {props.formStatus.three ? (
                  <Button
                    className="save-fafa p-0 btn"
                    onClick={handleSubmit}
                    disabled={loading} // Disable button while loading
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className=" save-fafa"
                        icon="fa-solid fa-floppy-disk"
                      />
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-content-div pc-sec pb-0">
        <div className="washing-mach-sec">
          <Select
            placeholder="Select Services"
            className=" "
            name="service_id"
            isMulti
            value={selectedservices}
            onChange={handleService}
            options={services}
          />

          {formData.services.length === 0 ? (
            <p style={{ textAlign: "center" }}>
              <b>Add Services</b>{" "}
              <b>
                {" "}
                {errors ? (
                  <p style={{ color: "red" }}>{errors?.services?.msg}</p>
                ) : null}
              </b>
            </p>
          ) : (
            formData?.services?.map((data, index) => {
              return (
                <div className="row mt-2" key={index}>
                  <div className="col-md-6">
                    <p className="servicing-ppp">{data?.label}</p>
                    <p className="servicing-date">4/11/23</p>
                  </div>
                  <div className="col-md-6 text-end">
                    <p className="servicing-ppp"> ₹ {data?.price}/-</p>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="row total-final-div">
          <div className="col-3">
            <p className="total-ppp">Total</p>
          </div>
          <div className="col-4">
            <div className="total-fff-line"></div>
          </div>
          <div className="col-5 text-end">
            <p className="tottal-ppp-price">{formData?.total}/-</p>
          </div>
        </div>
      </div>

      <section>
        <Modal
          show={submit}
          onHide={handleSubmitClose}
          backdrop="static"
          keyboard={false}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="addproduct-pop-section"
        >
          <Modal.Header closeButton>
            <Modal.Title>Services Submitted</Modal.Title>
          </Modal.Header>

          <div className="box">
            <div className="success alert">
              <div className="alert-body">Success !</div>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
};

export default Service;
