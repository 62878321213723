import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function SubTaskModal(props) {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
       <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Sub Task
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="">Name</Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      placeholder="Name"
                    />
                    <Form.Control type="hidden" name="Name" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="">Due Date</Form.Label>
                    <div>
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      name="start_date"
                                      id="start_date"
                                      autoComplete="off"
                                      className="form-control"
                                    />
                                  </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <Form>
            <Button className="btn btn-success save-btn my-2" type="submit">
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
    </>
  )
}

export default SubTaskModal