import React, { useContext, useState, useEffect } from "react";
import { Button, Container } from 'react-bootstrap'
import './HrmsDashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HrmsDashboardChart from '../../Hrms-Dashboard-Chart/HrmsDashboardChart'
import HrmsDashboardChartTwo from '../../Hrms-Dashboard-Chart/HrmsDashboardChartTwo'
import ApplyLeaveModal from '../../Hrms-Modals/ApplyLeaveModal';
// import ApplyLeaveModal from "../../Hrms-Dashboard/Hrms-Modals/ApplyLeaveModal";
import { Context } from "../../../../utils/context";
import { DatePicker } from "antd";
import Chart from "react-apexcharts";
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;



const HrmsDashboard = () => {

    const {
        getData,
        postData,
        deleteData,
        userdata
    } = useContext(Context);

    const [openApplyLeaveModal, setOpenApplyLeaveModal] = useState(false)
    console.log("openApplyLeaveModal", openApplyLeaveModal)



    const [attendanceData, setAttendanceData] = useState([]);
    const [chartOptions, setChartOptions] = useState({
        series: [
            {
                name: "Present",
                data: [5, 10, 8, 32, 6, 9, 9, 7, 4, 10, 3, 5],
            },
            {
                name: "Absent",
                data: [5, 10, 8, 12, 6, 5, 9, 7, 4, 6, 3, 5],
            },
            {
                name: "Late Marks",
                data: [2, 5, 3, 6, 4, 5, 3, 2, 1, 3, 2, 4],
            },
        ],
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        // title: {
        //     text: "Employee Attendance Trends by Month",
        //     align: "left",
        // },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            labels: {
                show: false, // Hide default labels
            },
        },
        yaxis: {
            // title: {
            //     text: "Count",
            // },
            min: 0,
            max: 50,
            tickAmount: 5,
            labels: {
                formatter: (val) => Math.floor(val),
            },
        },
    });

    const [leaveData, setLeaveData] = useState([]);

    const [chartOptions2, setChartOptions2] = useState({
        series: [],
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            labels: {
                show: false,
            },
        },
        yaxis: {
            min: 0,
            max: 50,
            tickAmount: 5,
            labels: {
                formatter: (val) => Math.floor(val),
            },
        },
    });

    // Define colors for each month
    const labelColors = [
        "#FF5733", // January
        "#33FF57", // February
        "#3357FF", // March
    ];

    const [dates, setDates] = useState([new Date(), new Date()]);
    const [data, setData] = useState([]);
    const GetDataAll = async () => {
        console.log("datesdates", dates);

        const response = await getData(`/hrms-masters/dashboard?start=${dates[0]}&&end=${dates[1]}`);
        if (response?.data) {
            setData(response?.data);
        }
    };

    // const [leaveData, setLeaveData] = useState([]);
    const GetDataLeaveChart = async () => {
        const response = await getData(`/hrms-masters/dashboard/leave-chart`);
        if (response?.data) {
            setLeaveData(response?.data);
        }
    };

    const GetDataAttChart = async () => {
        const response = await getData(`/hrms-masters/dashboard/attendance-chart`);
        if (response?.data) {
            setAttendanceData(response?.data);
        }
    };

    useEffect(() => {
        // Prepare the series data based on leaveData
        const appliedLeaves = leaveData.map(data => data.total_leaves);
        const approvedLeaves = leaveData.map(data => data.approved_leaves);
        const rejectedLeaves = leaveData.map(data => data.rejected_leaves);
        const pendingLeaves = leaveData.map(data => data.pending_leaves);

        // Update the chart options with the new data
        setChartOptions2(prevOptions => ({
            ...prevOptions,
            series: [
                { name: "Applied Leaves", data: appliedLeaves },
                { name: "Approved Leaves", data: approvedLeaves },
                { name: "Rejected Leaves", data: rejectedLeaves },
                { name: "Pending Leaves", data: pendingLeaves },
            ],
        }));
    }, [leaveData]);

    useEffect(() => {
        // Prepare the series data based on leaveData
        const presents = attendanceData.map(data => data.present);
        const absents = attendanceData.map(data => data.absent);
        const lates = attendanceData.map(data => data.late);
        // Update the chart options with the new data
        setChartOptions(prevOptions => ({
            ...prevOptions,
            series: [
                { name: "Present", data: presents },
                { name: "Absent", data: absents },
                { name: "Late Marks", data: lates },

            ],
        }));
    }, [attendanceData]);



    useEffect(() => {
        GetDataAll();
        GetDataLeaveChart();
        GetDataAttChart();
    }, [getData]);
    useEffect(() => {
        GetDataAll();
    }, [dates]);

    const today = dayjs();

    const getGreeting = () => {
        const currentHour = new Date().getHours();

        if (currentHour < 12) {
            return 'Good Morning';
        } else if (currentHour < 18) {
            return 'Good Afternoon';
        } else {
            return 'Good Evening';
        }
    };
    return (
        <div className='section-hrms-dashboard'>
            <div className="section-hrms-dashboard-div container-fluid">

                <div className='dashboard-heading-div'>
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-between  align-items-center dashboard-heading-row-div">
                            <div className='dashboard-heading-text'>
                                <span>{getGreeting()} , {userdata?.name}  </span>
                            </div>
                            <div className='dashboard-heading-button'>
                                <Button
                                    type="button"
                                    onClick={() => setOpenApplyLeaveModal(true)}
                                    id="save-form"
                                    variant="success"
                                    className="custom-apply-leaves-btn"
                                >
                                    Apply Leaves
                                    <span className="icon-container">
                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='card-section'>
                    <RangePicker
                        className="input-mandatory"
                        defaultValue={[today, today]}
                        onChange={(values) => {
                            if (values && values.length > 0) {
                                const formattedDates = values.map((item) => {
                                    const year = item.$y; // Get the year
                                    const month = (item.$M + 1).toString().padStart(2, '0'); // Correctly format the month (0-indexed)
                                    const day = item.$D.toString().padStart(2, '0'); // Format the day
                                    return `${year}-${month}-${day}`; // Return formatted date
                                });
                                setDates(formattedDates);
                            }
                        }}

                    />
                    <div className="row">


                        {/* Total Applied Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card4.png"} alt="Card 4" />
                                </div>
                                <div className='text-container'>
                                    <p>Total Applied <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>{data?.total_leaves}</p>
                                </div>
                            </div>
                        </div>


                        {/* Approved Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card5.png"} alt="Card 5" />
                                </div>
                                <div className='text-container'>
                                    <p>Approved <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>{data?.approved_leaves}</p>
                                </div>
                            </div>
                        </div>

                        {/* Rejected Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card6.png"} alt="Card 6" />
                                </div>
                                <div className='text-container'>
                                    <p>Rejected <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>{data?.rejected_leaves}</p>
                                </div>
                            </div>
                        </div>

                        {/* Pending Leaves Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card7.png"} alt="Card 7" />
                                </div>
                                <div className='text-container'>
                                    <p>Pending <br />Leaves</p>
                                </div>
                                <div className='count-container'>
                                    <p>{data?.pending_leaves}</p>
                                </div>
                            </div>
                        </div>

                        {/* Total Present Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card1.png"} alt="Card 1" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Present</p>
                                </div>
                                <div className='count-container'>
                                    <p>{data?.total_present}</p>
                                </div>
                            </div>
                        </div>

                        {/* Total Absent Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card2.png"} alt="Card 2" />
                                </div>
                                <div className='text-container'>
                                    <p>Total <br />Absent</p>
                                </div>
                                <div className='count-container'>
                                    <p>{data?.total_absent > 0 ? data?.total_absent : 0}</p>
                                </div>
                            </div>
                        </div>

                        {/* Late Marks Card */}
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                            <div className='home-cards'>
                                <div className='img-holder'>
                                    <img src={process.env.PUBLIC_URL + "/assets/hrms-dashboard/Image/card3.png"} alt="Card 3" />
                                </div>
                                <div className='text-container'>
                                    <p>Late <br />Marks</p>
                                </div>
                                <div className='count-container'>
                                    <p>{data?.total_late}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='chart-main-div'>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                            <div className='first-chart-div'>
                                <Chart
                                    options={chartOptions}
                                    series={chartOptions.series}
                                    type={chartOptions.chart.type}
                                    height={chartOptions.chart.height}
                                    className={"chart"}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                            <div className='second-chart-div'>
                                <Chart
                                    options={chartOptions2}
                                    series={chartOptions2.series}
                                    type={chartOptions2.chart.type}
                                    height={chartOptions2.chart.height}
                                    className={"chart"}
                                />
                            </div>
                        </div>
                    </div>
                </div>



                <ApplyLeaveModal
                    show={openApplyLeaveModal}
                    onHide={() => setOpenApplyLeaveModal(false)} // Set onHide to close modal
                />

            </div>
        </div>

    )
}

export default HrmsDashboard