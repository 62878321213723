import React, { useState, useEffect, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import ProjectDashboard from "./ProjectDashboard/ProjectDashboard";
import ProjectHeader from "./ProjectHeader/ProjectHeader";
import ProjectSidebar from "./ProjectSidebar/ProjectSidebar";
import LoginPage from "../../Components/Admin/Login/LoginPage/LoginPage";
import ProjectAdd from "./ProjectAdd/ProjectAdd";
import ProjectClient from "./ProjectClient/ProjectClient";
import ProjectTeam from "./ProjectTeam/ProjectTeam";
import ProjectTask from "./ProjectTask/ProjectTask";
import ProjectInnerPage from "./ProjectAdd/ProjectInnerPage/ProjectInnerPage";
import AddUser from "./RolesPermission/AddUser/AddUser";
import AddRole from "./RolesPermission/AddUser/AddRole";
import PageNotFound from "./PageNotFound/PageNotFound";

import { IDS } from "../../utils/common";
import { Context } from "../../utils/context";

const ProjectManagementRoutes = () => {
  const location = useLocation();
  const isLoginpage = location.pathname === "/login";
  const [closed, setClosed] = useState(true);
  const sidebarToggle = () => {
    setClosed(!closed);
  };

  const { isAllow } = useContext(Context);
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "login",
        element: <LoginPage />,
        status: true,
      },
      {
        path: "/projectDashboard",
        element: <ProjectDashboard />,
        status: true,
      },
      {
        path: "/add-user",
        element: <AddUser />,
        status: isAllow.includes(IDS.User.List),
      },
      {
        path: "/add-role",
        element: <AddRole />,
        status: isAllow.includes(IDS.Role.List),
      },
      {
        path: "/project-client",
        element: <ProjectClient />,
        status: isAllow.includes(IDS.Client.List),
      },
      {
        path: "/project-add",
        element: <ProjectAdd />,
        status: isAllow.includes(IDS.Project.List),
      },
      {
        path: "/project-team",
        element: <ProjectTeam />,
        status: isAllow.includes(IDS.Team.List),
      },
      {
        path: "/project-task",
        element: <ProjectTask />,
        status: isAllow.includes(IDS.Task.List),
      },
      {
        path: "/project-inner/:id",
        element: <ProjectInnerPage />,
        status: isAllow.includes(IDS.Project.List),
      },
    ]);
  }, [isAllow]);

  return (
    <section style={{ height: "100vh", display: "flex" }}>
      {!isLoginpage && (
        <div
          className={`sidebar ${closed ? "sidebar-closed" : "sidebar-open"}`}
        >
          <ProjectSidebar />
        </div>
      )}
      <div
        className={`content ${
          isLoginpage
            ? "content-full"
            : closed
            ? "content-closed"
            : "content-open"
        }`}
        style={{ overflowY: "auto" }}
      >
        {!isLoginpage && <ProjectHeader sidbarclose={sidebarToggle} />}
        <Routes>
          {urls?.map(
            (url) =>
              url.status && <Route path={url.path} element={url.element} />
          )}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </section>
  );
};

export default ProjectManagementRoutes;
