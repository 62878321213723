import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import '../../../CommonCss/ModalCommonCss.css';
import PaymentSlipDeleteModal from './PaymentSlipDeleteModal';

const PaymentSlipModal = (props) => {
    const [PaymentSlipModalShow, setPaymentSlipModalShow] = useState(false);

    const handleDownload = () => {
        props.onHide();
        setPaymentSlipModalShow(true);


        setTimeout(() => {
            setPaymentSlipModalShow(false);
        }, 3000);
    };

    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='below-bodyy'>
                        <div className='text-center mt-5 mb-5'>
                            <button className='cancel-button me-2' onClick={props.onHide}>Cancel</button>
                            <button className='download-button' onClick={handleDownload}>Download</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <PaymentSlipDeleteModal
                show={PaymentSlipModalShow}
                onHide={() => setPaymentSlipModalShow(false)}
            />
        </section>
    );
};

export default PaymentSlipModal;
