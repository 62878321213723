import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../../Components/Admin/assets/icons/a1.png";
import colunms from "../../../../Components/Admin/assets/icons/LINES.png";
import pen from "../../../../Components/Admin/assets/icons/pen.png";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import top from "../../../../Components/Admin/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import ModalDelete from "../../../common/ModalDelete";

import { Toaster } from "react-hot-toast";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";

library.add(fas);

const Tables = () => {
  const {
    getData,
    postData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [priority, setPriority] = useState("");
  const [searchpriority, setSearchPriority] = useState("");

  const [channel, setChannel] = useState("");
  const [searchchannel, setSearchChannel] = useState("");

  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  const [formData, setFormData] = useState({
    assign_to_id: "",
    ids: [],
  });

  const [employee, setEmployees] = useState([]);
  const [searchemployee, setSearchEmployees] = useState([]);

  const getDataAll = async () => {
    const response = await getData(
      `/ticket_management/ticket_generates?page=${currentPage}&per_page=${perPage}&term=${search}
      &priority=${searchpriority.value ? searchpriority.value : ""}
      &user=${searchemployee.value ? searchemployee.value : ""}
      &channel=${searchchannel.value ? searchchannel.value : ""}`
    );

    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setChannel(response?.data?.search_name);
    setPriority(response?.data?.search_name);
    setEmployees(response?.data?.search_name);

    // setCurrentPage(response?.data?.);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }

    const res = await getData("/masters/allusers");
    const option = [];
    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data?.userid,
          name: "assign_to_id",
          label: data?.user?.name,
        });
      });
    }
    setEmployees(option);

    const res2 = await getData("/masters/all-channel");

    if (res2.success) {
      setChannel(res2?.data);
    }

    const res3 = await getData("/masters/all-priority");

    if (res3.success) {
      setPriority(res3?.data);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(
      `/ticket_management/ticket_generates/${id}`
    );
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  useEffect(() => {
    setFormData({ ...formData, ids: selectAllChecked });
    if (selectAllChecked.length === 0) {
      TicketTransferHide();
      setFormData({ assign_to_id: "", ids: [] });
    }
  }, [selectAllChecked]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/ticket_management/ticket_generates/${recordToDeleteId}`
      );

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const handleSelect = async (e) => {
    setFormData({ ...formData, [e.name]: e });
  };

  // Lead Transfer
  const [tickettransfer, setTickettransfer] = useState(false);

  const TicketTransferShow = () => {
    if (selectAllChecked.length === 0) {
      alert("Please Select At Least One Record");
    } else {
      setTickettransfer(true);
    }
  };

  const TicketTransferHide = () => {
    setTickettransfer(false);
  };
  const [errors, setErrors] = useState({});

  const TicketTransfer = async () => {
    try {
      const response = await postData(
        "/ticket_management/ticket_generates/tickenttransfer",
        formData
      );

      if (response?.success) {
        setFormData({ assign_to_id: "", ids: [] });
        setSelectAllChecked([]);
        setErrors({});
        getDataAll();
      } else {
        setErrors(response?.message?.errors);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header
          title={" Generate Ticket"}
          link={"/ticket_management/ticket_generates"}
        />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <section className="Tabels tab-radio tab-radio mt-5">
              <div className="">
                <div className="row">
                  <div className="d-flex">
                    <div className="add me-3">
                      {isAllow.includes(54) ? (
                        <Link type="button" className="btn btn-add pe-3">
                          <div onClick={() => handleShow()}>
                            <img src={plus} className="plus me-2 ms-0" alt="" />
                            Generate Ticket
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="add">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={colunms} className="columns " alt="" />
                          Column Selection{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col0")}
                              href="#"
                            >
                              Check Box
                              {visible?.col0 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col1")}
                              href="#"
                            >
                              Sr. No.
                              {visible?.col1 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col11")}
                              href="#"
                            >
                              Requestor Type
                              {visible?.col11 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col2")}
                              href="#"
                            >
                              Requestor Name
                              {visible?.col2 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col5")}
                              href="#"
                            >
                              Employee
                              {visible?.col5 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col6")}
                              href="#"
                            >
                              Title
                              {visible?.col6 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col7")}
                              href="#"
                            >
                              Description
                              {visible?.col7 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col8")}
                              href="#"
                            >
                              Priority
                              {visible?.col8 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col9")}
                              href="#"
                            >
                              Channel
                              {visible?.col9 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col10")}
                              href="#"
                            >
                              Assigned To
                              {visible?.col10 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Status
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Action
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-4">
                    <div className="  col-lg-4 col-md-4 col-12">
                      <div className="d-flex">
                        <div className="show me-2">
                          <p className="show mt-1">Show</p>
                        </div>
                        <div className="number me-2">
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={(e) => setperPage(e.target.value)}
                          >
                            {option?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="entries">
                          <p className="show mt-1">Entries</p>
                        </div>
                      </div>
                    </div>

                    <div className="  col-lg-4  col-md-8 col-6">
                      <div className="d-flex justify-content-end">
                        <div className="sowing me-2">
                          <p className="show mt-2">{`Showing ${Math.min(
                            (currentPage - 1) * perPage + 1
                          )} to ${Math.min(
                            currentPage * perPage,
                            data?.data?.totalEntries
                          )} of ${data?.data?.totalEntries} Entries`}</p>
                        </div>
                        <div className="num me-2">
                          <input
                            type="text"
                            className="form-control"
                            id=""
                            value={search}
                            placeholder="Requestor Name"
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <Col md={2}>
                      <Select
                        placeholder="Employee"
                        className="custom-select"
                        onChange={(e) => {
                          setSearchEmployees(e);
                        }}
                        value={searchemployee}
                        options={employee}
                      />
                    </Col>

                    <Col md={2}>
                      <Select
                        placeholder="priority"
                        className="custom-select"
                        onChange={(e) => {
                          setSearchPriority(e);
                        }}
                        value={searchpriority}
                        options={priority}
                      />
                    </Col>
                    <Col md={2}>
                      <Select
                        placeholder="Channel"
                        className="custom-select"
                        onChange={(e) => {
                          setSearchChannel(e);
                        }}
                        value={searchchannel}
                        options={channel}
                      />
                    </Col>
                    <Col md={3}>
                      <button
                        type="button"
                        onClick={getDataAll}
                        className="btn btn-search me-3"
                      >
                        {/* <img src={search1} className="search" alt="" /> */}
                        Search
                      </button>

                      <button
                        type="button"
                        onClick={() => {
                          setSearch("");
                          setSearchChannel("");
                          setSearchEmployees("");
                          setSearchPriority("");
                          setReset((prevReset) => !prevReset);
                        }}
                        className="btn btn-reset"
                      >
                        Reset
                      </button>
                    </Col>
                  </div>

                  <div className="">
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-3 col-12">
                        Ticket Report :
                        <Button
                          variant="info"
                          onClick={TicketTransferShow}
                          className="ms-1"
                        >
                          Ticket Transfer
                        </Button>
                      </div>
                      {tickettransfer ? (
                        <>
                          <div className="col-lg-3 col-md-3 col-12">
                            <Select
                              name="assign_to_id"
                              className="custom-select"
                              onChange={handleSelect}
                              value={formData?.assign_to_id}
                              options={employee}
                            />
                            {errors ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: "11px",
                                  textAlign: "center",
                                }}
                              >
                                {errors?.assign_to_id?.msg}
                              </p>
                            ) : null}
                          </div>

                          <div className="col-lg-3 col-md-3 col-12">
                            <Button variant="primary" onClick={TicketTransfer}>
                              Transfer
                            </Button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="border-line mt-3"></div>
                <div className="row mt-3">
                  <div className="data table-responsive">
                    <Table striped bordered hover responsive center>
                      <thead>
                        <tr className="">
                          {visible.col0 && (
                            <th className="check round-check">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="selectAll"
                                  checked={
                                    allChecked.length ===
                                    selectAllChecked.length
                                  }
                                  onChange={handleChange}
                                  id="selectAll"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckChecked"
                                ></label>
                              </div>
                            </th>
                          )}
                          {visible.col1 && <th className="sr">Sr. No.</th>}

                          {visible.col11 && (
                            <th className="tax-name">Requestor Type</th>
                          )}

                          {visible.col2 && (
                            <th className="tax-name">Requestor Name</th>
                          )}
                          {visible.col5 && (
                            <th className="tax-name">Employee</th>
                          )}

                          {visible.col6 && <th className="tax-name">Title</th>}
                          {visible.col7 && (
                            <th className="tax-name">Description</th>
                          )}

                          {visible.col8 && (
                            <th className="tax-name">Priority</th>
                          )}

                          {visible.col9 && <th className="tax-name">Chanel</th>}

                          {visible.col10 && (
                            <th className="tax-name">Assigned To</th>
                          )}
                          {visible.col3 && <th className="tax-name">Status</th>}
                          {visible.col4 && <th className="active">Action</th>}
                        </tr>
                      </thead>
                      <tbody className="table-body-holder">
                        {isAllow.includes(53) ? (
                          data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                {visible.col0 && (
                                  <td>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={d?.id}
                                        name="perselected"
                                        checked={selectAllChecked.includes(
                                          d?.id
                                        )}
                                        onChange={handleChange}
                                        id="flexCheckDefault"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexCheckDefault"
                                      ></label>
                                    </div>
                                  </td>
                                )}
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col11 && <td>{d?.requestor_type}</td>}
                                {visible.col2 && <td>{d?.requestor_name}</td>}
                                {visible.col5 && <td>{d?.employee?.name}</td>}
                                {visible.col6 && <td>{d?.title}</td>}
                                {visible.col7 && <td>{d?.description}</td>}
                                {visible.col8 && <td>{d?.priority?.name}</td>}
                                {visible.col9 && (
                                  <td>{d?.chanel_name?.name}</td>
                                )}
                                {visible.col10 && (
                                  <td>
                                    {d?.assign_to
                                      ? d.assign_to.name
                                      : "Not Assigned"}
                                  </td>
                                )}
                                {visible.col3 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        checked={d.status}
                                        onChange={() => {
                                          ChangeStatus(d.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d.status ? "Active" : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                )}

                                {visible.col4 && (
                                  <td>
                                    <div className="d-flex">
                                      {isAllow.includes(55) ? (
                                        <Button
                                          // to={`/ticket_management/ticket_generates/edit/${d?.id}`}
                                          onClick={() => handleShow1(d?.id)}
                                          type="button"
                                          className="btn btn-primary me-1"
                                        >
                                          <img
                                            src={pen}
                                            className="pen"
                                            alt=""
                                          />
                                        </Button>
                                      ) : (
                                        <></>
                                      )}

                                      {isAllow.includes(56) ? (
                                        <DeletButton
                                          showDeleteRecord={showDeleteRecord}
                                          id={d?.id}
                                          name={d?.requestor_name}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      {/* <!-- Modal Delete --> */}
      <ModalDelete
        show={showDeleteModal}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteCancel={handleDeleteCancel}
      />
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
