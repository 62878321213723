import React, { useState, handlelick, active, useContext } from "react";
import "./ComplaintSidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../utils/context";
import { useEffect } from "react";
library.add(fas);

const CompalintSidebar = () => {
  const { isAllow } = useContext(Context);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("0");

  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    if (active == itemKey) {
      setActive(null);
    } else {
      setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
      setIsOpen(true);
    }
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  return (
    <>
      <section
        style={{ width: isOpen ? "300px" : "71px" }}
        className="sidebar-complaint"
      >
        <div className="  top_section">
          <div style={{ display: isOpen ? "block" : "none" }} className="logo">
            <FontAwesomeIcon
              icon="fa-solid fa-grip"
              className="grid-icon me-1"
            />
            <span>Dashboard </span>
            <FontAwesomeIcon
              icon="fa-solid fa-magnifying-glass"
              className="grid-icon "
            />
            <FontAwesomeIcon icon="fa-solid fa-plus" className="grid-icon " />
          </div>
          <div style={{ marginLeft: isOpen ? "39px" : "0px" }} className="bars">
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>
        <ul className="list-unstyled ps-0 mt-2">
          <Accordion defaultActiveKey={active} className="mb-1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p style={{ display: isOpen ? "block" : "none" }}>
                  {" "}
                  ALL DASHBOARDS
                </p>
              </Accordion.Header>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <p style={{ display: isOpen ? "block" : "none" }}>
                  POPULAR DASHBOARDS
                </p>
              </Accordion.Header>
              <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <Link
                    to="Overview-complaint"
                    className="text-decoration-none "
                  >
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("Overview-complaint")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                    >
                      <p style={{ display: isOpen ? "block" : "none" }}>
                        Overview Complaint
                      </p>
                    </li>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <p style={{ display: isOpen ? "block" : "none" }}>Masters</p>
              </Accordion.Header>
              {/* <Accordion.Body style={{ display: isOpen ? "block" : "none" }} >
                <ul className=" list-unstyled fw-normal pb-1 small">

                  <Link
                    to="/complaint_management/complaint_channel"
                    className="text-decoration-none "
                  >
                    <li
                      className={`${isOpen
                        ? headerText.includes("/complaint_management/complaint_channel")
                          ? "active block"
                          : "none"
                        : "none"
                        }`}
                      onClick={() => handleInnerListItemClick("complaint_channel")}
                    >
                      <p

                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Channel
                      </p>
                    </li>
                  </Link>

                  <Link
                    to="/complaint_management/complaint_priorities"
                    className="text-decoration-none "
                  >
                    <li
                      className={`${isOpen
                        ? headerText.includes("/complaint_management/complaint_priorities")
                          ? "active block"
                          : "none"
                        : "none"
                        }`}
                      onClick={() => handleInnerListItemClick("complaint_priorities")}
                    >
                      <p

                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Priorities
                      </p>
                    </li>
                  </Link>

                  <Link
                    to="/complaint_management/complaint_types"
                    className="text-decoration-none "
                  >
                    <li
                      className={`${isOpen
                        ? headerText.includes("/complaint_management/complaint_types")
                          ? "active block"
                          : "none"
                        : "none"
                        }`}
                      onClick={() => handleInnerListItemClick("complaint_types")}
                    >
                      <p

                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Complaint Type
                      </p>
                    </li>
                  </Link>


                  <Link
                    to="/complaint_management/complaint_status"
                    className="text-decoration-none "
                  >
                    <li
                      className={`${isOpen
                        ? headerText.includes("/complaint_management/complaint_status")
                          ? "active block"
                          : "none"
                        : "none"
                        }`}
                      onClick={() => handleInnerListItemClick("complaint_status")}
                    >
                      <p

                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Complaint Status
                      </p>
                    </li>
                  </Link>


                  <Link
                    to="/complaint_management/complaint_generates"
                    className="text-decoration-none "
                  >
                    <li
                      className={`${isOpen
                        ? headerText.includes("/complaint_management/complaint_generates")
                          ? "active block"
                          : "none"
                        : "none"
                        }`}
                      onClick={() => handleInnerListItemClick("complaint_generates")}
                    >
                      <p

                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Complaint Generates
                      </p>
                    </li>
                  </Link>



                </ul>
              </Accordion.Body> */}
            </Accordion.Item>

            {/* <Accordion.Item eventKey="2">
              <Accordion.Header>
                <p style={{ display: isOpen ? "block" : "none" }}>PUBLIC DASHBOARDS</p>
              </Accordion.Header>
            </Accordion.Item>



            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <p style={{ display: isOpen ? "block" : "none" }}> TELEPHONY DASHBOARDS</p>
              </Accordion.Header>


              <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li key={5} className={active === "5" ? "active" : undefined} id={"5"} onClick={handleClick}>
                    <p style={{ display: isOpen ? "block" : "none" }} className={active === "5" ? "active" : undefined}
                      id={"5"} onClick={handleClick}>
                      Attendance
                    </p>
                  </li>

                  <li>
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Holiday
                    </p>
                  </li>
                  <li >
                    <p style={{ display: isOpen ? "block" : "none" }}>
                      Designation
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>



            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <p style={{ display: isOpen ? "block" : "none" }}>ZIA DASHBOARDS</p>
              </Accordion.Header>
            </Accordion.Item> */}
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default CompalintSidebar;
