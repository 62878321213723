import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Row, Col, Form, Modal, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";

const Requirement = (props) => {
  const { postData, getData } = useContext(Context);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const handleSubmitClose = () => setSubmit(false);
  const handleSubmitShow = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 5000);
  };
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState({});
  const [finalproducts, setFinalproducts] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    lead_id: props.lead_id,
    total_price: "",
    total_quantity: "",
    products: "",
  });

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const getSelectData = async () => {
    const res = await getData("/masters/allproducts-list");
    const option = [];
    if (res?.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "product_id",
          label: data.name,
          price: data.margin_price,
        });
      });
    }
    setProducts(option);
  };

  useEffect(() => {
    getSelectData();
  }, []);

  useEffect(() => {
    if (props?.lead_id !== "") {
      setFormData({ ...formData, lead_id: props?.lead_id });
    }
  }, [props?.lead_id]);

  const [product, setProduct] = useState({});

  // Handle Single Product
  const handleProduct = async (e) => {
    if (e?.name === "product_id") {
      setProduct({
        ...product,
        [e.name]: e,
      });
    } else {
      setProduct({ ...product, [e.target.name]: e.target.value });
    }
  };

  // Add Particular Product in List
  const AddProduct = async (e) => {
    e.preventDefault();

    const updatedData = [...finalproducts];
    const existingProductIndex = updatedData.findIndex(
      (item) => item.product_id?.value === product.product_id?.value
    );

    if (existingProductIndex !== -1) {
      updatedData[existingProductIndex].total_quantity += parseInt(
        product.total_quantity
      );

      updatedData[existingProductIndex].total_price =
        updatedData[existingProductIndex].total_quantity *
        updatedData[existingProductIndex].price;

      setFinalproducts([...finalproducts]);
    } else {
      const newProduct = {
        product_id: product?.product_id,
        name: product.product_id?.label,
        total_quantity: parseInt(product?.total_quantity),
        total_price:
          parseInt(product.total_quantity) *
          parseInt(product.product_id?.price),
        price: parseInt(product.product_id?.price),
      };

      setFinalproducts([...finalproducts, newProduct]);
    }

    handleClose2();
  };

  // Remove Particular Product From List
  const RemoveProduct = (index) => {
    const updatedFormData = [...finalproducts];
    updatedFormData.splice(index, 1);
    setFinalproducts(updatedFormData);
  };

  // Update Particular Product Quantity
  const updateProduct = (productId, newQuantity, index) => {
    const updatedProducts = finalproducts.map((product) => {
      if (product.product_id === productId) {
        const price = product.product_id.price;

        let updatedQuantity = newQuantity;

        if (updatedQuantity < 0 || isNaN(updatedQuantity)) {
          updatedQuantity = 0;
        }

        const updatedTotalPrice = Number(updatedQuantity) * Number(price);

        return {
          ...product,
          total_quantity: updatedQuantity,
          total_price: updatedTotalPrice,
        };
      }
      return product;
    });

    // Remove the product if the total quantity becomes 0
    const productToRemove = updatedProducts.find(
      (product) => product.total_quantity === 0
    );
    if (productToRemove) {
      updatedProducts.splice(updatedProducts.indexOf(productToRemove), 1);
    }

    setFinalproducts(updatedProducts);
  };
  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d*\.?\d*$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  // Use useEffect to update totalAllProducts whenever formData changes
  useEffect(() => {
    // Calculate the total price of all products in the formData array
    const total = finalproducts.reduce((acc, product) => {
      return acc + product.total_price;
    }, 0);

    // Calculate the total quantity of all products in the formData array
    const totalQuantity = finalproducts.reduce((acc, product) => {
      return acc + product.total_quantity;
    }, 0);

    const updatedFormData = {
      ...formData,
      total_price: total,
      total_quantity: totalQuantity,
    };

    // Remove the product if the total quantity becomes 0
    updatedFormData.products = finalproducts.filter(
      (product) => product.total_quantity !== 0
    );

    // Update the state formData with the modified object including the totals
    setFormData(updatedFormData);
  }, [finalproducts]);

  // Submit Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true
    try {
      const response = await postData(
        "/lead_management/lead-requirement",
        formData
      );
      if (response?.success) {
        setFormData({
          ...formData,
          id: response.data.id ? response.data.id : "",
        });
        handleSubmitShow();
        setErrors({});
      } else {
        setErrors(response?.message?.errors);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setLoading(false); // Set loading state to false
  };

  const getEditData = async () => {
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      const response = await getData(
        `/lead_management/lead-requirement/${lead_id}`
      );
      setFormData(response?.data);
      setFinalproducts(response.data?.products);
    }
  };

  useEffect(() => {
    getSelectData();
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      getEditData();
    } else {
      setFormData({
        id: "",
        lead_id: props.lead_id,
        total_price: "",
        total_quantity: "",
        products: "",
      });
      setFinalproducts([]);
    }
  }, [props?.formStatus]);

  return (
    <div className="main-box">
      <div className="box-title-div">
        <div className="row">
          <div className="col-md-10 pe-0">
            <div className="sec-1">
              <p className="call-statusp">Requirement </p>
            </div>
          </div>
          <div className="col-md-2 ps-0">
            <div className="sec-2">
              <div className="d-flex justify-content-center">
                {props.formStatus.two ? (
                  <Button
                    className="save-fafa p-0 btn"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <FontAwesomeIcon
                        className=" save-fafa"
                        icon="fa-solid fa-floppy-disk"
                      />
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-content-div pc-sec pb-0">
        <div className="products-overflow">
          <p className="e-prod-name">Enter Product/s</p>

          {formData.products.length === 0 ? (
            <p style={{ textAlign: "center" }}>
              <b>Add Product</b>
              <b>
                {" "}
                {errors ? (
                  <p style={{ color: "red" }}>{errors?.products?.msg}</p>
                ) : null}
              </b>
            </p>
          ) : (
            formData?.products?.map((data, index) => {
              return (
                <div className="washing-mach-sec" key={index}>
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <p className="wash-p-com">{data?.product_id?.label}</p>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex">
                        <div className="input-div-width">
                          <input
                            type="tel"
                            value={data?.total_quantity}
                            className="form-control"
                            onKeyPress={handleKeyPressContact}
                            onInput={(e) => {
                              updateProduct(
                                data?.product_id,
                                parseInt(e.target.value, 10),
                                index
                              );
                            }}
                          />
                        </div>

                        <div
                          className="input-div-width"
                          onClick={() => {
                            RemoveProduct(index);
                          }}
                        >
                          <FontAwesomeIcon
                            className="faf-dle-in"
                            icon="fa-solid fa-trash"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-md-4">
                      <p className="unit-pr-p">Unit Price</p>
                    </div>
                    <div className="col-md-4">
                      <div className="hor-line"></div>
                    </div>
                    <div className="col-md-4">
                      <p className="unit-pr-p"> {data?.total_price}/-</p>
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-md-4">
                      <p className="unit-pr-p">Quantity</p>
                    </div>
                    <div className="col-md-4">
                      <div className="hor-line"></div>
                    </div>
                    <div className="col-md-4 me-auto">
                      <p className="unit-pr-p"> {data?.total_quantity}</p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        <div className="text-end">
          <Button className="add-p-btn btn" onClick={handleShow2}>
            Add Product
          </Button>
        </div>

        <div className="row total-final-div">
          <div className="col-3">
            <p className="total-ppp">Quantity</p>
          </div>
          <div className="col-4">
            <div className="total-fff-line"></div>
          </div>
          <div className="col-5 text-end">
            <p className="tottal-ppp-price">{formData?.total_quantity} </p>
          </div>
          <div className="col-3">
            <p className="total-ppp">Total</p>
          </div>
          <div className="col-4">
            <div className="total-fff-line"></div>
          </div>
          <div className="col-5 text-end">
            <p className="tottal-ppp-price">{formData?.total_price}/-</p>
          </div>
        </div>
      </div>
      {/* add product modal start  */}
      <section>
        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="addproduct-pop-section"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Product
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="add-pro-Sec">
              <Row>
                <Col md={10} className="mx-auto">
                  <Form role="form" onSubmit={AddProduct}>
                    <Form.Group
                      className="form-group"
                      controlId="formPlaintextEmail"
                    >
                      <Select
                        placeholder="Select Product"
                        className="custom-select"
                        name="product_id"
                        onChange={handleProduct}
                        options={products}
                        required
                      />
                    </Form.Group>
                    {errors ? (
                      <p style={{ color: "red", fontSize: "11px" }}>
                        {errors?.product_id?.msg}
                      </p>
                    ) : null}

                    <Form.Group
                      className="form-group"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Control
                        type="tel"
                        name="total_quantity"
                        onChange={handleProduct}
                        onKeyPress={handleKeyPressContact}
                        placeholder="Enter Quantity "
                        required
                      />
                    </Form.Group>
                    {errors ? (
                      <p style={{ color: "red", fontSize: "11px" }}>
                        {errors?.total_quantity?.msg}
                      </p>
                    ) : null}

                    {/* Add required attribute and show error message accordingly */}
                    {/* <Form.Group className="form-group" controlId="formPlaintextEmail">
            <Form.Control
              type="text"
              placeholder="Enter Price"
              name="price"
              onChange={handleProduct}
              disabled
              required
            />
          </Form.Group> */}

                    <div className="text-center mt-4">
                      <Button className="add-pro-btn btn" type="submit">
                        Add
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </section>
      {/* end product modal start  */}

      <section>
        <Modal
          show={submit}
          onHide={handleSubmitClose}
          backdrop="static"
          keyboard={false}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="addproduct-pop-section"
        >
          <Modal.Header closeButton>
            <Modal.Title>Requirement Details Submitted</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body> */}
          <div className="box">
            <div className="success alert">
              <div className="alert-body">Success !</div>
            </div>
          </div>
          {/* </Modal.Body> */}
        </Modal>
      </section>
    </div>
  );
};

export default Requirement;
