import React from "react";
import { Modal } from "react-bootstrap";
import AddedRecord from "./animation/AddedRecord";
import AlreadyExistsLottie from "./animation/AlreadyExistsLottie";

const AddedLottieModal = ({ success, message, show, onHide }) => {
  console.log("AddedLottieModal props:", { success, message, show }); // Log props for debugging

  return (
    <section className="modal-common-class-hrms">
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-modal"
      >
        <Modal.Body>
          <div className='below-bodyy'>
            <div className='donwload-div d-flex justify-content-center'>
              {success ? <AddedRecord /> : <AlreadyExistsLottie />}
              <h6 className="mt-2">{message || "Added Successfully!"}</h6>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default AddedLottieModal;
