import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import "./holiday.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Offcanvas from "react-bootstrap/Offcanvas";
import Card from "react-bootstrap/Card";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import ModalSave from "../../../common/ModalSave";
import Validation from "../../../common/FormValidation";
import { CancelButton, SaveButton } from "../../../common/Button";

import CustomDatePicker from "../../CustomDatePicker";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData } = useContext(Context);

  const initialFormData = {
    name: "",
    start: "",
    end: "",
  };
  const [formList, setFormList] = useState([initialFormData]);

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [holiday, setHoliday] = useState([]);

  const handleAddRow = () => {
    setFormList((prevFormList) => [...prevFormList, initialFormData]);
  };

  const handleRemoveRow = (index) => {
    const newList = [...formList];
    newList.splice(index, 1);
    setFormList(newList);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newList = [...formList];
    newList[index][name] = value;
    setFormList(newList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    await Promise.all(
      formList.map(async (formData, index) => {
        const validationErrors = await Validation.FormValidation({
          classname: ".holidayclass",
        });

        if (!formData.start || !formData.end) {
          isValid = false;
          const newErrors = { ...errors };
          newErrors[index] = {
            start: !formData?.start ? "Start date is required" : "",
            end: !formData.end ? "End date is required" : "",
          };
          setErrors(newErrors);
        } else {
          const selectedStartDate = new Date(formData?.start);
          const selectedEndDate = new Date(formData.end);
          const currentDate = new Date();

          if (selectedStartDate < currentDate) {
            isValid = false;
            const newErrors = { ...errors };
            newErrors[index] = {
              start: "Please select a current or future date.",
              end: "",
            };
            setErrors(newErrors);
          } else if (selectedEndDate < currentDate) {
            isValid = false;
            const newErrors = { ...errors };
            newErrors[index] = {
              start: "",
              end: "Please select a current or future date.",
            };
            setErrors(newErrors);
          } else {
            const newErrors = { ...errors };
            delete newErrors[index]?.start;
            delete newErrors[index]?.end;
            setErrors(newErrors);

            try {
              const response = await postData(
                "/hrms-masters/holiday",
                formData
              );

              if (response.success) {
                setShowModal({
                  code: response.code,
                  message: response.message,
                });
                setTimeout(() => {
                  setShowModal({ code: 0, message: "" });
                  props.handleClose();
                }, 1000);
              } else {
                setShowModal({
                  code: response.code,
                  message: response.message,
                });
                setTimeout(() => {
                  setShowModal({ code: 0, message: "" });
                  props.handleClose();
                }, 1000);
              }
            } catch (error) {
              console.error("Error submitting form:", error);
            }
          }
        }

        return formData;
      })
    );

    if (!isValid) {
      return;
    }
  };

  const Holiday = async () => {
    const response = await getData("/masters/allholidays");
    setHoliday(response);
  };

  useEffect(() => {
    Holiday();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Event</Card.Title>
              <hr />

              <Row style={{ margin: "0% 15% 0% 15%" }}>
                <Col md={12}>
                  {formList.map((formData, index) => (
                    <Row key={index}>
                      <Col xxl={12} xl={12} md={12}>
                        <Form
                          onSubmit={(e) => handleSubmit(e, index)}
                          role="form"
                          className="holidayclass"
                        >
                          <Row>
                            <Col lg={6} md={6} className="mx-auto Add-content">
                              <Row>
                                <Col md={12}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={6}>
                                        Add Holiday
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <InputGroup>
                                            <Form.Control
                                              type="text"
                                              name="name"
                                              value={formData?.name}
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                              placeholder="Holiday "
                                              id="inputEmail3"
                                              className="input-mandatory"
                                            />
                                          </InputGroup>
                                          <span className="error-message"></span>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col md={12}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={6}>
                                        Start Date
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <CustomDatePicker
                                            wrapperClassName="datepicker"
                                            className="input-mandatory"
                                            selected={formData?.start}
                                            onChange={(e) => {
                                              const selectedDate = new Date(e);
                                              const currentDate = new Date();

                                              if (selectedDate >= currentDate) {
                                                // Make sure the error list exists for this index
                                                const newErrors = { ...errors };
                                                if (newErrors[index]) {
                                                  delete newErrors[index].start;
                                                }

                                                setErrors(newErrors);

                                                // Update form list
                                                const newList = [...formList];
                                                if (newList[index]) {
                                                  newList[index].start = e;
                                                }
                                                setFormList(newList);
                                              } else {
                                                const newErrors = { ...errors };
                                                if (!newErrors[index]) {
                                                  newErrors[index] = {};
                                                }
                                                newErrors[index].start =
                                                  "Please select a current or future date.";
                                                setErrors(newErrors);
                                              }
                                            }}
                                            minDate={new Date()}
                                          />
                                        </Form.Group>
                                        {errors[index]?.start && (
                                          <span className="error-message">
                                            {errors[index].start}
                                          </span>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>

                                <Col md={12}>
                                  <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                      <Form.Label column sm={6}>
                                        End Date
                                      </Form.Label>
                                      <Col sm={6}>
                                        <Form.Group>
                                          <CustomDatePicker
                                            wrapperClassName="datepicker"
                                            className="input-mandatory"
                                            selected={formData.end}
                                            onChange={(e) => {
                                              const selectedDate = new Date(e);
                                              const currentDate = new Date();
                                              if (selectedDate >= currentDate) {
                                                const newErrors = { ...errors };
                                                delete newErrors[index]?.end;
                                                setErrors(newErrors);
                                                const newList = [...formList];
                                                newList[index].end = e;
                                                setFormList(newList);
                                              } else {
                                                const newErrors = { ...errors };
                                                newErrors[index].end =
                                                  "Please select a current or future date.";
                                                setErrors(newErrors);
                                              }
                                            }}
                                            minDate={new Date()}
                                          />
                                        </Form.Group>
                                        <span className="error-message"></span>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </Col>

                            <Col lg={6} md={6} className="my-auto">
                              {index > 0 && (
                                <Row className="mb-3">
                                  <Col md={3}>
                                    <Button
                                      variant="outline-secondary"
                                      type="button"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      Remove
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col md={3}>
                                  <Button
                                    variant="outline-danger"
                                    type="button"
                                    onClick={handleAddRow}
                                  >
                                    Add Row
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  ))}

                  <Row className="mt-5 pb-3">
                    <div className="d-flex justify-content-center">
                      <Link>
                        <CancelButton
                          name={"cancel"}
                          handleClose={props.handleClose}
                        />
                      </Link>
                      <SaveButton name={"save"} handleSubmit={handleSubmit} />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code !== 0}
      />
    </>
  );
};
export default AddOffCanvance;
