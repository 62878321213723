import React from "react";
import "../../style/Modals/Modals.css";
import "../../style/Common/Common.css";
import "../../style/Form/Form.css";

import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
function Projectsummury(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Project Summary
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="Project_Summary">
                    <Form.Control
                      as="textarea"
                      name="Project_Summary"
                      placeholder="Project Summary"
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <Form>
            <Button className="btn btn-secondary save-btn my-2" type="submit">
              <FontAwesomeIcon icon="fa-solid fa-times" /> Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Projectsummury;
