import React, { useState } from "react";
import Chart from "react-apexcharts";
import './HrmsDashboardChart.css'; // Import a CSS file for custom styles

const HrmsDashboardChart = () => {
    const [chartOptions, setChartOptions] = useState({
        series: [
            {
                name: "Present",
                data: [5, 10, 8, 32, 6, 9, 9, 7, 4, 10, 3, 5],
            },
            {
                name: "Absent",
                data: [5, 10, 8, 12, 6, 5, 9, 7, 4, 6, 3, 5],
            },
            {
                name: "Late Marks",
                data: [2, 5, 3, 6, 4, 5, 3, 2, 1, 3, 2, 4],
            },
        ],
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        // title: {
        //     text: "Employee Attendance Trends by Month",
        //     align: "left",
        // },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            labels: {
                show: false, // Hide default labels
            },
        },
        yaxis: {
            // title: {
            //     text: "Count",
            // },
            min: 0,
            max: 50,
            tickAmount: 5,
            labels: {
                formatter: (val) => Math.floor(val),
            },
        },
    });

    // Define colors for each month
    const labelColors = [
        "#FF5733", // January
        "#33FF57", // February
        "#3357FF", // March
    ];

    return (
        <div >
            <Chart
                options={chartOptions}
                series={chartOptions.series}
                type={chartOptions.chart.type}
                height={chartOptions.chart.height}                
                className={"chart"}
            />
            {/* <div className="custom-xaxis-labels">
                {chartOptions.series.map((item, index) => (
                    <div
                        className="label"
                        key={index}
                        style={{
                            left: `${index * (100 / 12)}%`, // Distributing labels across the width
                            backgroundColor: labelColors[index], // Assigning color to each label
                        }}
                    >
                        {item.name} 
                    </div>
                ))}
            </div> */}
        </div>
    );
};

export default HrmsDashboardChart;
