import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../CommonCss/CommonCss.css";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
  Dropdown,
} from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditModal from "./AssetsModal/EditModal";
import DeleteModal from "../../../common/DeleteModal";

import { Context } from "../../../../utils/context";
import pen from "../../../../Components/Admin/assets/icons/pen.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import search1 from "../../../../Components/Admin/assets/icons/search.png";
import { AddButton, EditButton, DeletButton } from "../../../common/Button";
import ModalDelete from "../../../common/ModalDelete";
import AddModal from "./AssetsModal/AddModal";

const DashboardAssets = () => {
  const [addmodalShow, setAddModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState({ show: false, id: "" });
  const [deleteModalShow, setDeleteModalShow] = useState({ show: false, heading: "", id: "" });

  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [users, setUsers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [deptartment_id, setDepartment] = useState("");
  const [employee_id, setEmployee] = useState("");
  const [asset_id, setAsset] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  //  column hide and show.....
  const [short, setShort] = useState({
    col1: false,
    col2: false,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setShort((prev) => ({
      col1: columnName === "col1" ? !prev.col1 : false,
      col2: columnName === "col2" ? !prev.col2 : false,
    }));
  };

  const getDataAll = async () => {
    const response = await getData(
      `/hrms/assign-asset?page=${currentPage || ""}&per_page=${perPage || ""
      }&term=${search || ""}&deptartment_id=${deptartment_id?.value || ""}&asset_id=${asset_id?.value || ""}&employee_id=${employee_id?.value || ""}` +
      `&short_name=${short.col1}&short_created=${short.col2}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, editmodalShow, addmodalShow, deptartment_id, asset_id, employee_id, short]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/hrms/assign-asset/${id}`);
    setChangeStatus(response);
  };
  const handleDeleteRecord = async (id) => {
    const response = await deleteData(
      `/hrms/assign-asset/${id}`
    );
    setDeleteModalShow({ show: false, heading: "", id: "" })
    setChangeStatus(response);
  };

  const handleEditData = (id) => {
    setEditModalShow({ show: true, id: id })

  };

  const getDepartments = async () => {
    const response = await getData("/masters/alldepartments");
    if (response?.success) {
      setDepartments(await Select2Data(response?.data, "leave_type_id"));
    }
  };

  const getEmployees = async (id) => {
    const response = await getData(`/masters/allemployeebydept/${id}`);
    if (response?.success) {
      setEmployee("")
      setUsers(await Select2Data(response?.data, "employee_id"));
    }
  };

  const getShifts = async () => {
    const response = await getData("/hrms-masters/all-assets");
    if (response?.success) {
      setAssets(await Select2Data(response?.data, "asset_id"));
    }
  };

  useEffect(() => {
    getDepartments();
    getShifts();
  }, []);

  return (
    <>
      <section className="main-common-class-hrms">
        <div className="white-bggg mt-3">
          <Row>
            <Row>
              <Col xxl={8} xl={6} lg={6} md={12} sm={12} xs={12}>
                <p className="main-table-head">Assets</p>
              </Col>

              <Col xxl={4} xl={6} lg={6} md={12} sm={12} xs={12}>
                <div className="add d-md-flex justify-content-lg-end">
                  <Button
                    className="custom-apply-leaves-btn me-2"
                    onClick={() => setAddModalShow(true)}
                  >
                    Assign Assets
                    <span className="icon-container">
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Button>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="custom-apply-leaves-btn"
                      id="dropdown-basic"
                    >
                      Sort By
                      <span className="icon-container">
                        <FontAwesomeIcon icon={faArrowUpWideShort} />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                      <Dropdown.Item>
                        {" "}
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col1")}
                            href="#"
                          >
                            Employee
                            {short?.col1 ? (
                              <>
                                <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" /> Zz-Aa
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />  Aa-Zz
                              </>
                            )}
                          </Link>
                        </li>
                      </Dropdown.Item>
                      {/* <Dropdown.Item>
                        {" "}
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col2")}
                            href="#"
                          >
                            Date
                            {short?.col2 ? (
                              <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />
                            ) : (
                              // <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />
                              <></>
                            )}
                          </Link>
                        </li>
                      </Dropdown.Item> */}

                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            <div className="grey-bggg">
              <Row>
                <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={12}>
                  <div class="show-entries mt-2">
                    <label for="entries">Show</label>
                    <select
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                      onChange={(e) => setperPage(e.target.value)}
                    >
                      {option?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <label for="entries" className="ms-2">
                      Entries
                    </label>
                  </div>
                </Col>
                <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={12}>
                  <div className="text-lg-end sowing me-2">
                    <p className="showing-txttt mt-2">{`Showing ${Math.min(
                      (currentPage - 1) * perPage + 1
                    )
                      } to ${Math.min(
                        currentPage * perPage,
                        data?.data?.totalEntries
                      )
                      } of ${data?.data?.totalEntries} Entries`}</p>
                  </div>
                </Col>
                <Col xxl={2} xl={3} lg={6} md={6} sm={6} xs={12}>
                  <div className="d-flex">
                    <InputGroup className="mb-3">
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,

                          }),
                        }}
                        value={deptartment_id}
                        placeholder="Department"
                        options={departments}
                        onChange={(item) => {
                          setDepartment(item);
                          getEmployees(item?.value);
                        }}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col xxl={2} xl={3} lg={6} md={6} sm={6} xs={12}>
                  <div className="d-flex">
                    <InputGroup className="mb-3">
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,

                          }),
                        }}
                        value={employee_id}
                        placeholder="Employee"
                        options={users}
                        onChange={(item) => {
                          setEmployee(item);
                        }}
                      />
                    </InputGroup>
                  </div>
                </Col>
                <Col xxl={2} xl={3} lg={6} md={6} sm={6} xs={12}>
                  <div className="d-flex">
                    <InputGroup className="mb-3">
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,

                          }),
                        }}
                        value={asset_id}
                        placeholder="Asset"
                        options={assets}
                        onChange={(item) => {
                          setAsset(item);
                        }}
                      />
                    </InputGroup>
                  </div>
                </Col>
                {/* <Col xxl={2} xl={2} lg={3} md={3} sm={3} xs={6}>
                  <div className="d-flex">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Search Here"
                        aria-label="Search Here"
                        aria-describedby="basic-addon1"
                        id=""
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </div>
                </Col> */}
                <Col xxl={1} xl={4} lg={6} md={6} sm={6} xs={12}>
                  {/* <button
                    type="button"
                    onClick={getDataAll}
                    className="btn btn-search mb-3 me-2"
                  >
                    Search
                  </button> */}
                  <button
                    type="button"
                    onClick={() => {
                      setSearch("");
                      setDepartment("");
                      setEmployee("");
                      setAsset("");
                      setReset(!reset);
                    }}
                    className="btn reset-btnn mb-3"
                  >
                    Reset
                  </button>
                </Col>
              </Row>
            </div>
            <div className="common-table-hrms">
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Employee Id</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Assigned Assets</th>
                    <th>Assigned By</th>
                    <th>Assigned On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.data?.map((d, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{d?.e_employment_detail?.employee_id}</td>
                        <td>{d?.name}</td>
                        <td>{d?.department?.name}</td>
                        <td>
                          {d?.e_assigned_assets?.map((item, index) => (
                            <p>
                              {index + 1}. {item?.asset?.name}
                            </p>
                          ))}
                        </td>
                        <td>
                          {d?.e_assigned_assets?.map((item) => (
                            <p>{item?.assets_assigned_by?.name} ,</p>
                          ))}
                        </td>
                        <td>
                          {d?.e_assigned_assets?.map((item, index) => (
                            <p>
                              {index + 1}. {new Date(item?.createdAt).toDateString()}
                            </p>
                          ))}
                        </td>
                        <td>
                          <div className="d-flex">
                            {isAllow.includes(75) && (
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="table-iconnnn"
                                onClick={() => handleEditData(d?.id)}
                                type="button"
                              />
                            )}

                            {isAllow.includes(76) && (
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="table-iconnnn ms-2"
                                onClick={() => setDeleteModalShow({ show: true, heading: "Assets", id: d?.id })}
                              />
                            )}
                          </div>
                        </td>

                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Row>

        </div>
      </section>
      <AddModal show={addmodalShow} onHide={() => setAddModalShow(false)} />
      <EditModal
        id={editmodalShow?.id}
        show={editmodalShow?.show}
        onHide={() => {
          setEditModalShow({ show: false, id: "" });
          getDataAll();
        }}
      />
      <DeleteModal
        heading={deleteModalShow?.heading}
        show={deleteModalShow?.show}
        onHide={() => setDeleteModalShow({ show: false, heading: "", id: "" })}
        handleDelete={() => handleDeleteRecord(deleteModalShow?.id)}
      />
    </>
  );
};

export default DashboardAssets;
