import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const data = [
  { value: 5, label: 'Email' },
  { value: 10, label: 'Phone' },
  { value: 15, label: 'Web' },
  { value: 20, label: 'Forums' },
];

const size = {
  width: 400,
  height: 200,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 15,
}));

const PieCenterLabel = ({ children }) => {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
};

const Piechart = () => {
  return (
    <>
      <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
        <PieCenterLabel>Ticket <br />2265</PieCenterLabel>
      </PieChart>
    </>
  );
};

export default Piechart;
