import React, { useState } from "react";
import "./TaskBoardCard.css";
import "../../Veiw_Details.css";
import "../../../projectsec.css";
import "../../../style/Common/Common.css";
import {  Offcanvas,   } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import OffcanvasView from "../../../OffcanvasView/OffcanvasView";

library.add(fas);

function TaskBoardCard() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="task-board-sec">
        <div className="card task_board_card" onClick={handleShow}>
          <div className="card-body ">
            <div className="task_board_heading">
              <div className="card-heding-text">
                <p className="lg-text mb-0">Demo</p>
                <p className="text-label xs-text mb-0">Private</p>
              </div>

              <div className="sm-text">
                <FontAwesomeIcon
                  className="sm-text"
                  icon="fa-solid fa-layer-group"
                />{" "}
                Royal Studio
              </div>
            </div>

            <div className="task_board_content "></div>

            <div className="task_board_img_holder">
              <div className="member-img">
                <img
                  src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                  data-toggle="tooltip"
                  data-original-title="Paresh Markad "
                  data-placement="right"
                  alt="user"
                  className="img-circle "
                  width="25"
                  height="25"
                />

                <img
                  src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                  data-toggle="tooltip"
                  data-original-title="Paresh Markad "
                  data-placement="right"
                  alt="user"
                  className="img-circle "
                  width="25"
                  height="25"
                />
              </div>
            </div>

            <div className="task_board_card_footer">
              <p className="sm-text mb-0">
                <FontAwesomeIcon
                  className="sm-text"
                  icon="fa-solid fa-calender"
                />{" "}
                21-02-2024
              </p>

              <p
                className=" mb-0"
                data-toggle="tooltip"
                data-original-title="Comment"
                data-placement="left"
              >
                <FontAwesomeIcon
                  className="sm-text"
                  icon="fa-solid fa-comment"
                />{" "}
                0
              </p>
            </div>
          </div>
        </div>

        <div className="offcanvas-sec">
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            style={{ width: "80%" }}
          >
            <Offcanvas.Header className="offcanvas-heading" closeButton>
              <Offcanvas.Title> Task </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <OffcanvasView/>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </>
  );
}

export default TaskBoardCard;
