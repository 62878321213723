import * as React from 'react';
import './PieChartSecond.css'
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
const data = [
    { value: 5, label: '90% Good(16)' },
    { value: 10, label: '7% Okay(2)' },
    { value: 15, label: '3% Bed(2)' },

];

const size = {
    width: 400,
    height: 200,
};

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 15,
}));

const PieCenterLabel = ({ children }) => {
    const { width, height, left, top } = useDrawingArea();
    return (
        <StyledText x={left + width / 2} y={top + height / 2}>
            {children}
        </StyledText>
    );
};

const PieChartSecond = () => {
    return (
        <>
            <PieChart series={[{ data, innerRadius: 80 }]} {...size}>
                <PieCenterLabel>Total Rating <br />205</PieCenterLabel>
            </PieChart>
        </>
    )
}

export default PieChartSecond