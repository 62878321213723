import React from "react";
import "./superadmin-main.css";
import { Route, Routes } from "react-router-dom";
import Super_admin_routes from "../Super-admin-routes/Super_admin_routes";
import Sidebar from "../sidebar/Sidebar";
import Top_header from "../top-header/Top_header";

const Super_admin_main_routes = () => {
  return (
    <>
      <section className="supermain-structure">
        <div className="container-fluid p-0">
          <div className="d-flex">
            <div className="sidebarmaindiv">
              <Sidebar />
            </div>
            <div className="contentmaindiv ps-0 pe-0">
              <Top_header />
              <Super_admin_routes />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Super_admin_main_routes;
