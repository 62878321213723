import React, { useState,useContext } from "react";
import "../../style/Modals/Modals.css";
import "../../style/Common/Common.css";
import "../../style/Form/Form.css";
import "../../style/Tables/Tables.css";
import { Context } from "../../../../../../utils/context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function AddClientInfoModal(props) {

  const { postData } = useContext(Context);

  const [formData, setFormData] = useState({
    client_name: "",
    client_email: "",
    phone: "",
  });
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


const handleSubmit = async (event) => {
  // Prevent the default form submission behavior
  event.preventDefault();
  
  try {
      // Call your API to submit the form data
      const response = await postData("/projectmanagement/project-client", formData);
      
      // Handle success
      console.log("Form data submitted successfully:", response);
      // Close the modal if the response is successful
      if (response.success) {
          props.onHide(); // Close the modal
      }
      // Optionally, you can reset the form after successful submission
      // setFormData(initialFormData); // Assuming initialFormData is the initial state of your form
      
  } catch (error) {
      // Handle error
      console.error("Error submitting form data:", error);
  }
};
  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <>
        <Modal
          {...props}
          size="lg"
          className="table_modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Client Info
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="field_top_space">
              <div className="row">
                <div className="col-md-4">
                  <Form>
                    <Form.Group controlId="Client_Name">
                      <Form.Label className="required">Client Name</Form.Label>

                      <Form.Control
                        type="text"
                        name="client_name"
                        placeholder="Enter Client Name"
                        value={formData.client_name}
                         onChange={handleChange}
                      />
                      <Form.Control type="hidden" name="Client_Name" />
                    </Form.Group>
                  </Form>
                </div>

                <div className="col-md-4">
                  <Form>
                    <Form.Group controlId="Client_Email">
                      <Form.Label className="required">Client Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="client_email"
                        placeholder="Enter Client Email"
                        value={formData.client_email}
                        onChange={handleChange}
                      />
                      <span className="help-block">
                        {" "}
                        Client will login using this email.
                      </span>
                    </Form.Group>
                  </Form>
                </div>

                <div className="col-md-4">
                  <Form>
                    <Form.Group controlId="Password">
                      <Form.Label className="required">Password</Form.Label>
                      <div className="password_field">
                        <Form.Control
                          type="text"
                          name="phone"
                          placeholder="Enter Password"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                        <div className="password-icon">
                          <FontAwesomeIcon
                            className="text-dark gear-icon"
                            icon="fa-solid fa-eye"
                          />
                        </div>
                      </div>
                      <Form.Control type="hidden" name="Password" />
                      <span className="help-block">
                        {" "}
                        Employee will login using this password.{" "}
                      </span>
                      <div>
                        <Form.Group>
                          <div className="checkbox checkbox-info text-info">
                            <Form.Check
                              type="checkbox"
                              id="Generate_Random_Password"
                              name="Generate_Random_Password"
                              value="true"
                              label="Generate Random Password"
                            />
                          </div>
                        </Form.Group>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>


            <Form onSubmit={handleSubmit}>
              <Button className="btn btn-success save-btn my-2" type="submit">
                <FontAwesomeIcon icon="fa-solid fa-check" /> Save
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}

export default AddClientInfoModal;
