import React from 'react'
import Modal from 'react-bootstrap/Modal';

const SuccessTransferModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='main-success-modal'
            >
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className='success-button'>
                        <p>Complaint Transfer Successfully</p>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default SuccessTransferModal