import React from "react";
import "../../style/Tables/Tables.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button, Table } from "react-bootstrap";
import DeleteModal from "../../Modals/DeleteModal/DeleteModal";
library.add(fas);

function Payments() {
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  return (
    <>
      <div className="Payments">
        <h6 className="page-title">Payments</h6>

        <div className="Payments mt-5">
          <div className="row">
            <div className="col-md-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Hourly Rate</th>
                    <th>User Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="table-body-holder">
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-sm-3 col-xs-4">
                          <img
                            src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                            alt="user"
                            className="img-circle"
                            width="40"
                            height="40"
                          />
                        </div>
                        <div className="col-sm-9 col-xs-8">
                          Aditya Madkar
                          <br />
                          <span className="text-muted font-12">
                            Software Tester
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>0.00</td>
                    <td>
                      <Form.Check
                        type="radio"
                        name="project_admin"
                        id="project_admin_373"
                        label="Project Admin"
                      />
                      <a
                        href="javascript:;"
                        className="text-danger remove-admin"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                        Remove
                      </a>
                    </td>
                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        className="btn-outline edit-members me-2"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen edit" />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        className="btn-outline delete-members "
                        onClick={() => setDeleteModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash delete" />
                      </Button>
                      <DeleteModal
                        show={deleteModalShow}
                        onHide={() => setDeleteModalShow(false)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payments;
