import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import "./PopularDashboard.css";
import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-bootstrap/Pagination';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BarChart from "./Barchart/BarChartnew";
import Barchart from "../../../Admin/DashBoard/Barchart/Barchart"
import BarChartnew from "./Barchart/BarChartnew";
import Piechart from "./Piechart/Piechart";
import ProgressChart from "./progreeschart/ProgressChart";
import PieChartSecond from "./PieChart-second/PieChartSecond";
library.add(fas);
function PopularDashboard() {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className="main-advancedashboard">
      <section className="PopularDashboard">
        <div className="AdvanceDashboardTabs">
          <div className="row justify-content-end me-0 ms-0">
            <div className="col-xxl-12 col-12 p-0">
              <div className="badhboard1 sticky-top bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <ul className="nav ">
                      <li className="nav-item" role="presentation">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            Overview Dashboard
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>

                      <li className="nav-item mx-md-2 mx-sm-1 mx-0 mt-1" role="presentation">
                        <FontAwesomeIcon icon="fa-solid fa-filter" className="filter-icon" />
                      </li>

                      <li className="nav-item  mx-md-2 mx-sm-1 mx-0 mt-1" role="presentation">
                        <FontAwesomeIcon icon="fa-solid fa-rotate" className="filter-icon" />
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="nav justify-content-md-end justify-content-start">
                      <li className="nav-item" role="presentation">
                        <button className="btn btn-icon"><FontAwesomeIcon icon="fa-solid fa-ellipsis" /></button>
                      </li>

                      <li className="nav-item mx-2 " role="presentation">
                        <button className="btn btn-icon"><FontAwesomeIcon icon="fa-solid fa-down-left-and-up-right-to-center" /></button>
                      </li>

                      <li className="nav-item  mx-2 " role="presentation">
                        <button className="btn btn-icon"><FontAwesomeIcon icon="fa-solid fa-exclamation" /></button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="badhboard1 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <ul className="nav">
                      <li className="nav-item" role="presentation">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            Last Seven Days
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="nav justify-content-md-end justify-content-start">
                      <li className="nav-item" role="presentation">
                        <Pagination>
                          <Pagination.Prev />
                          <Pagination.Item>{1}</Pagination.Item>
                          <Pagination.Item>{2000}</Pagination.Item>
                          <Pagination.Next />
                        </Pagination>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="AdvanceDashboardTabsContent">
                <div className="row ">
                  <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
                    <div className="box bg-white">
                      <div className="text-holder">
                        <h5 className="text">Open Request</h5>
                        <p className="number">
                          1173
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
                    <div className="box bg-white">
                      <div className="text-holder">
                        <h5 className="text">On Hold Request</h5>
                        <p className="number">
                          2824
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
                    <div className="box bg-white">
                      <div className="text-holder">
                        <h5 className="text">Overdue Request</h5>
                        <p className="number">
                          814
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
                    <div className="box bg-white">
                      <div className="text-holder">
                        <h5 className="text">Due Today</h5>
                        <p className="number">
                          10
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
                    <div className="box bg-white">
                      <div className="text-holder">
                        <h5 className="text">Unassigned Due In hr</h5>
                        <p className="number">
                          76 Hrs
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-6 col-md-6 mb-3">
                    <div className="box bg-white">
                      <div className="text-holder">
                        <h5 className="text"> Unassigned Request </h5>
                        <p className="number">
                          11
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="card">
                  <div className="card-header ">
                    <div className="heading-holder">
                      <p>Ticket Status</p>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="new-ticket ">
                          <h5>2235</h5>
                          <p>New Ticket (Total)</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="new-ticket">
                          <h5>470</h5>
                          <p>On Hold Ticket (Total)</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="new-ticket">
                          <h5>2235</h5>
                          <p>Closed  Ticket (Total)</p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6">
                        <div className="new-ticket">
                          <h5>2235</h5>
                          <p>Backlog Ticket (Total)</p>
                        </div>
                      </div>
                      <div className="col-md-12 mt-5">
                        <BarChartnew />
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row ">
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="card mt-xl-5 mt-lg-3 mt-md-3 mt-3">
                      <div className="card-header">
                        <div className="d-flex">
                          <h6 className="mt-2">Traffic Analysis</h6>
                          <div className="ms-auto">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic" className="traffic-button">
                                Donut
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <Piechart />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="card mt-xl-5 mt-lg-3 mt-md-3 mt-3">
                      <div className="card-header">
                        <div className="d-flex">
                          <h6 className="mt-2">Average headling Time</h6>
                          <div className="ms-auto">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic" className="traffic-button">
                                Horizontal bar
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="tag-line mb-4">
                          <p>First Reaponsive Time</p>
                          <ProgressChart />
                        </div>
                        <div className="tag-line  mb-4">
                          <p>First Reaponsive Time</p>
                          <ProgressChart />
                        </div>
                        <div className="tag-line mb-md-5 ">
                          <p>First Reaponsive Time</p>
                          <ProgressChart />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6">
                    <div className="card mt-xl-5 mt-lg-3 mt-md-3 mt-3">
                      <div className="card-header">
                        <div className="d-flex">
                          <h6 className="mt-2">Happiness Rate</h6>
                          <div className="ms-auto">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic" className="traffic-button">
                                Donut
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">1</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">2</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">3</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <PieChartSecond />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PopularDashboard;
