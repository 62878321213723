import React from 'react'
import ComplaintReportTable from './complaint-report-table/ComplaintReportTable'

const ComplaintReport = () => {
    return (
        <>
            <div data-aos="fade-up"
                data-aos-duration="500">
                <ComplaintReportTable />
            </div>
        </>
    )
}

export default ComplaintReport