import React, { useState } from "react";
import "../../CommonCss/CommonCss.css";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Leave = () => {
  const [addmodalShow, setAddModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [deletemodalShow, setDeleteModalShow] = useState(false);
  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <Row>
          <Col xxl={8} xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className="main-table-head">Leave </p>
          </Col>
          <Col xxl={4} xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className="add d-md-flex justify-content-lg-end">
              <Dropdown>
                <Dropdown.Toggle
                  className="custom-apply-leaves-btn"
                  id="dropdown-basic"
                >
                  Column Selection
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-0">Sr.No</Dropdown.Item>
                  <Dropdown.Item href="#/action-1">Employee Id</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Name</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Leave Type</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">Resaon</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">Duration</Dropdown.Item>
                  <Dropdown.Item href="#/action-5">Status</Dropdown.Item>
                  <Dropdown.Item href="#/action-6">Action</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          {/* <AddModal
                        show={addmodalShow}
                        onHide={() => setAddModalShow(false)}
                    /> */}
        </Row>

        <div className="card-section">
          <div className="row">
            {/* Total Present Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card1.png"
                    }
                    alt="Card 1"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Total Applied
                    <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>100</p>
                </div>
              </div>
            </div>

            {/* Total Absent Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card2.png"
                    }
                    alt="Card 2"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Approved <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>70</p>
                </div>
              </div>
            </div>

            {/* Late Marks Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card3.png"
                    }
                    alt="Card 3"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Rejected <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>25</p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/leave-cards/card4.png"
                    }
                    alt="Card 3"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Pending <br />
                    Leaves
                  </p>
                </div>
                <div className="count-container">
                  <p>25</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grey-bggg">
          <Row>
            <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={12}>
              <div class="show-entries mt-2">
                <label for="entries">Show</label>
                <select id="entries" name="entries">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <label for="entries" className="ms-2">
                  Entries
                </label>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="text-lg-end">
                <p className="text-end showing-txttt mt-2">
                  Showing 1 to 5 Of 5 Entries
                </p>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12}>
              <div className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search Here"
                    aria-label="Search Here"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </div>
            </Col>
            <Col xxl={3} xl={6} lg={6} md={12} sm={6} xs={12}>
              <div className="">
                <button className="btn reset-btnn">Reset</button>
              </div>
            </Col>

            <Col xxl={3} xl={6} lg={6} md={12} sm={6} xs={12}>
              <div className="">
                <button className="btn reset-btnn">Reset</button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="common-table-hrms">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Employee Id</th>
                <th>Name</th>
                <th>Leave Type</th>
                <th>Reason</th>
                <th>Duration</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              <tr>
                <td>01.</td>
                <td>0012</td>
                <td>Komal Nathe</td>
                <td>Half day</td>
                <td>Emergancy</td>
                <td>12 Oct 2024- 15 oct 2024</td>
                <td>
                  <div>
                    <button
                      className="approve-btn me-2"
                      onClick={() => setAddModalShow(true)}
                    >
                      Approved
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>01.</td>
                <td>0012</td>
                <td>Komal Nathe</td>
                <td>Half day</td>
                <td>Emergancy</td>
                <td>12 Oct 2024- 15 oct 2024</td>
                <td>
                  <div>
                    <button
                      className="pending-btn me-2"
                      onClick={() => setAddModalShow(true)}
                    >
                      Pending
                    </button>
                  </div>
                </td>
              </tr>
              <tr>
                <td>01.</td>
                <td>0012</td>
                <td>Komal Nathe</td>
                <td>Half day</td>
                <td>Emergancy</td>
                <td>12 Oct 2024- 15 oct 2024</td>
                <td>
                  <div>
                    <button
                      className="rejected-btn me-2"
                      onClick={() => setAddModalShow(true)}
                    >
                      Rejected
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      {/* <EditModal
                show={editmodalShow}
                onHide={() => setEditModalShow(false)}
            />
            <DeleteModal
                show={deletemodalShow}
                onHide={() => setDeleteModalShow(false)}
            /> */}
    </section>
  );
};

export default Leave;
