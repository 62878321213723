import React from 'react'
import Lottie from "react-lottie";
import * as animationData from './pdf.json';
import './DownloadPaymentSlip.css'

const DownloadPaymentSlip = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <section className='main-lottey'>
            <div className='Download-Payment-Slip'>
                <Lottie
                    options={defaultOptions}
                />
            </div>
        </section>
    )
}

export default DownloadPaymentSlip
