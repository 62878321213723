import { useContext, useEffect, useState } from "react";
import "./ComplaintHeader.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logoimg from "../image/logo.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import profileimg from "../image/profile.png";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
library.add(fas);

function ComplaintHeader() {
  const { setUserData, setUsertype, setSignin, usertype, getData, postData } =
    useContext(Context);
  const [submit, setSubmit] = useState(false);
  const handleSubmitClose = () => setSubmit(false);
  const handleSubmitShow = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 5000);
  };

  const [error, setError] = useState(false);
  const handleErrorClose = () => setError(false);
  const handleErrorShow = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };
  const navigate = useNavigate();

  // const Reload = async () => {
  //   Cookies.remove("lead_id");
  //   setFormStatus({ ...formStatus, lead_id: "" });
  // };

  const LogOut = async () => {
    Cookies.remove("it_pulse_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  const [formStatus, setFormStatus] = useState({
    lead_id: "",
    one: true,
    two: true,
    three: true,
    four: true,
  });
  return (
    <>
      <section className="header">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Navbar.Brand>
            <div className="header-text">
              <img src={logoimg} className="logo-img" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="col-md-6">
              <Nav className="">
                <div className="header-icons">
                  <div className="heading-name">
                    <h1>Ticket</h1>
                  </div>
                  <div className="heading-name">
                    <h1>Employees</h1>
                  </div>
                  <div className="heading-name">
                    <h1>Activities</h1>
                  </div>
                  <div className="heading-name">
                    <h1>Social</h1>
                  </div>
                  <div className="heading-name">
                    <h1>Analytics</h1>
                  </div>
                  <div className="heading-name">
                    <h1>KB</h1>
                  </div>
                  <div className="heading-name">
                    <h1>Chat</h1>
                  </div>
                </div>
              </Nav>
            </div>
            <div className="col-md-6">
              <Nav className="justify-content-end">
                <div className="header-icons ">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">Zpad</Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <div className="icon-holder mt-1">
                    <FontAwesomeIcon
                      icon="fa-solid fa-plus"
                      className="drop-icon1 mx-1"
                    />
                    <FontAwesomeIcon
                      icon="fa-solid fa-caret-down"
                      className="drop-icon mx-1"
                    />
                  </div>

                  <div className="icon mt-1 ">
                    <FontAwesomeIcon
                      icon="fa-solid fa-magnifying-glass"
                      className="search-icon"
                    />
                  </div>
                  <div className="icon mt-1 ">
                    <FontAwesomeIcon
                      icon="fa-solid fa-gear"
                      className=" search-icon"
                    />
                  </div>
                  <div className="icon mt-1">
                    <FontAwesomeIcon
                      icon="fa-solid fa-note-sticky"
                      className="search-icon"
                    />
                  </div>
                  <div className="icon mt-1 ">
                    <FontAwesomeIcon
                      icon="fa-solid fa-gamepad"
                      className="search-icon"
                    />
                  </div>
                  <div className="icon mt-1 ">
                    <img src={profileimg} className="profile-img" />
                  </div>
                  <div className="icon mt-1 ">
                    <FontAwesomeIcon
                      icon="fa-solid fa-grip"
                      className="search-icon"
                    />
                  </div>
                  <FontAwesomeIcon
                    onClick={LogOut}
                    className="users"
                    icon="fa-solid fa-power-off"
                  />
                </div>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </section>
    </>
  );
}

export default ComplaintHeader;
