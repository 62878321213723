import React from "react";
import "./Overviews.css"
import "../Veiw_Details.css";
import "../../projectsec.css";
import "../../style/Modals/Modals.css";
import "../../style/Form/Form.css";
import "../../style/Common/Common.css";
import "../../style/Tables/Tables.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import ProjectModal from "../../Modals/Projectsummury/Projectsummury";
import Projectsummury from "../../Modals/Projectsummury/Projectsummury";
library.add(fas);

function Overviews() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      {/* overview tab content start (smita) */}
      <div className="overview_tab_content">
        <div className="row">
          <div className="col-md-9">
            <div className="no_of">
              <div className="row project-top-stats">
                <div className="col-md-3 ">
                  <div className="right-border">
                    <span className="text-primary me-1">£15000.00</span>
                    <span className="name">Project Budget</span>
                  </div>
                </div>

                <div className="col-md-3 ">
                  <div className="right-border">
                    <span className="text-success me-1">£0</span>
                    <span className="name">Earnings</span>
                  </div>
                </div>

                <div className="col-md-3 ">
                  <div className="right-border">
                    <span className="text-info me-1">0</span>
                    <span className="name"> Hours Logged</span>
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="right-border">
                    <span className="text-warning me-1">£0</span>
                    <span className="name">Expenses</span>
                  </div>
                </div>
              </div>
            </div>

            {/* expand card start (smita) */}
            <div className="expand bottom-space">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="box-title">Project Details</h6>
                      <p className="sm-text">Details</p>
                    </div>

                    <div className="expand-tbn card-footer-bg ">
                      <span onClick={() => setModalShow(true)}>
                        <FontAwesomeIcon
                          className="me-2"
                          icon="fa-solid fa-up-down"
                          onClick={() => setModalShow(true)}
                        />
                        Expand
                      </span>
                    </div>
                    <Projectsummury
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* expand card end (smita) */}

            <div className="date_milestone bottom-space">
              <div className="row">
                <div className="col-md-6">
                  <div className="date">
                    <div className="card ">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <h6 className="lg-text">Start Date</h6>
                            <p className="sm-text ">06-02-2024</p>
                          </div>
                          <div className="col-md-6">
                            <h6 className="lg-text">End Date</h6>
                            <p className="sm-text ">07-02-2024</p>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-12">
                            <h6 className="xl-text">Client Details</h6>
                          </div>
                          <div className="col-md-6 ">
                            <h6 className="lg-text">Client Name</h6>
                            <p className="sm-text">Prashant Nandu</p>
                          </div>
                          <div className="col-md-6 ">
                            <h6 className="lg-text">Client Email</h6>
                            <p className="sm-text">prashant@organomart.in</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="milestone">
                    <div className="card">
                      <div className="card-header">
                        <h6 className="xl-text mb-0">MILESTONE</h6>
                      </div>

                      <div className="card-body lg-text card-footer-bg">
                        No Record Found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* time line section start */}
          <div className="col-md-3">
            <div className="activity-timeline">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-img-holder ">
                    <div className="card-body">
                      <img
                        src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                        data-toggle="tooltip"
                        data-original-title="Aditya Madkar"
                        alt="user"
                        className="img-circle me-2"
                        width="25"
                        height="25"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xs-12" id="project-timeline">
                  <div className="card mb-4">
                    <div className="card-header">
                      <h6 className="box-title ">Activity Timeline</h6>
                    </div>
                    <div className="card-body  time_line">
                      <div className="card-text">
                        <div className="steamline">
                          <div className="sl-item">
                            <div className="sl-left">
                              <FontAwesomeIcon
                                className="text-primary"
                                icon="fa-solid fa-circle"
                              />
                            </div>
                            <div className="sl-right">
                              <div>
                                <h6 className="sm-text">
                                  Demo added as new project.
                                </h6>
                                <p className="xs-text sl-date">1 day ago</p>
                              </div>
                            </div>
                          </div>
                          <div className="sl-item">
                            <div className="sl-left">
                              <FontAwesomeIcon
                                className="text-primary"
                                icon="fa-solid fa-circle"
                              />
                            </div>
                            <div className="sl-right">
                              <div>
                                <h6 className="sm-text">
                                  Aditya Madkar is added as project member.
                                </h6>
                                <p className="xs-text sl-date">1 day ago</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* time line section start */}
        </div>

        {/* task_earning section start */}
        <div className="task_earning">
          <div className="row">
            <div className="col-md-12">
              <div className="task_earning_time bottom-space">
                <div className="row">
                  <div className="col-md-4">
                    <div className="task">
                      <div className="card">
                        <div className="card-header">
                          <h6 className="xl-text mb-0">Task</h6>
                        </div>

                        <div className="card-body lg-text card-footer-bg">
                          No Record Found
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="task">
                      <div className="card">
                        <div className="card-header">
                          <h6 className="xl-text mb-0">Earning</h6>
                        </div>

                        <div className="card-body lg-text card-footer-bg">
                          No Record Found
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="task">
                      <div className="card">
                        <div className="card-header">
                          <h6 className="xl-text mb-0">Time Log</h6>
                        </div>

                        <div className="card-body lg-text card-footer-bg">
                          No Record Found
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* task_earning section end */}

        {/* task_earning section start */}
        <div className="task_earning">
          <div className="row">
            <div className="col-md-12">
              <div className="task_earning_time bottom-space">
                <div className="row">
                  <div className="col-md-12">
                    <div className="task">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="xl-text ">Project Note</h6>
                          <p className="sm-text">Notes</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* task_earning section end */}
      </div>
      {/* overview tab content end (smita) */}
    </>
  );
}

export default Overviews;
