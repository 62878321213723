import React from "react";
import { useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import { Context } from "../../utils/context";
import { useContext } from "react";
import { useEffect } from "react";
import { Row, Col, Modal, Table } from "react-bootstrap";

const Summary = (props) => {
  const { getData } = useContext(Context);
  const [data, setData] = useState({});
  const [submit, setSubmit] = useState(false);
  const handleSubmitClose = () => setSubmit(false);
  const handleSubmitShow = (id = 1) => {
    props?.getAllLeads(id);
    setSubmit(true);
  };
  const getDataAll = async () => {
    const response = await getData("/masters/allleadstatusandcount");
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const HandleClicks = async (id) => {
    props?.SaveCookie(id);
    handleSubmitClose();
  };
  return (
    <Accordion>
      <Accordion.Item eventKey="0" style={{ backgroundColor: `white` }}>
        <Accordion.Header>
          <div className="box-title-div">
            <div className="">
              <div className=" pe-0">
                <div className="sec-1">
                  <p className="call-statusp">Summary</p>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Header>

        <Accordion.Body>
          <div className="box-content-div pb-0">
            <div className="tabs-main-sec tabs-sec-hight">
              {data?.data?.map((data, index) => (
                <div
                  className="row mb-2"
                  key={index}
                  onClick={() => {
                    handleSubmitShow(data?.id);
                  }}
                >
                  <div className="col-md-4">
                    <p className="unit-pr-ppp">{data?.name} </p>
                  </div>
                  <div className="col-md-4">
                    <div className="hor-line"></div>
                  </div>
                  <div className="col-md-4 text-end">
                    <p className="unit-pr-p"> {data?.leadCount}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="row total-final-div ">
              <div className="col-3">
                <p className="total-ppp">Total</p>
              </div>
              <div className="col-4">
                <div className="total-fff-line"></div>
              </div>
              <div className="col-5 text-end">
                <p className="tottal-ppp-price">{data?.total_count}</p>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <section>
        <Modal
          show={submit}
          onHide={handleSubmitClose}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="addproduct-pop-section"
          style={{ overflow: "scroll" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-2">
            <div className="table-wrapper">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="table-body-holder">
                  {props?.alllead?.map((data, index) => (
                    <React.Fragment key={index}>
                      <tr
                        onClick={() => {
                          HandleClicks(data?.id);
                        }}
                      >
                        <td rowspan={2}>{++index}</td>
                        <td>{data?.f_name + " " + data?.l_name}</td>
                        <td>{data?.mobile_no}</td>
                        <td>{data?.email}</td>
                        <td>Action</td>
                      </tr>
                      <tr
                        onClick={() => {
                          HandleClicks(data?.id);
                        }}
                      >
                        <td colspan={2}>
                          <b> Date:- </b>
                          <p className="m-0">
                            Creation Date:{" "}
                            {data?.createdAt?.replace("T", " ").slice(0, -5)}
                          </p>
                          <p className="m-0">
                            {" "}
                            Update Date :{" "}
                            {data?.updatedAt?.replace("T", " ").slice(0, -5)}
                          </p>
                          <p className="m-0">
                            {" "}
                            Next Date :{" "}
                            {data?.next_date?.replace("T", " ").slice(0, -5)}
                          </p>
                        </td>
                        <td colspan={2}>
                          <p className="m-0">
                            <b>Remark : </b>
                            {data?.remark}
                          </p>
                          <p className="m-0">
                            <b>Course :</b>
                          </p>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          {/* <Modal.Body> */}
          {/* <div className="box">
            <div className="success alert">
              <div className="alert-body">Success !</div>
            </div>
          </div> */}
          {/* </Modal.Body> */}
        </Modal>
      </section>
    </Accordion>
  );
};

export default Summary;
