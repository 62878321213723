import React, { useContext, useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { Context } from "../../../../utils/context";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import EventModal from "./eventsModal/eventModal"; // Import your modal component
import DeleteModal from "../../../common/DeleteModal";

const localizer = momentLocalizer(moment);

const CalendarHrms = () => {
  const [events, setEvents] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState({ show: false, heading: "", id: "" });

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color; // Use the color from the event data
    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "5px",
        border: "none",
        display: "block",
      },
    };
  };

  const { getData, postData, deleteData } = useContext(Context);

  const [counts, setCounts] = useState([]);

  const GetEditData = async () => {
    const response = await getData(`/hrms-masters/calender`);
    if (response?.data) {
      const formattedEvents = response.data.events.map((event) => ({
        ...event,
        start: moment(event.start).toDate(), // Convert to Date object
        end: moment(event.end).toDate(),     // Convert to Date object
      }));
      setEvents(formattedEvents);
      setCounts(response.data);
    }
  };

  useEffect(() => {
    GetEditData();
  }, [getData]);

  const handleSelectSlot = ({ start, end }) => {
    setSelectedSlot({ start, end });
    setEventToEdit(null); // Clear any existing event to edit
    setModalShow(true); // Show modal for adding event
  };

  const handleSelectEvent = (event) => {
    setEventToEdit(event); // Set the event to edit
    setSelectedSlot(null); // Clear selected slot
    setModalShow(true); // Show modal for editing event
  };
  const handleSave = async ({ title, type, start, end }) => {
    // Ensure start and end are Date objects
    const startDate = moment(start).toDate();
    const endDate = moment(end).toDate();

    if (eventToEdit) {
      const updatedEvent = { start: startDate, end: endDate, title, type };
      setEvents((prevEvents) =>
        prevEvents.map((e) => (e.id === eventToEdit.id ? updatedEvent : e))
      );

      const response = await postData(
        `/hrms-masters/calender/${eventToEdit.id}`,
        updatedEvent
      );
      if (response.success) {
        console.log("Event successfully updated on the server");
      }
    } else {
      const newEvent = {
        start: startDate,
        end: endDate,
        title,
        type,
        color: getRandomColor(),
      };

      setEvents((prevEvents) => [...prevEvents, newEvent]);

      const response = await postData("/hrms-masters/calender", newEvent);
      if (response.success) {
        console.log("Event successfully created on the server");
      }
    }
    GetEditData();
  };

  const handleDelete = async (event) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${event.title}'?`
      )
    ) {
      setEvents((prevEvents) => prevEvents.filter((e) => e.id !== event.id));
      const response = await deleteData(`/hrms-masters/calender/${event.id}`);
      if (response.success) {
        setModalShow(false);
        console.log("Event successfully deleted from the server");
        GetEditData();
      }
    }
  };

  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <p className="main-table-head">Calender</p>

        <div className="card-section">
          <div className="row">
            {/* Total Present Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/employee-cards/card1.png"
                    }
                    alt="Card 1"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Events in <br />
                    Years
                  </p>
                </div>
                <div className="count-container">
                  <p>{counts?.totalCount}</p>
                </div>
              </div>
            </div>

            {/* Total Absent Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/employee-cards/card2.png"
                    }
                    alt="Card 2"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Total <br />
                    Holidays
                  </p>
                </div>
                <div className="count-container">
                  <p>{counts?.holidayCount}</p>
                </div>
              </div>
            </div>

            {/* Late Marks Card */}
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
              <div className="designed-cards">
                <div className="img-holder">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/hrms-dashboard/Image/employee-cards/card3.png"
                    }
                    alt="Card 3"
                  />
                </div>
                <div className="text-container">
                  <p>
                    Important <br />
                    Event
                  </p>
                </div>
                <div className="count-container">
                  <p>{counts?.eventCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Calendar
            selectable
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventStyleGetter}
          />
        </Row>
      </div>
      <EventModal
        show={modalShow}
        handleClose={() => { setModalShow(false); GetEditData() }}
        handleSave={handleSave}
        eventToEdit={eventToEdit}
        deleteData={deleteData}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
      />
    </section>
  );
};

export default CalendarHrms;
