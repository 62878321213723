import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { format } from 'date-fns';
import DeleteModal from "../../../../common/DeleteModal";

const EventModal = ({ show, handleClose, handleSave, eventToEdit, selectedSlot, deleteData }) => {
    const [title, setTitle] = React.useState('');
    const [type, setType] = React.useState('');
    const [startDateTime, setStartDateTime] = React.useState('');
    const [endDateTime, setEndDateTime] = React.useState('');
    const [showDelete, setShowDelete] = React.useState(false);
    const [deleteModalShow, setDeleteModalShow] = React.useState({ show: false, heading: "", id: "" });
    const [error, setError] = React.useState('');

    const eventTypes = ['Holiday', 'Event']; // Define your event types here

    React.useEffect(() => {
        if (eventToEdit) {
            setTitle(eventToEdit.title);
            setType(eventToEdit.type);
            setStartDateTime(eventToEdit.start ? format(new Date(eventToEdit.start), "yyyy-MM-dd'T'HH:mm") : '');
            setEndDateTime(eventToEdit.end ? format(new Date(eventToEdit.end), "yyyy-MM-dd'T'HH:mm") : '');
            setShowDelete(true);
        } else {
            setTitle('');
            setType('');
            setStartDateTime(selectedSlot ? format(new Date(selectedSlot.start), "yyyy-MM-dd'T'HH:mm") : '');
            setEndDateTime(selectedSlot ? format(new Date(selectedSlot.end), "yyyy-MM-dd'T'HH:mm") : '');
            setShowDelete(false);
        }
    }, [eventToEdit, selectedSlot]);

    const onSubmit = () => {
        if (!title || !type || !startDateTime || !endDateTime) {
            setError('All fields are mandatory.');
            return;
        }

        setError(''); // Clear any existing error
        handleSave({ title, type, start: startDateTime, end: endDateTime }); // Pass datetime to handleSave
        handleClose();
    };

    const handleDeleteRecord = async (id) => {
        const response = await deleteData(
            `/hrms-masters/calender/${id}`
        );
        setDeleteModalShow({ show: false, heading: "", id: "" })
        handleClose();
    }

    const handleStartDateTimeChange = (e) => {
        const newStartDateTime = e.target.value;
        setStartDateTime(newStartDateTime);

        // If the new start time is after the end time, reset the end time
        if (endDateTime && newStartDateTime > endDateTime) {
            setEndDateTime('');
        }
    };

    const handleEndDateTimeChange = (e) => {
        const newEndDateTime = e.target.value;
        setEndDateTime(newEndDateTime);

        // If the new end time is before the start time, reset the start time
        if (newEndDateTime < startDateTime) {
            setStartDateTime('');
        }
    };





    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventToEdit ? 'Edit Event' : 'Add Event'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className='d-flex'>
                            <Form.Group className="mb-4 ms-2" controlId="formStartDateTime">
                                <Form.Label>From</Form.Label>
                                <Form.Control
                                    max={endDateTime}
                                    type="datetime-local"
                                    value={startDateTime}
                                    onChange={handleStartDateTimeChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-4 ms-4" controlId="formEndDateTime">
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                    min={startDateTime}
                                    type="datetime-local"
                                    value={endDateTime}
                                    onChange={handleEndDateTimeChange}
                                />
                            </Form.Group>
                        </div>

                        <Form.Group controlId="formEventTitle">
                            <Form.Label>Event Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter event title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formEventType">
                            <Form.Label>Event Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="">Select event type</option>
                                {eventTypes.map((eventType) => (
                                    <option key={eventType} value={eventType}>
                                        {eventType}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    {error && <div className="text-danger">{error}</div>}
                </Modal.Body>
                <Modal.Footer>
                    {showDelete && (
                        <Button variant="danger"
                            onClick={() => setDeleteModalShow({ show: true, heading: "Event", id: eventToEdit?.id })}
                        >
                            Delete
                        </Button>
                    )}
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        {eventToEdit ? 'Update Event' : 'Save Event'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <DeleteModal
                heading={deleteModalShow?.heading}
                show={deleteModalShow?.show}
                onHide={() => setDeleteModalShow({ show: false, heading: "", id: "" })}
                handleDelete={() => handleDeleteRecord(deleteModalShow?.id)}
            />
        </>
    );
};

export default EventModal;
