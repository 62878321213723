import React, { useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form } from "react-bootstrap";
import "./Task_board.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import TaskBoardCard from "./TaskBoardCard/TaskBoardCard";
import AddTaskModal from "../../Modals/AddTaskModal/AddTaskModal";
import UpdateTaskModal from "../../Modals/UpdateTaskModal/UpdateTaskModal";
library.add(fas);

function Task_board() {
  const [startDate, setStartDate] = useState("02-02-2024");
  const [endDate, setEndDate] = useState("15-02-2024");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);

  const handleApplyFilters = () => {
    // Handle applying filters
  };

  const handleResetFilters = () => {
    // Handle resetting filters
  };

  const handleCloseFilters = () => {
    // Handle closing filters
  };
  return (
    <>
      <div className="task_board" id="ticket-filters">
        <div className="task_board_form">
          <Form id="filter-form">
            <div className="row mt-3">
              <div className="col-md-3">
                <h6 className="box-title">Select Date Range</h6>
                <div
                  className="input-daterange input-group m-t-5"
                  id="date-range"
                >
                  <Form.Control
                    type="text"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <span className="input-group-addon bg-info b-0 text-white">
                    To
                  </span>
                  <Form.Control
                    type="text"
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <h6 className="box-title">Select Assigned To</h6>
                <Form>
                  <Form.Group controlId="Select_Assigned">
                    <Form.Select as="select" name="Select_Assigned">
                      <option value="1">Smita Madan Lad</option>
                      <option value="2">Java</option>
                      <option value="3">Development</option>
                      <option value="5">Digital Marketing</option>
                      <option value="6">Mobile App Development</option>
                      <option value="7">Internal Development</option>
                      <option value="8">WordPress</option>
                      <option value="9">E-Commerce Development</option>
                      <option value="10">Internal Designing</option>
                      <option value="11">Designing</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-3">
                <h6 className="box-title">Select Assigned By</h6>
                <Form>
                  <Form.Group controlId="Select_Assigned">
                    <Form.Select as="select" name="Select_Assigned">
                      <option value="1">All</option>
                      <option value="2">Java</option>
                      <option value="3">Development</option>
                      <option value="5">Digital Marketing</option>
                      <option value="6">Mobile App Development</option>
                      <option value="7">Internal Development</option>
                      <option value="8">WordPress</option>
                      <option value="9">E-Commerce Development</option>
                      <option value="10">Internal Designing</option>
                      <option value="11">Designing</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-3">
                <div className="form-group ">
                  <label className="control-label col-md-12">&nbsp;</label>
                  <Button
                    className=" me-2"
                    variant="success"
                    onClick={handleApplyFilters}
                    size="sm"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-check" /> Apply
                  </Button>
                  <Button
                    className="not_started  me-2 "
                    variant="inverse"
                    onClick={handleResetFilters}
                    size="sm"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-refresh" /> Reset
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleCloseFilters}
                    size="sm"
                    className="toggle-filter  "
                  >
                    <FontAwesomeIcon icon="fa-solid fa-close" /> Close
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div className="task_status mt-5">
          <div className="task_board_top_btn text-end">
            <Button
              id="toggle_fullscreen"
              className="simple-btn pull-right btn-sm "
            >
              <span className="sm-text">
                <FontAwesomeIcon icon="fa-solid fa-user" /> My Tasks
              </span>
            </Button>
            <Button
              id="toggle_fullscreen"
              className="simple-btn pull-right btn-sm"
            >
              <span className="sm-text">
                <FontAwesomeIcon icon="fa-solid fa-maximize" />
              </span>
            </Button>
          </div>

          <div class="row">
            <div className="col-md-3">
              <div class="card mx-2">
                <div class="card-header">
                  <div class="card-title">
                    <h6 className="text-danger lg-text">Incomplete</h6>

                    <Dropdown className="pull-right ">
                      <Dropdown.Toggle className="setting-btn">
                        <FontAwesomeIcon
                          className="text-info"
                          icon="fa-solid fa-gear "
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => setModalShow(true)}
                        >
                          New Task
                        </Dropdown.Item>
                        <AddTaskModal
                          show={modalShow}
                          onClick={() => setModalShow(true)}
                        />
                        <Dropdown.Item eventKey="2"   onClick={() => setModalShow1(true)}>Edit</Dropdown.Item>
                        <UpdateTaskModal
                          show={modalShow1}
                          onHide={() => setModalShow1(false)}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div class="card-body task_board_card_body" id="">
                  <div class="row mb-3">
                    <TaskBoardCard />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div class="card  mx-2">
                <div class="card-header ">
                  <div class="card-title">
                    <h6 className="text-success lg-text">Completed</h6>

                    <Dropdown className="pull-right ">
                      <Dropdown.Toggle className="setting-btn">
                        <FontAwesomeIcon
                          className="text-info"
                          icon="fa-solid fa-gear "
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => setModalShow(true)}
                        >
                          New Task
                        </Dropdown.Item>
                        <AddTaskModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                        />
                        <Dropdown.Item eventKey="2"   onClick={() => setModalShow1(true)}>Edit</Dropdown.Item>
                        <UpdateTaskModal
                          show={modalShow1}
                          onHide={() => setModalShow1(false)}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div class="card-body task_board_card_body ">
                  <div class="row mb-3">
                    <TaskBoardCard />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Task_board;
