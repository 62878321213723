import React from "react";
//
import Form from "react-bootstrap/Form";
import EditMemberModals from "../../Modals/EditMemberModals/EditMemberModals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button, Table } from "react-bootstrap";
import DeleteModal from "../../Modals/DeleteModal/DeleteModal";

library.add(fas);

function Members() {
  const [modalShow, setModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  return (
    <>
      {/* members tab content start (smita) */}
      <div className="members_tab_content">
        {/* members form start (smita ) */}
        <div className="row">
          <div className="col-md-6">
            <div className="members_tab_content_form">
              <Form>
                <Form.Group controlId="">
                  <div className="lable-bg">
                    <Form.Label className="mb-0">
                      Add Project Members
                    </Form.Label>
                  </div>
                  <Form.Select as="select" name="Add_Project_Members">
                    <option value="1">Choose Members</option>
                    <option value="2">Java</option>
                    <option value="3">Development</option>
                    <option value="5">Digital Marketing</option>
                    <option value="6">Mobile App Development</option>
                    <option value="7">Internal Development</option>
                    <option value="8">WordPress</option>
                    <option value="9">E-Commerce Development</option>
                    <option value="10">Internal Designing</option>
                    <option value="11">Designing</option>
                  </Form.Select>
                </Form.Group>
                <Form>
                  <Button
                    className="btn btn-success save-btn mt-5"
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-check" /> Save
                  </Button>
                </Form>
              </Form>
            </div>
          </div>

          <div className="col-md-6">
            <div className="members_tab_content_form">
              <Form>
                <Form.Group controlId="">
                  <div className="lable-bg">
                    <Form.Label className=" mb-0">Add Team</Form.Label>
                  </div>

                  <Form.Select as="select" name="Add_Team">
                    <option value="1">Department</option>
                    <option value="2">Java</option>
                    <option value="3">Development</option>
                    <option value="5">Digital Marketing</option>
                    <option value="6">Mobile App Development</option>
                    <option value="7">Internal Development</option>
                    <option value="8">WordPress</option>
                    <option value="9">E-Commerce Development</option>
                    <option value="10">Internal Designing</option>
                    <option value="11">Designing</option>
                  </Form.Select>
                </Form.Group>

                <Form>
                  <Button
                    className="btn btn-success save-btn mt-5"
                    type="submit"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-check" /> Save
                  </Button>
                </Form>
              </Form>
            </div>
          </div>
        </div>
        {/* members form end (smita ) */}

        {/* members table start  (smita) */}
        <div className="members_table mt-5">
          <div className="row">
            <div className="col-md-12">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Hourly Rate</th>
                    <th>User Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="table-body-holder">
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-sm-3 col-xs-4">
                          <img
                            src="https://teampro.profcymaglobal.com/img/default-profile-3.png"
                            alt="user"
                            className="img-circle"
                            width="40"
                            height="40"
                          />
                        </div>
                        <div className="col-sm-9 col-xs-8">
                          Aditya Madkar
                          <br />
                          <span className="text-muted font-12">
                            Software Tester
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>0.00</td>
                    <td>
                      <Form.Check
                        type="radio"
                        name="project_admin"
                        id="project_admin_373"
                        label="Project Admin"
                      />
                      <a
                        href="javascript:;"
                        className="text-danger remove-admin"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />{" "}
                        Remove
                      </a>
                    </td>
                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        className="btn-outline edit-members me-2"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen edit" />
                      </Button>
                      <EditMemberModals
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        className="btn-outline delete-members "
                        onClick={() => setDeleteModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash delete" />
                      </Button>

                      <DeleteModal
                        show={deleteModalShow}
                        onHide={() => setDeleteModalShow(false)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        {/* members table end (smita) */}
      </div>
      {/* members tab content end (smita) */}
    </>
  );
}

export default Members;
