import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function UpdateMilestonesModal(props) {
  return (
    <>
        <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          <FontAwesomeIcon className="me-1" icon="fa-solid fa-flag" /> Update Milestones
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="field_top_space">
            <div className="row">
              <div className="col-md-4">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="required">Milestone Title</Form.Label>

                    <Form.Control
                      type="text"
                      name="Hitesh Kulkarni"
                      placeholder="Hitesh Kulkarni"
                    />
                    <Form.Control type="hidden" name="Hitesh Kulkarni" />
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-4">
              <Form>
                  <Form.Group controlId="Status">
                    <Form.Label className="required">Status</Form.Label>
                    <Form.Select as="select" name="Status">
                      <option value="1">Incomplete</option>
                      <option value="2">Completed</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-4">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="required">Milestone Cost</Form.Label>

                    <Form.Control
                      type="number"
                      name=""
                      placeholder="0.00"
                    />
                    <Form.Control type="hidden" name="Hitesh Kulkarni" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="required">Milestone Summary</Form.Label>
                    <Form.Control as="textarea" name="" placeholder="" />
                    <Form.Control type="hidden" name="" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <Form>
            <Button className="btn btn-success save-btn my-2" type="submit">
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
    </>
  )
}

export default UpdateMilestonesModal