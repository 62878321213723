import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./ProjectClientModal.css";
import Table from "react-bootstrap/Table";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import ModalSave from "../../saveModal/ModalSave";
import { Row, Col, Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";

const ProjectClientEditModal = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [projects, setProjects] = useState([]);
  const id = props?.id;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();

  const { getData, postData, getDimension, Select2Data } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);
      const sendData = new FormData();
      sendData.append("name", data?.name);
      sendData.append("email", data?.email);
      sendData.append("contact_no", data?.contact_no);
      sendData.append("company_name", data?.company_name);
      sendData.append("designation", data?.designation);
      sendData.append("image", data?.image[0]);

      sendData.append("project_create", data?.project_create);
      sendData.append("project_read", data?.project_read);
      sendData.append("project_write", data?.project_write);
      sendData.append("project_delete", data?.project_delete);
      sendData.append("project_import", data?.project_import);
      sendData.append("project_export", data?.project_export);

      sendData.append("task_create", data?.task_create);
      sendData.append("task_read", data?.task_read);
      sendData.append("task_write", data?.task_write);
      sendData.append("task_delete", data?.task_delete);
      sendData.append("task_import", data?.task_import);
      sendData.append("task_export", data?.task_export);

      sendData.append("timeseet_create", data?.timeseet_create);
      sendData.append("timeseet_read", data?.timeseet_read);
      sendData.append("timeseet_write", data?.timeseet_write);
      sendData.append("timeseet_delete", data?.timeseet_delete);
      sendData.append("timeseet_import", data?.timeseet_import);
      sendData.append("timeseet_export", data?.timeseet_export);

      const response = await postData(
        `/projectmanagement/client/${id}`,
        sendData
      );
      console.log(response);
      if (response?.success) {
        setShowModal({ code: response?.code, message: response.message });
        setTimeout(() => {
          setShowModal(0);
          props.onHide();
          props.getDataAll();
          reset();
        }, 3000);
      } else {
        setShowModal({ code: response?.code, message: response.message });
        setTimeout(() => {
          setShowModal(0);
          props.onHide();
          props.getDataAll();
          reset();
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/projectmanagement/client/${id}`);
    reset(response?.data);
  };

  const getAllProject = async () => {
    const response = await getData("/project_management/allproject");
    if (response?.success) {
      setProjects(await Select2Data(response?.data, "project_id"));
    }
  };

  console.log("id", id);

  useEffect(() => {
    getAllProject();
    GetEditData();
  }, []);
  useEffect(() => {
    GetEditData();
  }, [id, props.onHide]);

  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="project-client-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Client
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Client Image</Form.Label>
                  <Form.Group>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                          // "is-valid": !errors?.image && getValues("image"),
                        })}
                        type="file"
                        {...register("image", {
                          // required: "images is required",
                        })}
                        accept="image/*"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Client Name</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Client Name"
                        className={classNames("", {
                          "is-invalid": errors?.name,
                          "is-valid": !errors?.name && getValues("name"),
                        })}
                        {...register("name", {
                          required: "Client Name is required",
                        })}
                        onKeyDown={(event) => {
                          const { key } = event;
                          if (
                            !/^[A-Za-z\s]+$/.test(key) &&
                            key !== "Backspace" &&
                            key !== "Tab"
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </InputGroup>
                    {errors.name && (
                      <span className="text-danger">{errors.name.message}</span>
                    )}
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Client Email"
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": !errors?.email && getValues("email"),
                        })}
                        {...register("email", {
                          required: "Client Email is required",
                          pattern: {
                            value:
                              /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                            message: "Invalid E-Mail Address",
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                  </Form.Group>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="contact_no"
                      placeholder="Contact No."
                      {...register("contact_no", {
                        required: "Contact number is required",
                        validate: {
                          isTenDigits: (value) =>
                            (value && value.length === 10) ||
                            "Contact number must be 10 digits",
                          isNumeric: (value) =>
                            /^\d+$/.test(value) ||
                            "Contact number must be numeric",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                        "is-valid":
                          !errors?.contact_no &&
                          getValues("contact_no")?.length === 10,
                      })}
                      onKeyDown={(event) => {
                        const { key } = event;
                        if (
                          !/[0-9]/.test(key) &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                        if (
                          event.target.value?.length >= 10 &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                  {errors.contact_no && (
                    <span className="text-danger">
                      {errors.contact_no.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Company Name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="company_name"
                      placeholder="Company Name"
                      className={classNames("", {
                        "is-invalid": errors?.company_name,
                        "is-valid":
                          !errors?.company_name && getValues("company_name"),
                      })}
                      {...register("company_name", {
                        required: "Company Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.company_name && (
                    <span className="text-danger">
                      {errors.company_name.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Designation</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="designation"
                      placeholder="Designation"
                      className={classNames("", {
                        "is-invalid": errors?.designation,
                        "is-valid":
                          !errors?.designation && getValues("designation"),
                      })}
                      {...register("designation", {
                        required: "Designation is required",
                      })}
                      onKeyDown={(event) => {
                        const { key } = event;
                        if (
                          !/^[A-Za-z\s]+$/.test(key) &&
                          key !== "Backspace" &&
                          key !== "Tab"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                  {errors.designation && (
                    <span className="text-danger">
                      {errors.designation.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <div className="brown-border"></div>
            <div className="text-center">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Module Permission</th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Create</th>
                    <th>Delete</th>
                    <th>Import</th>
                    <th>Export</th>
                  </tr>
                </thead>
                <tbody className="table-body-holder">
                  <tr>
                    <td>Project</td>
                    <td>
                      <Form.Check
                        name="project_read"
                        type="checkbox"
                        {...register("project_read")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="project_write"
                        type="checkbox"
                        {...register("project_write")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="project_create"
                        type="checkbox"
                        {...register("project_create")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="project_delete"
                        type="checkbox"
                        {...register("project_delete")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="project_import"
                        type="checkbox"
                        {...register("project_import")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="project_export"
                        type="checkbox"
                        {...register("project_export")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Task</td>
                    <td>
                      <Form.Check
                        name="task_read"
                        type="checkbox"
                        {...register("task_read")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="task_write"
                        type="checkbox"
                        {...register("task_write")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="task_create"
                        type="checkbox"
                        {...register("task_create")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="task_delete"
                        type="checkbox"
                        {...register("task_delete")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="task_import"
                        type="checkbox"
                        {...register("task_import")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="task_export"
                        type="checkbox"
                        {...register("task_export")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Timing Sheet</td>
                    <td>
                      <Form.Check
                        name="timeseet_read"
                        type="checkbox"
                        {...register("timeseet_read")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="timeseet_write"
                        type="checkbox"
                        {...register("timeseet_write")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="timeseet_create"
                        type="checkbox"
                        {...register("timeseet_create")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="timeseet_delete"
                        type="checkbox"
                        {...register("timeseet_delete")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="timeseet_import"
                        type="checkbox"
                        {...register("timeseet_import")}
                      />
                    </td>
                    <td>
                      <Form.Check
                        name="timeseet_export"
                        type="checkbox"
                        {...register("timeseet_export")}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <Modal.Footer>
              <div className="mx-auto">
                <Button
                  // onClick={props.onHide}
                  className="submit-btn me-2"
                  type="submit"
                >
                  <FontAwesomeIcon icon={faFloppyDisk} /> Save
                </Button>
                <Button onClick={props.onHide} className="submit-btn">
                  <FontAwesomeIcon icon={faXmark} /> Cancel
                </Button>
              </div>
              <ModalSave
                message={showModal.message}
                showErrorModal={showModal.code ? true : false}
              />
            </Modal.Footer>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
                    <div className='mx-auto'>
                        <Button onClick={props.onHide} className='submit-btn me-2'>
                            <FontAwesomeIcon icon={faFloppyDisk} /> Save
                        </Button>
                        <Button onClick={props.onHide} className='submit-btn'>
                            <FontAwesomeIcon icon={faXmark} /> Cancel
                        </Button>
                    </div>
                </Modal.Footer> */}
      </Modal>
    </section>
  );
};

export default ProjectClientEditModal;
