import { Modal } from "react-bootstrap";
import "../../../CommonCss/ModalCommonCss.css";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../../../utils/context";
import { useFieldArray, useForm } from "react-hook-form";
import classNames from "classnames";
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddModal = (props) => {
  const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });
  const { postData, getData, Select2Data } = useContext(Context);
  const { register, handleSubmit, reset, setValue, control, watch, formState: { errors }, getValues } = useForm();
  const { fields, append, remove } = useFieldArray({ control, name: "leaves" });

  const [fromYear, setFromYear] = useState(null);
  const [toYear, setToYear] = useState(null);
  const [totalLeaves, setTotalLeaves] = useState(0);

  const onSubmit = async (data) => {
    console.log("datadtadtadatad", data);

    try {
      const sendData = new FormData();
      sendData.append("from_year", data?.from_year);
      sendData.append("to_year", data?.to_year);
      sendData.append("total_paid_leaves", totalLeaves); // Use totalLeaves state

      const allLeaves = data.leaves.map((item) => ({
        leave_type_id: item.leave_type_id,
        paid_leaves: item.paid_leaves,
      })) || [];

      sendData.append("leaves", JSON.stringify(allLeaves));

      const response = await postData(`/hrms-masters/leave-setup`, sendData);
      if (response?.success) {
        setShowLottie({ show: true, msg: response?.message, success: response?.success });
        reset();
        setFromYear("")
        setToYear("")
        setTotalLeaves("")
      } else {
        setShowLottie({ show: true, msg: response?.message, success: response?.success });
      }
      setTimeout(() => {
        setShowLottie({ show: false, msg: "", success: false });
        props?.onHide();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFromYearChange = (date) => {
    if (date) {
      const selectedYear = date.getFullYear();
      setFromYear(new Date(selectedYear, 3, 1));
      setToYear(new Date(selectedYear + 1, 2, 30));
      setValue("from_year", selectedYear);
      setValue("to_year", selectedYear + 1);
    } else {
      setFromYear(null);
      setToYear(null);
      setValue("from_year", null);
      setValue("to_year", null);
    }
  };

  const getLeaveTypes = async () => {
    const response = await getData("/masters/leave_type");
    const leaveTypesData = await Select2Data(response?.data, "leave_type_id");

    // Clear existing leaves to prevent duplicates
    remove();

    // Prepare leave types for appending
    leaveTypesData.forEach(type => append({
      leave_type_id: type.value,
      label: type.label,
      paid_leaves: "" // Initialize paid_leaves as an empty string
    }));
  };

  useEffect(() => {
    getLeaveTypes();
  }, []);

  const calculateTotalLeaves = (leaves) => {
    return leaves?.reduce((total, leave) => {
      const paidLeaves = Number(leave?.paid_leaves) || 0;
      return total + paidLeaves;
    }, 0);
  };

  const calculate = (event, index) => {
    const updatedLeaves = getValues("leaves");
    const newValue = event.target.value ? Number(event.target.value) : 0;

    // Update the specific index with the new value
    updatedLeaves[index].paid_leaves = newValue;

    // Calculate the new total
    const total = calculateTotalLeaves(updatedLeaves);
    setTotalLeaves(total);
    setValue("total_leaves", total);
  };

  return (
    <section className="modal-common-class-hrms">
      <Modal {...props} size="md" centered className="add-modal">
        <Modal.Body>
          <div className="text-center">
            <h4 className="modal-title-common">Add Leave Setup</h4>
          </div>

          <Container>
            <Row>
              <Col md={12}>
                <Container>
                  <Row>
                    <Col xxl={12} xl={12} md={10}>
                      <Form onSubmit={handleSubmit(onSubmit)} role="form">
                        <Row>
                          <Col lg={8} md={10} className="mx-auto Add-content">
                            <Row>
                              <div className="main-form-section mt-5">
                                <Form.Label>From Year</Form.Label>
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <DatePicker
                                      required
                                      selected={fromYear}
                                      onChange={handleFromYearChange}
                                      showYearPicker
                                      dateFormat="yyyy"
                                      filterDate={(date) => date >= new Date(new Date().getFullYear() + 1, 0, 1)} // Restrict dates
                                      className={classNames("form-control", {
                                        "is-invalid": errors?.from_year,
                                      })}
                                    />
                                  </InputGroup>
                                  {errors.from_year && (
                                    <span className="text-danger">
                                      {errors.from_year.message}
                                    </span>
                                  )}
                                </Form.Group>
                              </div>
                            </Row>
                            <Row>
                              <div className="main-form-section mt-2">
                                <Form.Label>To Year</Form.Label>
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <DatePicker
                                      disabled
                                      selected={toYear}
                                      showYearPicker
                                      dateFormat="yyyy"
                                      className={classNames("form-control", {
                                        "is-invalid": errors?.to_year,
                                      })}
                                    />
                                  </InputGroup>
                                  {errors.to_year && (
                                    <span className="text-danger">
                                      {errors.to_year.message}
                                    </span>
                                  )}
                                </Form.Group>
                              </div>
                            </Row>

                            {fields.map((type, index) => (
                              <Row key={type.leave_type_id || index}>
                                <div className="main-form-section mt-2">
                                  <Form.Label>{type.label}</Form.Label>
                                  <Form.Group className="mb-3">
                                    <InputGroup>
                                      <Form.Control
                                        type="number"
                                        step={0.5}
                                        placeholder="Paid Leaves"
                                        className={classNames("", {
                                          "is-invalid": errors.leaves?.[index]?.paid_leaves,
                                        })}
                                        {...register(`leaves[${index}].paid_leaves`, {
                                          required: "Paid Leaves is required",
                                        })}
                                        onChange={(event) => calculate(event, index)}
                                      />

                                    </InputGroup>
                                    <input
                                      type="hidden"
                                      {...register(`leaves[${index}].leave_type_id`)} // Registering leave_type_id
                                      value={type.leave_type_id}
                                    />
                                    {errors.leaves?.[index]?.paid_leaves && (
                                      <span className="text-danger">{errors.leaves[index].paid_leaves.message}</span>
                                    )}
                                  </Form.Group>
                                </div>
                              </Row>
                            ))}


                            <Row>
                              <div className="main-form-section mt-2">
                                <Form.Label>Total Paid Leaves</Form.Label>
                                <Form.Group className="mb-3">
                                  <InputGroup>
                                    <Form.Control
                                      disabled
                                      type="text"
                                      value={totalLeaves || 0}
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </div>
                            </Row>
                            <Row className="mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link>
                                  <button
                                    className="cancel-button me-2"
                                    onClick={() => {
                                      props.onHide(); // Hide the modal
                                      reset(); // Reset the form
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </Link>

                                <button
                                  className="save-button"
                                  type="submit"
                                >
                                  Save
                                </button>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <AddedLottieModal
        pageTitle="Designation"
        message={showLottie.msg}
        show={showLottie.show}
        success={showLottie.success}
        onHide={() => setShowLottie({ show: false, msg: "", success: false })}
      />
    </section>
  );
};

export default AddModal;
