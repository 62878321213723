import React, { useState } from "react";
import { SketchPicker } from "react-color"; // Import the color picker component from react-color
import { Form, InputGroup } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { TimePicker } from "antd";
library.add(fas);

function UpdateTimeLogModal(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Time Log{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <div className="form-body">
              <div className="field_top_space">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Group controlId="status">
                      <Form.Label>Project</Form.Label>
                      <Form.Select as="select">
                        <option value="">Demo</option>
                        <option value="">--</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group controlId="status">
                      <Form.Label>Task</Form.Label>
                      <Form.Select as="select">
                        <option value="No_task">
                          No Task Added To The Project
                        </option>
                        <option value="nill">--</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-4">
                    <Form.Group controlId="status">
                      <Form.Label>Employee Name</Form.Label>
                      <Form.Select as="select">
                        <option value="Smita_Lad">Smita_Lad</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="field_top_space">
                <div className="row">
                  <div className="col-md-6">
                    <Form>
                      <Form.Group controlId="Start_Date">
                        <Form.Label className="">Start Date</Form.Label>
                        <div>
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            name="start_date"
                            id="start_date"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="col-md-6">
                    <Form>
                      <Form.Group controlId="End_Date">
                        <Form.Label className="">End Date</Form.Label>
                        <div>
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            name="end_date"
                            id="end_date"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>

              <div className="field_top_space">
                <div className="row">
                  <div className="col-md-3">
                    <Form>
                      <Form.Group controlId="Start_Time">
                        <Form.Label className="">Start Time</Form.Label>
                        <div>
                          <TimePicker
                            name="start_time"
                            id="start_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="col-md-3">
                    <Form>
                      <Form.Group controlId="End_Time">
                        <Form.Label className="">End Time</Form.Label>
                        <div>
                          <TimePicker
                            name="end_time"
                            id="end_time"
                            autoComplete="off"
                            className="form-control"
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>

                  <div className="col-md-3">
                    <Form>
                      <Form.Group controlId="End_Time">
                        <Form.Label className="">Total Hours</Form.Label>
                        <Form.Control
                          type="text"
                          name="Total Hours"
                          placeholder="0Hrs 0Mins"
                        />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </div>

              <div className="field_top_space">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group controlId="Memo">
                      <Form.Label>Memo</Form.Label>
                      <Form.Control type="text" defaultValue="" />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </Form>

          <Form>
            <Button
              className="btn btn-success save-btn my-2 me-2"
              type="submit"
            >
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateTimeLogModal;
