import React, { useState, useContext, useEffect } from "react";
import "../../style/Modals/Modals.css";
import "../../style/Common/Common.css";
import "../../style/Form/Form.css";
import "../../style/Tables/Tables.css";
import { Context } from "../../../../../../utils/context";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Select from "react-select";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function FormModals(props) {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [role, setRole] = useState([]);
  const { postData, getData } = useContext(Context);

  const [formData, setFormData] = useState({
    id: "",
    role_id: "",
    name: "",
    // mobile_number: "",
    email: "",
    password: "",
    // cpassword: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleChange = (e) => {
    if (e?.name == "role_id") {
      setFormData({ ...formData, [e.name]: e });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (event) => {
    // Prevent the default form submission behavior
    event.preventDefault();

    try {
      // Call your API to submit the form data
      const response = await postData("/employee/employee_details", formData);

      // Handle success
      console.log("Form data submitted successfully:", response);
      // Close the modal if the response is successful
      if (response.success) {
        props.onHide(); // Close the modal
      }
      // Optionally, you can reset the form after successful submission
      // setFormData(initialFormData); // Assuming initialFormData is the initial state of your form
    } catch (error) {
      // Handle error
      console.error("Error submitting form data:", error);
    }
  };

  const GetAllRoles = async () => {
    const response = await getData("/masters/allroles");

    const option = [];
    if (response.success) {
      response?.data?.map((data) => {
        option.push({
          value: data.id,
          name: "role_id",
          label: data.name,
        });
      });
    }
    setRole(option);
  };

  useEffect(() => {
    GetAllRoles();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Employee Info
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="field_top_space">
            <div className="row">
              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Employee_ID">
                    <Form.Label className="required">Employee ID</Form.Label>
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      className="text-dark gear-icon"
                    />
                    <Form.Control
                      type="text"
                      name="id"
                      placeholder="Enter Employee ID"
                      value={formData.id}
                      onChange={handleChange}
                    />
                    <Form.Control type="hidden" name="Employee_ID" />
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Employee_Name">
                    <Form.Label className="required">Employee Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Employee Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <Form.Control type="hidden" name="Employee_Name" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="field_top_space">
            <div className="row">
              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Employee_Email">
                    <Form.Label className="required">Employee Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Employee Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <Form.Control type="hidden" name="email" />
                    <span className="help-block">
                      Employee will login using this email.
                    </span>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Password">
                    <Form.Label className="required">Password</Form.Label>
                    <div className="password_field">
                      <Form.Control
                        type="text"
                        name="password"
                        placeholder="Enter Password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <div className="password-icon">
                        <FontAwesomeIcon
                          className="text-dark gear-icon"
                          icon="fa-solid fa-eye"
                        />
                      </div>
                    </div>
                    <Form.Control type="hidden" name="Password" />
                    <span className="help-block">
                      {" "}
                      Employee will login using this password.{" "}
                    </span>
                  </Form.Group>
                </Form>

                <div className="field_top_space">
                  <Form.Group>
                    <div className="checkbox checkbox-info">
                      <Form.Check
                        type="checkbox"
                        id="Generate_Random_Password"
                        name="Generate Random Password"
                        value="true"
                        label="Generate Random Password"
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="field_top_space">
            <div className="row">
              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Designation">
                    <Form.Label className="required">Designation</Form.Label>
                    <Form.Select as="select" name="Designation">
                      <option value="1">--</option>
                      <option value="2">Java</option>
                      <option value="3">Development</option>
                      <option value="5">Digital Marketing</option>
                      <option value="6">Mobile App Development</option>
                      <option value="7">Internal Development</option>
                      <option value="8">WordPress</option>
                      <option value="9">E-Commerce Development</option>
                      <option value="10">Internal Designing</option>
                      <option value="11">Designing</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Department">
                    <Form.Label className="required">Role</Form.Label>
                    {/* <Form.Select as="select" name="Department">
                      <option value="1">--</option>
                      <option value="2">Java</option>
                      <option value="3">Development</option>
                      <option value="5">Digital Marketing</option>
                      <option value="6">Mobile App Development</option>
                      <option value="7">Internal Development</option>
                      <option value="8">WordPress</option>
                      <option value="9">E-Commerce Development</option>
                      <option value="10">Internal Designing</option>
                      <option value="11">Designing</option>
                    </Form.Select> */}
                    <Select
                      name="role_id"
                      className="input-mandatory"
                      onChange={handleChange}
                      value={formData.role_id}
                      options={role}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="field_top_space">
            <div className="row">
              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Joining_Date">
                    <Form.Label className="required">Joining Date</Form.Label>
                    <div>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        name="start_date"
                        id="start_date"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Gender">
                    <Form.Label className="control-label">Gender</Form.Label>
                    <Form.Select as="select" name="Gender">
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <Form onSubmit={handleSubmit}>
            <Button className="btn btn-success save-btn my-2" type="submit">
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FormModals;
