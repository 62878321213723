import React from "react";
import DashboardTable from "./dashboard-table/DashboardTable";
const ComplaintManagement = () => {
  return (
    <>
      <DashboardTable />
    </>
  );
};

export default ComplaintManagement;
