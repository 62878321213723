// import React, { useContext, useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Form from "react-bootstrap/Form";
// import Select from "react-select";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { useForm } from "react-hook-form";
// import { Context } from "../../../../utils/context";

import { Link } from "react-router-dom";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Modal } from "react-bootstrap";

import React, { useContext, useEffect, useState } from "react";

import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../../common/ModalSave";
import { Context } from "../../../../../utils/context";
import { Controller, useFieldArray } from "react-hook-form";
import AddedLottieModal from "../../../../Hrms-Dashboard/commonLottie/AddedLottieModal";
import { useNavigate, useParams } from "react-router";
import { format } from "date-fns";
const Edit = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [showLottie, setshowLottie] = useState(false);

  const { postData, Select2Data, getData } = useContext(Context);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await postData(
        `/super-admin/masters/thired-party-api/${id}`,
        data
      );

      if (response?.success) {
        setshowLottie(true);
        setTimeout(() => {
          setshowLottie(false);
          reset();
          navigate("/super-admin/masters/thired-party-api");
          // props.onHide();
        }, 2000);
      } else {
        setshowLottie();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetEditData();
  }, [id]);

  const GetEditData = async () => {
    const response = await getData(
      `/super-admin/masters/thired-party-api/${id}`
    );
    reset(response?.data);
  };

  // ----------------------------------------------------------------

  return (
    <>
      <section className="maintablesec">
        <div className="addsection">
          <h6 className="addtitle">Edit Third Party API Charge</h6>
        </div>

        <div className="addformseccc mt-5">
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <div className="row">
              <div className="col-lg-4 mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Name"
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      {...register("name", {
                        required: "Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Charges</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="charges"
                      placeholder="Charges"
                      className={classNames("", {
                        "is-invalid": errors?.charges,
                      })}
                      {...register("charges", {
                        required: "Charges is required",
                      })}
                    />
                  </InputGroup>
                  {errors.charges && (
                    <span className="text-danger">
                      {errors.charges.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-12 mb-4 text-center">
                <Form.Group className="form-group">
                  {" "}
                  <button type="submit" className="submit-btn btn me-2">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="submit-btn btn"
                    onClick={() =>
                      navigate(`/super-admin/masters/thired-party-api`)
                    }
                  >
                    Close
                  </button>
                </Form.Group>
              </div>
            </div>
          </Form>
        </div>
      </section>
      <AddedLottieModal
        pageTitle="Thired Part API Charges Updated"
        show={showLottie}
        onHide={() => setshowLottie(false)}
      />
    </>
  );
};

export default Edit;
