import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import SucessfullyScheduleDateModal from "./SuccessfulScheduleTimeModel";
import { useForm, Controller } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import { Col, Row } from "react-bootstrap";
const ScheduleDateChangemodal = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { getData, postData, Select2Data } = useContext(Context);

  const [complaint_status, setcomplaint_status] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = useForm();

  useEffect(() => {
    reset({ ids: props?.ids });
  }, [props?.ids]);

  const [scheduleDate, setScheduleDate] = useState("");
  const [minScheduleDate, setMinScheduleDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });

  const handleScheduleDateChange = (event) => {
    setScheduleDate(event.target.value);
  };

  const onSubmit = async (data) => {
    try {
      const response = await postData(
        `/complaint-management/complaints/schedule-time`,
        data
      );
      // console.log(response);
      if (response?.success) {
        await setModalStatus(response?.success);
      }
      reset();
      setTimeout(() => {
        setModalStatus(0);
        props.onHide();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    props.getAllData();
    reset();
  }, [props.show]);

  return (
    <>
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-class-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h6 className="mt-2"> Schedule Time And Date Change</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Schedule Time</Form.Label>
              <Form.Control
                required
                type="time"
                className={errors.schedule_time ? "is-invalid" : ""}
                {...register("schedule_time", {
                  required: true,
                })}
              />
            </Form.Group>

            <Form.Label>Schedule Date</Form.Label>
            <Form.Control
              required
              type="date"
              min={minScheduleDate}
              onChange={handleScheduleDateChange}
              className={errors.schedule_date ? "is-invalid" : ""}
              {...register("schedule_date", {
                required: "Schedule Date is required",
              })}
            />
            {errors.schedule_date && (
              <Form.Control.Feedback type="invalid">
                {errors.schedule_date.message}
              </Form.Control.Feedback>
            )}

            <Form.Label>Completion Date</Form.Label>
            <Form.Control
              required
              type="date"
              min={minScheduleDate}
              onChange={handleScheduleDateChange}
              className={errors.completion_time ? "is-invalid" : ""}
              {...register("completion_time", {
                required: "Completion Date is required",
                validate: (value) =>
                  value >= watch("schedule_date") ||
                  "Completion Date cannot be before Schedule Date",
              })}
            />
            {errors.completion_time && (
              <Form.Control.Feedback type="invalid">
                {errors.completion_time.message}
              </Form.Control.Feedback>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            type="button"
            onClick={handleSubmit(onSubmit)}
            // onClick={() => {
            //   setModalStatus(true);
            //   props.onHide();
            // }}
          >
            Change Schedule Time And Date
          </Button>
        </Modal.Footer>
      </Modal>
      <SucessfullyScheduleDateModal
        show={modalStatus}
        onHide={() => setModalStatus(false)}
      />
    </>
  );
};

export default ScheduleDateChangemodal;
