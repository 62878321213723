import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./../Task_board/Task_board.css";
import "../../Veiw_Details/Veiw_Details.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import BurnChart from "./BurnChart/BurnChart";
library.add(fas);

function BurndownChart() {
  const [startDate, setStartDate] = useState("02-02-2024");
  const [endDate, setEndDate] = useState("15-02-2024");

  

  const handleApplyFilters = () => {
    // Handle applying filters
  };

  const handleResetFilters = () => {
    // Handle resetting filters
  };

  const handleCloseFilters = () => {
    // Handle closing filters
  };
  return (
    <>
      <div className="task_board ">
        <div className="BurndownChart_form">
          <Form id="filter-form">
            <div className="row mt-3">
              <div className="col-md-3">
                <h6 className="box-title">Select Date Range 09-02-2024</h6>
                <div
                  className="input-daterange input-group m-t-5"
                  id="date-range"
                >
                  <Form.Control
                    type="text"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                  <span className="input-group-addon bg-info b-0 text-white">
                    To
                  </span>
                  <Form.Control
                    type="text"
                    placeholder="End Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group ">
                  <label className="control-label col-md-12">&nbsp;</label>
                  <Button
                    className=" me-2"
                    variant="success"
                    onClick={handleApplyFilters}
                    size="sm"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-check" /> Apply
                  </Button>
                  <Button
                    className="not_started  me-2 "
                    variant="inverse"
                    onClick={handleResetFilters}
                    size="sm"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-refresh" /> Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>

        {/* burn_down_chart section start (smita ) */}
        <div className="chart-sec">
          <div className="row">
            <div className="col-md-12">
            <BurnChart/>
            </div>
          </div>
        </div>
        {/* burn_down_chart section end (smita ) */}
      </div>
    </>
  );
}

export default BurndownChart;
