import React, { useState } from "react";
import "../Veiw_Details.css";
import "../../projectsec.css";
import "../../style/Modals/Modals.css";
import "../../style/Form/Form.css";
import "../../style/Common/Common.css";
import "../../style/Tables/Tables.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button, Pagination, Table } from "react-bootstrap";
import UpdateMilestonesModal from "../../Modals/UpdateMilestonesModal/UpdateMilestonesModal";
import DeleteModal from "../../Modals/DeleteModal/DeleteModal";
import MilestonesDetails from "../../Modals/MilestonesDetails/MilestonesDetails";
library.add(fas);

function Milestone() {
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      {/* Milestone tab content start (smita) */}
      <div className="Milestone_tab_content">
        {/* Milestone form start (smita ) */}
        <div className="create_milestone_btn">
          <div className="row">
            <div className="col-md-12">
              <Button
                className="btn btn-success save-btn my-2"
                type="button" // Change type to "button" to prevent form submission
                onClick={toggleFormVisibility}
              >
                <FontAwesomeIcon className="me-2" icon="fa-solid fa-flag " />
                Create Milestone
              </Button>
            </div>
          </div>
        </div>

        {showForm && (
          <div className="create_milestone_form">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <div className="form-body">
                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group controlId="milestone_title">
                            <Form.Label>Milestone Title</Form.Label>
                            <Form.Control type="text" name="Milestone Title" />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group controlId="status">
                            <Form.Label>Status</Form.Label>
                            <Form.Select as="select">
                              <option value="incomplete">Incomplete</option>
                              <option value="complete">Complete</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group controlId="cost">
                            <Form.Label>Milestone Cost</Form.Label>
                            <Form.Control
                              type="number"
                              defaultValue="0"
                              min="0"
                              step="0.01"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group controlId="add_to_budget">
                            <Form.Label>Add Cost To Project Budget</Form.Label>
                            <Form.Select as="select">
                              <option value="no">No</option>
                              <option value="yes">Yes</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="field_top_space">
                      <div className="row">
                        <div className="col-md-12">
                          <Form.Group controlId="summary">
                            <Form.Label>Milestone Summary</Form.Label>
                            <Form.Control as="textarea" rows={4} />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-actions mt-3">
                    <Form>
                      <Button
                        className="btn btn-success save-btn my-2 me-2"
                        type=""
                      >
                        <FontAwesomeIcon icon="fa-solid fa-check" /> Save
                      </Button>

                      <Button
                        className="btn btn-secondary save-btn my-2"
                        type=""
                        onClick={toggleFormVisibility}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-times " /> Close
                      </Button>
                    </Form>
                  </div>
                </Form>

                <hr />
              </div>
            </div>
          </div>
        )}

        {/* Milestone form end (smita ) */}

        {/* Milestone table start  (smita) */}
        <div className="Milestone_table mt-5">
          <div className="row justify-content-between">
            <div className="col-md-2">
              <div className="dataTables_length " id="timelog-table_length">
                <Form.Label className="flex-box">
                  <p className="xl-text mb-0 me-1">Show</p>
                  <Form.Select
                    as="select"
                    name="timelog-table_length"
                    aria-controls="timelog-table"
                    className="form-control input-sm"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Select>{" "}
                  <p className="xl-text mb-0 ms-1">entries</p>
                </Form.Label>
              </div>
            </div>
            <div className="col-md-3">
              <div id="timelog-table_filter" className="dataTables_filter ">
                <Form.Label className="flex-box">
                  <p className="xl-text mb-0 me-2">Search:</p>
                  <Form.Control
                    type="search"
                    className="form-control input-sm"
                    placeholder=""
                    aria-controls="timelog-table"
                  />
                </Form.Label>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <Table
                bordered
                hover
                responsive="md"
                id="timelog-table"
                className="toggle-circle default footable-loaded footable dataTable no-footer dtr-inline"
                role="grid"
                aria-describedby="timelog-table_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_desc"
                      tabIndex="0"
                      aria-controls="timelog-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-sort="descending"
                      aria-label="Id: activate to sort column ascending"
                    >
                      Id
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="timelog-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Milestone Title: activate to sort column ascending"
                    >
                      Milestone Title
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="timelog-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Milestone Cost: activate to sort column ascending"
                    >
                      Milestone Cost
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="timelog-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Status: activate to sort column ascending"
                    >
                      Status
                    </th>
                    <th
                      className="sorting"
                      tabIndex="0"
                      aria-controls="timelog-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Action: activate to sort column ascending"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body-holder">
                  <tr className="odd">
                    <td>1</td>

                    <td>
                      <p
                        className="lg-text text-primary"
                        onClick={() => setModalShow1(true)}
                      >
                        Demo
                      </p>
                    </td>
                    <MilestonesDetails
                      show={modalShow1}
                      onHide={() => setModalShow1(false)}
                    />

                    <td>₹0.00</td>

                    <td>
                      <label class="label label-danger">Incomplete</label>
                    </td>

                    <td>
                      <Button
                        variant="info"
                        size="sm"
                        className="btn-outline edit-members me-2"
                        onClick={() => setModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pen edit" />
                      </Button>

                      <UpdateMilestonesModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />

                      <Button
                        variant="danger"
                        size="sm"
                        className="btn-outline delete-members "
                        onClick={() => setDeleteModalShow(true)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-trash delete" />
                      </Button>
                      <DeleteModal
                        show={deleteModalShow}
                        onHide={() => setDeleteModalShow(false)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="row justify-content-between">
            <div className="col-md-6">
              <div
                className="dataTables_info"
                id="timelog-table_info"
                role="status"
                aria-live="polite"
              >
                <p className="sm-text">Showing 0 to 0 of 0 Entries</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="Pagination_end">
                <nav aria-label="..." className="templateNav">
                  <Pagination>
                    <Pagination.Prev className="sm-text">
                      Previous
                    </Pagination.Prev>
                    <Pagination.Item className="sm-text">1</Pagination.Item>
                    <Pagination.Item className="sm-text" active>
                      2
                    </Pagination.Item>
                    <Pagination.Item className="sm-text">3</Pagination.Item>
                    <Pagination.Next className="sm-text"> Next</Pagination.Next>
                  </Pagination>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Milestone table end (smita) */}
      </div>
      {/* Milestone tab content end (smita) */}
    </>
  );
}

export default Milestone;
