import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "./superadmin_dashboard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { Context } from "../../../../utils/context";
import { Bar } from "react-chartjs-2";

import { Chart } from "primereact/chart";

const Dashboard = () => {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: "My Second dataset",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <>
      <section className="superadmin_dashboard">
        <h1 className="maintitle">Dashboard</h1>

        <div className="alldetailssec">
          <div className="row">
            <div className="col-xl-3 col-md-4 mb-4">
              <div className="detail-box">
                <div className="row">
                  <div className="col-2">
                    <div className="icondiv">
                      <FontAwesomeIcon className="icon" icon={faUser} />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="text-div">
                      <h6 className="count">20</h6>
                      <p className="name">Total Subscriber</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-4 mb-4">
              <div className="detail-box">
                <div className="row">
                  <div className="col-2">
                    <div className="icondiv">
                      <FontAwesomeIcon className="icon" icon={faUser} />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="text-div">
                      <h6 className="count">20</h6>
                      <p className="name">Active Subscriber</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-4 mb-4">
              <div className="detail-box">
                <div className="row">
                  <div className="col-2">
                    <div className="icondiv">
                      <FontAwesomeIcon className="icon" icon={faUser} />
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="text-div">
                      <h6 className="count">20</h6>
                      <p className="name">Expired Subscriber</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="allchartssec mt-5">
          <div className="row">
            <div className="col-md-6">
              <Chart type="bar" data={chartData} options={chartOptions} />
            </div>

            <div className="col-md-6">
              <Chart
                type="polarArea"
                data={chartData}
                options={chartOptions}
                className="w-full md:w-30rem"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
