import React, { useContext, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./comheader.css";
import { Modal, Offcanvas } from "react-bootstrap";
import { Select } from "antd";
import Cookies from "js-cookie";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router";
const ComHeader = (id) => {
  const { setUsertype, setUserData, setSignin, getData, userdata } =
    useContext(Context);
  const navigate = useNavigate();
  const [notShow, setNotShow] = useState(false);

  const [smShow, setSmShow] = useState(false);

  const [show, setShow] = useState(false);
  const [users, serUsers] = useState([]);

  const handleClose = () => setShow(false);
  const handleClose2 = () => setSmShow(false);
  const handleShow = () => setShow(true);

  const LogOut = async () => {
    Cookies.remove("it_pulse_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };
  const [notifications, setNotifications] = useState([]);
  const getAllData = async () => {
    {
      const res = await getData(
        `/complaint-management/dashboard/notifications`
      );
      setNotifications(res?.data);
    }
  };
  // const getUserData = async () => {
  //   {
  //     const res2 = await getData(userdata);
  //     console.log(getUserData);
  //   }
  // };

  console.log(userdata);

  // const getUsers = async () => {
  //   {
  //     const res = await getData(`/login/${user_id}`);
  //     setNotifications(res?.data);
  //   }
  // };
  useEffect(() => {
    getAllData();
    // getUserData();
    // getUsers();
  }, []);

  function calculateTimeDifference(updatedAt) {
    const updatedAtDate = new Date(updatedAt);
    const currentDate = new Date();
    const timeDifference = (currentDate - updatedAtDate) / (1000 * 3600); // Difference in hours
    const hours = Math.floor(timeDifference);

    if (hours === 0) {
      return "Just now";
    } else if (hours === 1) {
      return "1 hour ago";
    } else {
      return `${hours} hours ago`;
    }
  }

  return (
    <>
      <div className="com-header">
        <Navbar className="bg-body-tertiary justify-content-between">
          <Form inline>
            <InputGroup className="ms-5">
              {/* <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
              </InputGroup.Text> */}
              {/* <Form.Control
                placeholder="Type here to search"
                aria-label="Username"
                aria-describedby="basic-addon1"
              /> */}
            </InputGroup>
          </Form>
          <Form inline>
            <Row className="ms-0 me-0">
              <Col>
                <div
                  className="notification-main me-lg-5 me-1 mt-2"
                  onClick={handleShow}
                >
                  <FontAwesomeIcon
                    icon="fa-solid fa-bell"
                    className=" notification-icon mt-3"
                  />
                  {/* <div className="number-bg">
                    <p className="notification-number">7</p>
                  </div> */}
                </div>
              </Col>
              <Col xs="auto" className="user-image d-flex">
                <div className="ms-5 me-2 ">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/complaint-management/icon/user.png"
                    }
                    className="user-img mt-3"
                    alt="..."
                  />
                </div>
                <div className="me-5 mt-3">
                  <p className="header-text">
                    Hello,
                    <br /> <span>{userdata.name}</span>
                  </p>
                </div>
              </Col>
              <Col xs="auto" className="me-4 mt-2">
                <Button
                  type="button"
                  className="next-btn-class mt-3"
                  onClick={() => setSmShow(true)}
                >
                  <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                </Button>
              </Col>
            </Row>
          </Form>
        </Navbar>
      </div>

      {/* notification offcanvas */}

      <div className="">
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="top"
          className="notification-section"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Row>
              <Col md={6} className="mx-auto">
                <div className="notifi-pop-main">
                  {notifications?.map((data, index) => (
                    <div className="notifi-first first1" key={index}>
                      <div className="notifi-left">
                        <p className="righttxt">{data?.message} </p>
                      </div>
                      <div className="notifi-right">
                        <p className="timetxt">
                          {" "}
                          {calculateTimeDifference(data.updatedAt)}
                        </p>
                      </div>
                    </div>
                  ))}

                  {/* <div className="notifi-first">
                    <div className="notifi-left">
                      <p className="righttxt">New complaint received</p>
                    </div>
                    <div className="notifi-right">
                      <p className="timetxt">2 hr ago</p>
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {/* logout model */}
      <div>
        <Modal
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Body>
            <div className="mod-title">
              <p className="mod-txt">
                Are you sure you want to logout from account ?
              </p>
            </div>

            <div className="mod-buuton-main">
              <div className="modbuttn">
                <button onClick={handleClose2} className="modbtn1">
                  Cancel
                </button>
              </div>
              <div className="modbuttn">
                <button className="modbtn2" type="button" onClick={LogOut}>
                  Log Out
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ComHeader;
