import { useContext, useEffect, useState } from "react";
import "./lead.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faL, fas } from "@fortawesome/free-solid-svg-icons";

import { Tab, Tabs, Form, Button } from "react-bootstrap";
import { Row, Col, Modal } from "react-bootstrap";
import Summary from "./Summary";
import PersonalContacts from "./PersonalContacts";
import Requirement from "./Requirement";
import Service from "./Service";
import ActionandDeposition from "./ActionandDeposition";
import History from "./History";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Lead = () => {
  const { setUserData, setUsertype, setSignin, usertype, getData, postData } =
    useContext(Context);
  const [submit, setSubmit] = useState(false);
  const handleSubmitClose = () => setSubmit(false);
  const handleSubmitShow = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 5000);
  };

  const [error, setError] = useState(false);
  const handleErrorClose = () => setError(false);
  const handleErrorShow = () => {
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 5000);
  };
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [changed, setChanged] = useState();
  const [key, setKey] = useState("tab1");
  const [show, setShowAdd] = useState(false);
  const [alllead, setAllLead] = useState([]);
  // Modal function
  const handleClose = () => {
    handleTabChange("tab2");
    setErrors({});
    setShowAdd(false);
  };
  const handleShow = () => {
    handleTabChange("tab2");
    setShowAdd(true);
  };

  const DashboardClicks = (id) => {
    handleClose();
    getAllLeads(id);
  };

  const Reload = async () => {
    Cookies.remove("lead_id");
    setFormStatus({ ...formStatus, lead_id: "" });
  };

  const LogOut = async () => {
    Cookies.remove("it_pulse_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  const [data, setData] = useState({});

  const getDataAll = async () => {
    const response = await getData("/masters/allleadstatusandcount");
    await setData(response?.data);
  };

  const [formData, setFormData] = useState({
    name: "",
    mobile_no: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [formStatus, setFormStatus] = useState({
    lead_id: "",
    one: true,
    two: true,
    three: true,
    four: true,
  });

  useEffect(() => {
    getDataAll();
  }, [submit]);

  const handleTabChange = (k) => {
    setKey(k);
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/lead_management/create-lead", formData);
      if (response?.success) {
        setFormData({ name: "", mobile_no: "", email: "" });
        handleSubmitShow();
        setErrors({});
        getAllLeads();
      } else {
        setErrors(response?.message?.errors);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const SaveCookie = (id) => {
    Cookies.set("lead_id", id, { expires: 2 });
    setFormStatus({
      ...formStatus,
      lead_id: id,
    });
    setChanged({ id: id });
  };

  useEffect(() => {
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      setFormStatus({ ...formStatus, lead_id: lead_id });
    }
  }, []);

  // Contact Validation
  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const getAllLeads = async (id = 1) => {
    const res = await getData(`/lead_management/create-lead/${id}`);
    setAllLead(res?.data);
  };

  useEffect(() => {
    getAllLeads();
  }, []);

  const SearchLeads = async () => {
    setShowAdd(false);
    setErrors({});
    const res = await postData(`/lead_management/search-lead`, formData);
    setAllLead(res?.data);
  };

  return (
    <>
      <section className="leads-dashboard-section">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-3">
              <div className="main-box">
                <div className="box-title-div">
                  <div className="row">
                    <div className="col-md-9 pe-0">
                      <div className="sec-1">
                        <p className="call-statusp">
                          Call Status:New Opportunity
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 ps-0">
                      <div className="sec-2">
                        <div className="d-flex justify-content-center">
                          <FontAwesomeIcon
                            className="fafa-set"
                            onClick={Reload}
                            icon="fa-solid fa-arrows-rotate"
                          />

                          <FontAwesomeIcon
                            className="fafa-set"
                            onClick={handleClose}
                            icon="fa-solid fa-magnifying-glass"
                          />

                          <FontAwesomeIcon
                            onClick={handleShow}
                            className="fafa-set"
                            icon="fa-solid fa-plus"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-content-div">
                  <div className="tabs-main-sec">
                    <Tabs
                      activeKey={key}
                      defaultActiveKey="leaddashboard"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      onSelect={handleTabChange}
                    >
                      <Tab eventKey="tab1" title="Dashboard">
                        <div className="tab-content">
                          <ul
                            className="nav nav-pills row justify-content-center"
                            id="pills-tab"
                            role="tablist"
                          >
                            {data?.data?.map((data, index) => (
                              <li
                                className="nav-item col-md-4"
                                role="presentation"
                                key={index}
                                onClick={() => {
                                  DashboardClicks(data?.id);
                                }}
                              >
                                <div
                                  className="nav-link"
                                  id="pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                >
                                  <h6 className="count-number">
                                    {" "}
                                    {data?.leadCount}
                                  </h6>
                                  <p className="list-name">{data?.name}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Tab>

                      <Tab eventKey="tab2" title="List">
                        <div className="tab-content">
                          <div className="search-form-section">
                            <Form className="">
                              <Form.Group
                                className="form-div"
                                controlId="formPlaintextEmail"
                              >
                                <Form.Control
                                  type="text"
                                  name="name"
                                  value={formData?.name}
                                  onChange={handleChange}
                                  placeholder="Name"
                                />
                                {errors ? (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {errors?.name?.msg}
                                  </p>
                                ) : null}
                              </Form.Group>

                              <Form.Group
                                className="form-div"
                                controlId="formPlaintextEmail"
                              >
                                <Form.Control
                                  onKeyPress={handleKeyPressContact}
                                  type="tel"
                                  pattern="[0-9]{10}"
                                  maxLength="10"
                                  name="mobile_no"
                                  value={formData?.mobile_no}
                                  placeholder="Contact"
                                  onChange={handleChange}
                                />
                                {errors ? (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {errors?.mobile_no?.msg}
                                  </p>
                                ) : null}
                              </Form.Group>

                              {show ? (
                                <Form.Group
                                  className="form-div"
                                  controlId="formPlaintextEmail"
                                >
                                  <Form.Control
                                    type="email"
                                    name="email"
                                    value={formData?.email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                  />
                                  {errors ? (
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "11px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {errors?.email?.msg}
                                    </p>
                                  ) : null}
                                </Form.Group>
                              ) : (
                                <></>
                              )}

                              <div className="form-div">
                                {show ? (
                                  <button
                                    type="button"
                                    onClick={handleSubmit}
                                    className="search-btn btn  me-2"
                                  >
                                    Add
                                  </button>
                                ) : (
                                  <></>
                                )}

                                <button
                                  type="button"
                                  className="search-btn btn"
                                  onClick={SearchLeads}
                                >
                                  Search
                                </button>
                              </div>
                            </Form>

                            <div className="search-inp-overflow">
                              {alllead?.map((data, index) => {
                                return (
                                  <div
                                    className="form-div"
                                    key={index}
                                    onClick={() => {
                                      SaveCookie(data?.id);
                                    }}
                                  >
                                    <div className="form-control">
                                      {data?.f_name}
                                    </div>
                                    <FontAwesomeIcon
                                      className="call-fafa"
                                      icon="fa-solid fa-square-phone "
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>

              <div className="main-box summary-top">
                <Summary
                  getAllLeads={getAllLeads}
                  alllead={alllead}
                  SaveCookie={SaveCookie}
                />
              </div>
            </div>

            <div className="col-lg-9">
              <div className="box-title-div second-div">
                <div className="row">
                  <div className="col-md-12 ps-0">
                    <div className="sec-2 ">
                      <div className="d-flex justify-content-end">
                        <FontAwesomeIcon
                          className="fafa-set three-fafa"
                          icon="fa-solid fa-lock"
                        />

                        <FontAwesomeIcon
                          className="fafa-set three-fafa"
                          icon="fa-solid fa-list"
                        />

                        <FontAwesomeIcon
                          onClick={LogOut}
                          className="fafa-set three-fafa"
                          icon="fa-solid fa-right-from-bracket"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <PersonalContacts
                  setFormStatus={setFormStatus}
                  formStatus={formStatus}
                  handleErrorShow={handleErrorShow}
                />

                <div className="col-lg-4">
                  <Requirement
                    setFormStatus={setFormStatus}
                    formStatus={formStatus}
                    lead_id={formStatus.lead_id}
                    handleErrorShow={handleErrorShow}
                  />
                  <Service
                    setFormStatus={setFormStatus}
                    formStatus={formStatus}
                    changed={changed}
                    lead_id={formStatus.lead_id}
                    handleErrorShow={handleErrorShow}
                  />
                </div>

                <div className="col-lg-4 last-cll-over">
                  <ActionandDeposition
                    setFormStatus={setFormStatus}
                    formStatus={formStatus}
                    lead_id={formStatus.lead_id}
                    handleErrorShow={handleErrorShow}
                    getDataAll={getDataAll}
                  />
                  <History
                    setFormStatus={setFormStatus}
                    formStatus={formStatus}
                    lead_id={formStatus.lead_id}
                    handleErrorShow={handleErrorShow}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section>
          <Modal
            show={submit}
            onHide={handleSubmitClose}
            backdrop="static"
            keyboard={false}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="addproduct-pop-section"
          >
            <Modal.Header closeButton>
              <Modal.Title>Lead Added</Modal.Title>
            </Modal.Header>
            <div className="box">
              <div className="success alert">
                <div className="alert-body">Success !</div>
              </div>
            </div>
          </Modal>
        </section>

        <section>
          <Modal
            show={error}
            onHide={handleErrorClose}
            backdrop="static"
            keyboard={false}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="addproduct-pop-section"
          >
            <Modal.Header closeButton>
              <Modal.Title>Please Lead Select</Modal.Title>
            </Modal.Header>
            <div className="box">
              <div className="failed alert">
                <div className="alert-body">Error !</div>
              </div>
            </div>
          </Modal>
        </section>
      </section>
    </>
  );
};

export default Lead;
