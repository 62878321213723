import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import './comsidebar.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation } from 'react-router-dom';

const ComSidebar = () => {
  const [activeKey, setActiveKey] = useState('0');
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/complaint-manage':
        setActiveKey('0');
        break;
      case '/complaint-list-table':
        setActiveKey('1');
        break;
      case '/complaint-report-table':
        setActiveKey('2');
        break;
      default:
        setActiveKey('0'); 
    }
  }, [location.pathname]);
  return (
    <>
      <div className="com-sidebar">
        <div className="logo-image-main">
          <img src={process.env.PUBLIC_URL + "assets/complaint-management/LOGO_1.png"} className="logo-img" alt="..." />
        </div>
        <div className="brown-border"></div>
        <Row>
          <Col xl={12}>
            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
              <Link to='/complaint-manage'>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <FontAwesomeIcon icon="fa-solid fa-house" className="me-lg-4 me-md-1" />
                    Dashboard
                  </Accordion.Header>
                </Accordion.Item>
              </Link>
              <Link to='/complaint-list-table'>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <FontAwesomeIcon icon="fa-solid fa-file-pen" className="me-lg-4 me-md-1" /> Complaints
                  </Accordion.Header>
                </Accordion.Item>
              </Link>
              <Link to='/complaint-report-table'>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <FontAwesomeIcon icon="fa-solid fa-file-invoice" className="me-lg-4 me-md-1" /> Reports
                  </Accordion.Header>
                </Accordion.Item>
              </Link>

              <Accordion.Body>
                {/* Your body content */}
              </Accordion.Body>
            </Accordion>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ComSidebar;
