import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import HrmsHeader from "../Hrms-Header/HrmsHeader";
import Hsidebar from "../Hrms-Sidebar/Hsidebar";
import Hroutes from "../Hrms-Routes/Hroutes";
import "../CommonCss/CommonCss.css";
import { Context } from "../../../utils/context";
import PageNotFound from "../../PageNotFound/PageNotFound";
import { IDS } from "../../../utils/common";
import LeaveType from "../Hrms-Setup-Content/LeaveType/LeaveType";

const Hmain_Routes = () => {
  const { isAllow } = useContext(Context);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);
  useEffect(() => {
    setHeaderText(location.pathname);
  }, []);

  return (
    <section className="supermain-structure">
      <div className="container-fluid p-0">
        <div>
          <HrmsHeader />
        </div>
        <div className="main-class-content-hrms d-flex">
          <div className="sidebarmaindiv-hrms">
            <Hsidebar />
          </div>
          <div className="contentmaindiv-hrms ps-0 pe-0">
            <Hroutes />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hmain_Routes;
