import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import '../../../CommonCss/ModalCommonCss.css';
import FileUploadModal from '../../Payment-Slip/PaymentSlipModal/FileUploadModal';
import { Context } from "../../../../../utils/context";
import { useForm } from "react-hook-form";
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";

const UploadModal = (props) => {

    const { postData } =
        useContext(Context);

    const { register, handleSubmit, reset, setValue, control, watch, formState: { errors }, getValues } = useForm();

    const [fileSelected, setFileSelected] = useState(false);
    const [showLottie, setShowLottie] = useState({ show: false, count: 0 });
    const [showLottie1, setShowLottie1] = useState({ show: false, msg: "", success: false });
    const [loading, setLoading] = useState(false); // State for loading

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            formData.append("file", data.file[0]);
            const response = await postData(
                "/hrms-masters/attendance/bulkupload",
                formData,
                {}
            );
            console.log('response', response?.data);
            if (response.success) {
                reset()
                setShowLottie({ show: true, count: response?.data?.LeadAdded });
                setTimeout(() => {
                    setShowLottie({ show: false, count: 0 })
                    props?.onHide();
                }, 3000);

            } else {
                setShowLottie1({ show: true, msg: "oop's Something Went Wrong ", success: response?.success });
            }
            setTimeout(() => {
                setShowLottie1({ show: false, msg: "", success: false });
                props.onHide();
            }, 3000);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    }

    console.log("getValues", getValues());



    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='below-bodyy'>
                            <div className="upload-div text-center mt-4">
                                <input
                                    type="file"
                                    name="file"
                                    {...register("file", {
                                        required: "File is  Required",
                                    })}
                                    id="file-upload"
                                    style={{ display: "none" }}

                                />
                                <label htmlFor="file-upload">
                                    {watch("file") && getValues("file")[0] ? (
                                        <div>
                                            {getValues("file")[0].name}
                                        </div>
                                    ) : (
                                        <div className="custom-upload-button">
                                            +
                                        </div>
                                    )}
                                </label>
                            </div>
                            {errors.file && (
                                <span className="text-danger">
                                    {errors.file.message}
                                </span>
                            )}
                            <div className='text-center mt-5 mb-5'>
                                <button className='cancel-button me-2' type="button" onClick={() => {
                                    props.onHide();
                                    reset();
                                }}>
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className={'active-upload-button'}
                                    disabled={loading}
                                >
                                    {loading ? "Uploading..." : "Upload Sheet"}
                                </button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <FileUploadModal show={showLottie?.show} count={showLottie?.count} onHide={() => setShowLottie({ show: false, count: 0 })} />
            <AddedLottieModal
                pageTitle="Attendance"
                message={showLottie1.msg}
                show={showLottie1.show}
                success={showLottie1.success}
                onHide={() => setShowLottie1({ show: false, msg: "", success: false })}
            />
        </section>
    );
};

// Define PropTypes for better type checking
UploadModal.propTypes = {
    setFile: PropTypes.func.isRequired,
    handleBukUpload: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
};

export default UploadModal;
