import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Select from "react-select";
import "../AddEmployee.css";
import { Col, Form, Row } from "react-bootstrap";

const options = [
  { value: "demo", label: "demo" },
  { value: "demo", label: "demo" },
  { value: "demo", label: "demo" },
];

const EducationalDetails = ({
  getValues,
  register,
  classNames,
  errors,
  watch,
  removeEducation,
  appendEducation,
  educationFields,
  qualifications,
  universities,
  courses,
  Controller,
  control,
  disabled,
  setModalQualification,
  setModalUniversity,
  setModalCourse,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <div>
      <div className="below-formmmmm-education">
        {educationFields?.map((edu, index) => (
          <Row key={edu.id}>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Form.Group className="mb-4" controlId={`companyName-${index}`}>
                <Form.Label>Qualification</Form.Label>
                <div className="d-flex justify-content-between select-add-div">
                  <Controller
                    className="select-contoller"
                    name={`educations.${index}.qualification_id`}
                    {...register(`educations.${index}.qualification_id`, {
                      required: "Select Qualification",
                    })}
                    control={control}
                    rules={{
                      required: "Select Qualification",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors?.educations?.[index]
                              ?.qualification_id
                              ? "red"
                              : baseStyles.borderColor,
                          }),
                        }}
                        // {...field}
                        options={qualifications}
                        onChange={(selectedValue) => {
                          onChange(selectedValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        isDisabled={disabled}
                      />
                    )}
                  />
                  {!disabled && (
                    <button
                      className="add-button-with-select"
                      type="button"
                      width="10"
                      onClick={() => setModalQualification(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} width="10" />
                    </button>
                  )}
                </div>
                {errors?.educations?.[index]?.qualification_id && (
                  <span className="text-danger">
                    {errors?.educations?.[index]?.qualification_id.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Form.Group className="mb-4" controlId={`address-${index}`}>
                <Form.Label>University/Board</Form.Label>
                <div className="d-flex justify-content-between select-add-div">
                  <Controller
                    name={`educations.${index}.university_id`}
                    {...register(`educations.${index}.university_id`, {
                      required: "Select University",
                    })}
                    control={control}
                    rules={{
                      required: "Select University",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors?.educations?.[index]
                              ?.university_id
                              ? "red"
                              : baseStyles.borderColor,
                          }),
                        }}
                        // {...field}
                        options={universities}
                        onChange={(selectedValue) => {
                          onChange(selectedValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        isDisabled={disabled}
                      />
                    )}
                  />
                  {!disabled && (
                    <button
                      className="add-button-with-select"
                      type="button"
                      width="10"
                      onClick={() => setModalUniversity(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} width="10" />
                    </button>
                  )}
                </div>
                {errors?.educations?.[index]?.university_id && (
                  <span className="text-danger">
                    {errors?.educations?.[index]?.university_id.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Form.Group className="mb-4" controlId={`employeeId-${index}`}>
                <Form.Label>Course</Form.Label>

                <div className="d-flex justify-content-between select-add-div">
                  <Controller
                    name={`educations.${index}.course_id`}
                    {...register(`educations.${index}.course_id`, {
                      required: "Select Course",
                    })}
                    control={control}
                    rules={{
                      required: "Select Course",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors?.educations?.[index]?.course_id
                              ? "red"
                              : baseStyles.borderColor,
                          }),
                        }}
                        // {...field}
                        options={courses}
                        onChange={(selectedValue) => {
                          onChange(selectedValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                        isDisabled={disabled}
                      />
                    )}
                  />
                  {!disabled && (
                    <button
                      className="add-button-with-select"
                      type="button"
                      width="10"
                      onClick={() => setModalCourse(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} width="10" />
                    </button>
                  )}
                </div>
                {errors?.educations?.[index]?.course_id && (
                  <span className="text-danger">
                    {errors?.educations?.[index]?.course_id.message}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Form.Group className="mb-4" controlId={`previouses-${index}`}>
                <Form.Label>Year Of Passing</Form.Label>
                <Form.Control
                  disabled={disabled}
                  type="text"
                  name={`educations.${index}.passing_year`}
                  placeholder="Passing Year"
                  className={classNames("", {
                    "is-invalid": errors?.educations?.[index]?.passing_year, // Adjusted error checking
                  })}
                  {...register(`educations.${index}.passing_year`, {
                    required: "Year is required",
                    pattern: {
                      value: /^(19|20)\d{2}$/, // Pattern for four-digit years between 1900 and 2099
                      message: "Please enter a valid year (e.g., 2023).",
                    },
                    validate: (value) => {
                      const year = parseInt(value, 10);
                      return (
                        year <= new Date().getFullYear() ||
                        "Year cannot be in the future."
                      ); // Custom validation for current year
                    },
                  })}
                  onKeyDown={(e) => {
                    const { key } = e;
                    const currentLength = e.currentTarget.value.length;
                    if (
                      currentLength >= 4 &&
                      key !== "Backspace" &&
                      key !== "Tab"
                    ) {
                      e.preventDefault();
                    }

                    if (
                      !/[0-9]/.test(key) &&
                      key !== "Backspace" &&
                      key !== "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors?.educations?.[index]?.passing_year && (
                  <div className="invalid-feedback">
                    {errors.educations[index].passing_year.message}{" "}
                    {/* Display the error message */}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12} xs={12}>
              <Form.Group className="mb-4" controlId={`department-${index}`}>
                <Form.Label>Percentage</Form.Label>

                <Form.Control
                  disabled={disabled}
                  type="text"
                  name={`educations.${index}.percentage`}
                  placeholder="Percentage"
                  className={classNames("", {
                    "is-invalid": errors?.educations?.[index]?.percentage, // Adjusted error checking
                  })}
                  {...register(`educations.${index}.percentage`, {
                    required: "Percentage is required",
                    pattern: {
                      value: /^(100(\.0+)?|[1-9]?[0-9](\.[0-9]+)?)$/, // Regex for valid percentages (0.0-100.0)
                      message: "Please enter a valid percentage (0.0 - 100.0).",
                    },
                    validate: (value) => {
                      const percentage = parseFloat(value);
                      return (
                        (percentage >= 0 && percentage <= 100) ||
                        "Percentage must be between 0 and 100."
                      ); // Custom validation for range
                    },
                  })}
                  onKeyDown={(e) => {
                    // Allow only numeric input, Backspace, and decimal point
                    if (
                      !/[0-9]/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors?.educations?.[index]?.percentage && (
                  <div className="invalid-feedback">
                    {errors.educations[index].percentage.message}{" "}
                    {/* Display the error message */}
                  </div>
                )}
              </Form.Group>
            </Col>
            {index !== 0 && !disabled && (
              <div className="text-end mt-3">
                <button
                  className="add-button"
                  type="button"
                  onClick={() => removeEducation(index)}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
              </div>
            )}
            <hr className="mt-4" />
          </Row>
        ))}

        {!disabled && (
          <div className="text-end">
            <button
              className="add-button"
              type="button"
              onClick={() => appendEducation({})}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationalDetails;

{
  /* <Table striped bordered responsive>
                    <thead>
                        <tr>
                            <th>Qualification</th>
                            <th>University/Board</th>
                            <th>Course</th>
                            <th>Year Of Passing</th>
                            <th>Percentage</th>
                        </tr>
                    </thead>
                    <tbody className="table-body-holder">
                        {educationFields?.map((edu, index) =>
                            <tr key={edu.id}>
                                <td>
                                    <div className='d-flex'>
                                        <Controller
                                            name={`educations.${index}.qualification_id`}
                                            {...register(`educations.${index}.qualification_id`, {
                                                required: "Select Qualification",
                                            })}
                                            control={control}
                                            rules={{
                                                required: "Select Qualification",
                                            }}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors?.educations?.[index]?.qualification_id ? "red" : baseStyles.borderColor,
                                                        }),
                                                    }}
                                                  
                                                    options={qualifications}
                                                    onChange={(selectedValue) => {
                                                        onChange(selectedValue);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    ref={ref}
                                                    isDisabled={disabled}
                                                />
                                            )}
                                        />
                                        {!disabled &&
                                            <button className='add-button-with-select' type='button' width="10"
                                                onClick={() => setModalQualification(true)}
                                            ><FontAwesomeIcon icon={faPlus} width="10" /></button>
                                        }
                                    </div>
                                    {errors?.educations?.[index]?.qualification_id && (
                                        <span className="text-danger">
                                            {errors?.educations?.[index]?.qualification_id.message}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    <div className='d-flex'>
                                        <Controller
                                            name={`educations.${index}.university_id`}
                                            {...register(`educations.${index}.university_id`, {
                                                required: "Select University",
                                            })}
                                            control={control}
                                            rules={{
                                                required: "Select University",
                                            }}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors?.educations?.[index]?.university_id ? "red" : baseStyles.borderColor,
                                                        }),
                                                    }}
                                                  
                                                    options={universities}
                                                    onChange={(selectedValue) => {
                                                        onChange(selectedValue);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    ref={ref}
                                                    isDisabled={disabled}
                                                />
                                            )}
                                        />
                                        {!disabled &&
                                            <button className='add-button-with-select' type='button' width="10"
                                                onClick={() => setModalUniversity(true)}
                                            ><FontAwesomeIcon icon={faPlus} width="10" /></button>
                                        }
                                    </div>
                                    {errors?.educations?.[index]?.university_id && (
                                        <span className="text-danger">
                                            {errors?.educations?.[index]?.university_id.message}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    <div className='d-flex'>
                                        <Controller
                                            name={`educations.${index}.course_id`}
                                            {...register(`educations.${index}.course_id`, {
                                                required: "Select Course",
                                            })}
                                            control={control}
                                            rules={{
                                                required: "Select Course",
                                            }}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors?.educations?.[index]?.course_id ? "red" : baseStyles.borderColor,
                                                        }),
                                                    }}
                                                    
                                                    options={courses}
                                                    onChange={(selectedValue) => {
                                                        onChange(selectedValue);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    ref={ref}
                                                    isDisabled={disabled}
                                                />
                                            )}
                                        />
                                        {!disabled &&
                                            <button className='add-button-with-select' type='button' width="10"
                                                onClick={() => setModalCourse(true)}
                                            ><FontAwesomeIcon icon={faPlus} width="10" /></button>
                                        }
                                    </div>
                                    {errors?.educations?.[index]?.course_id && (
                                        <span className="text-danger">
                                            {errors?.educations?.[index]?.course_id.message}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`educations.${index}.passing_year`}
                                        placeholder="Passing Year"
                                        className={classNames("", {
                                            "is-invalid": errors?.educations?.[index]?.passing_year, // Adjusted error checking
                                        })}
                                        {...register(`educations.${index}.passing_year`, {
                                            required: "Year is required",
                                            pattern: {
                                                value: /^(19|20)\d{2}$/, // Pattern for four-digit years between 1900 and 2099
                                                message: "Please enter a valid year (e.g., 2023).",
                                            },
                                            validate: (value) => {
                                                const year = parseInt(value, 10);
                                                return year <= new Date().getFullYear() || "Year cannot be in the future."; // Custom validation for current year
                                            },
                                        })}
                                        onKeyDown={(e) => {
                                            const { key } = e;
                                            const currentLength = e.currentTarget.value.length;
                                            if (currentLength >= 4 && key !== "Backspace" && key !== "Tab") {
                                                e.preventDefault();
                                            }

                                            if (!/[0-9]/.test(key) && key !== "Backspace" && key !== ".") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors?.educations?.[index]?.passing_year && (
                                        <div className="invalid-feedback">
                                            {errors.educations[index].passing_year.message} 
                                        </div>
                                    )}
                                </td>
                                <td>
                                    <Form.Control
                                        disabled={disabled}
                                        type="text"
                                        name={`educations.${index}.percentage`}
                                        placeholder="Percentage"
                                        className={classNames("", {
                                            "is-invalid": errors?.educations?.[index]?.percentage, 
                                        })}
                                        {...register(`educations.${index}.percentage`, {
                                            required: "Percentage is required",
                                            pattern: {
                                                value: /^(100(\.0+)?|[1-9]?[0-9](\.[0-9]+)?)$/, 
                                                message: "Please enter a valid percentage (0.0 - 100.0).",
                                            },
                                            validate: (value) => {
                                                const percentage = parseFloat(value);
                                                return percentage >= 0 && percentage <= 100 || "Percentage must be between 0 and 100."; 
                                            },
                                        })}
                                        onKeyDown={(e) => {
                                          
                                            if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== ".") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors?.educations?.[index]?.percentage && (
                                        <div className="invalid-feedback">
                                            {errors.educations[index].percentage.message} 
                                        </div>
                                    )}
                                </td>
                                
                                {index !== 0 && !disabled &&
                                    < button className='add-button' type='button' onClick={() => removeEducation(index)} ><FontAwesomeIcon icon={faMinus} /></button>
                                }

                            </tr>
                        )}
                    </tbody>
                </Table> */
}
