import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function AddTaskModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Task</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="">Employee</Form.Label>

                    <Form.Control
                      type="text"
                      name="Hitesh Kulkarni"
                      placeholder="Hitesh Kulkarni"
                    />
                    <Form.Control type="hidden" name="Hitesh Kulkarni" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="">Hourly Rate</Form.Label>
                    <Form.Control type="text" name="" placeholder="0.00" />
                    <Form.Control type="hidden" name="Hourly_Rate" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <Form>
            <Button className="btn btn-success save-btn my-2" type="submit">
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTaskModal;
