import React, { useContext, useState, useEffect } from "react";
import { Form, Modal } from 'react-bootstrap'
import '../../../CommonCss/ModalCommonCss.css'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditLottieModal from '../../../commonLottie/EditLottieModal'
import { Context } from "../../../../../utils/context";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from 'react-select';
import AddedLottieModal from "../../../commonLottie/AddedLottieModal";


const AddModal = (props) => {

    const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });
    const { postData, getData, Select2Data } = useContext(Context);
    const { register, handleSubmit, reset, setValue, control, watch, formState: { errors }, getValues } = useForm();
    const [departments, setDepartments] = useState([]);
    const [users, setUsers] = useState([]);
    const [assets, setAssets] = useState([]);

    const onSubmit = async (data) => {
        console.log("data:----", data);

        try {
            const sendData = new FormData();
            sendData.append("department_id", data?.department_id?.value);
            sendData.append("employee_id", data?.employee_id?.value);
            sendData.append("shift_id", data?.shift_id?.value);

            const response = await postData(`/hrms/assign-shift`, sendData);
            if (response?.success) {
                setShowLottie({ show: true, msg: response?.message, success: response?.success });
                reset();
            } else {
                setShowLottie({ show: true, msg: response?.message, success: response?.success });
            }
            setTimeout(() => {
                setShowLottie({ show: false, msg: "", success: false });
                props?.onHide();
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    };

    const getDepartments = async () => {
        const response = await getData("/masters/alldepartments");
        if (response?.success) {
            setDepartments(await Select2Data(response?.data, "leave_type_id"));
        }
    };

    const getEmployees = async (id) => {
        const response = await getData(`/masters/allemployeebydept/${id}`);
        if (response?.success) {
            setUsers(await Select2Data(response?.data, "employee_id"));
        }
    };

    const getShifts = async () => {
        const response = await getData("/hrms-masters/all-shifts");
        if (response?.success) {
            setAssets(await Select2Data(response?.data, "shift_id"));
        }
    };

    useEffect(() => {
        getDepartments();
        getShifts();
    }, []);

    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='text-center'>
                        <h4 className='modal-title-common'>Assign Shift</h4>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='below-bodyy'>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Department</Form.Label>
                                    <Controller
                                        name="department_id"
                                        {...register("department_id", {
                                            required: "Select Department",
                                        })}
                                        control={control}
                                        rules={{
                                            required: "Select Department",
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors?.department_id ? "red" : baseStyles.borderColor,
                                                    }),
                                                }}
                                                options={departments}
                                                onChange={(item) => {
                                                    setValue("department_id", item);
                                                    getEmployees(item?.value);
                                                    setValue("employee_id", "")
                                                }}

                                            />
                                        )}
                                    />
                                    {errors?.department_id && (
                                        <span className="text-danger">
                                            {errors?.department_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Employee</Form.Label>
                                    <Controller
                                        name="employee_id" // name of the field
                                        {...register("employee_id", {
                                            required: "Select Employee",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.employee_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={users}
                                            />
                                        )}
                                    />

                                    {errors?.employee_id && (
                                        <span className="text-danger">
                                            {errors?.employee_id.message}
                                        </span>
                                    )}
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group className="mb-3" controlId="assignedAssets">
                                    <Form.Label>Assigned Shift</Form.Label>
                                    <Controller
                                        name="shift_id" // name of the field
                                        {...register("shift_id", {
                                            required: "Select Assets",
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors.shift_id
                                                            ? "red"
                                                            : baseStyles,
                                                    }),
                                                }}
                                                {...field}
                                                options={assets}
                                            />
                                        )}
                                    />

                                    {errors?.shift_id && (
                                        <span className="text-danger">
                                            {errors?.shift_id.message}
                                        </span>
                                    )}


                                    {/* <div className="text-end">
                                        <label className="assign-more mt-1">Assign More Assets <FontAwesomeIcon className='ms-3' icon={faCirclePlus} /></label>
                                    </div> */}
                                </Form.Group>
                            </div>

                            <div className='text-center mt-5 mb-5'>
                                <button className='cancel-button me-2' type="button" onClick={() => { props.onHide(); reset() }}>Cancel</button>
                                <button className='save-button' type="submit">Save</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <AddedLottieModal
                pageTitle="Designation"
                message={showLottie.msg}
                show={showLottie.show}
                success={showLottie.success}
                onHide={() => setShowLottie({ show: false, msg: "", success: false })}
            />

        </section >
    )
}

export default AddModal