import React, { useState, useContext } from "react";
import "./AddTaskLabelModal.css";
import { Context } from "../../../../../../utils/context";
import { Form, InputGroup } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { SketchPicker } from "react-color";
library.add(fas);

function AddTaskLabelModal(props) {
  const [color, setColor] = useState("#FFFFFF"); // Initialize color state
  const [showColorPicker, setShowColorPicker] = useState(false); // State to manage color picker visibility

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  // const handleChange = (newColor) => {
  //   setColor(newColor.hex);
  // };

  const handleSuggestedColorClick = (newColor) => {
    setColor(newColor);
  };


  const { postData } = useContext(Context);

  const [formData, setFormData] = useState({
    name: "",
  });

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const handleCloseModal = () => {
    props.onHide(); // Call onHide prop passed from parent component
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await postData("/projectmanagement/task_label", formData);
      if (response?.success) {
        setShowModal({ code: response?.code, message: response?.message });
        setTimeout(() => {
          setShowModal(0);
          handleCloseModal();
        }, 1000);
      } else {
        setShowModal({ code: response?.code, message: response?.message });
        setTimeout(() => {
          setShowModal(0);
          handleCloseModal();
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }

  };


  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Task Label
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="Label_Name">
                    <Form.Label className="required">Label Name</Form.Label>

                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Label Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <Form.Control type="hidden" name="Label_Name" />
                  </Form.Group>
                </Form>
              </div>

              {/* <div className="col-md-12">
                <Form>
                  <Form.Group controlId="Description">
                    <Form.Label className="">Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="Description"
                      placeholder="Description"
                    />
                    <Form.Control type="hidden" name="Description" />
                  </Form.Group>
                </Form>
              </div> */}
            </div>
          </div>

          {/* <div className="field_top_space">
            <div className="row">
              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Color">
                    <Form.Label className="required">Color</Form.Label>
                    <div className="asColorPicker-wrap">
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          className="colorpicker form-control asColorPicker-input"
                          name="label_color"
                          value={color}
                          readOnly 
                          onClick={toggleColorPicker} 
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          style={{ background: color }}
                        ></InputGroup.Text>
                      </InputGroup>
                      {showColorPicker && (
                        <SketchPicker color={color} onChange={handleChange} />
                      )}{" "}
                      
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>

            <div class="row">
              <Form.Label className="">
                Choose any color. Or you can choose one of the suggested colors
                below.
              </Form.Label>
              {/* <div class="col-md-12">
                <div className="suggest-colors">
                  <span
                    style={{ backgroundColor: "#0033CC" }}
                    data-color="#0033CC"
                    onClick={() => handleSuggestedColorClick("#0033CC")} // Update color state on click
                  >
                    &nbsp;
                  </span>
                  <span
                    style={{ backgroundColor: "#428BCA" }}
                    data-color="#428BCA"
                    onClick={() => handleSuggestedColorClick("#428BCA")} // Update color state on click
                  >
                    &nbsp;
                  </span>
                </div>
              </div> 
            </div>
          </div> */}

          <Form>
            <Button className="btn btn-success save-btn my-2"
              name={"save"}
              onClick={handleSubmit}
              type="submit">
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddTaskLabelModal;
