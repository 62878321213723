import React, { useState } from "react";
import "../../CommonCss/CommonCss.css";
import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
  faEye,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentSlipModal from "./PaymentSlipModal/PaymentSlipModal";
import PaymentSlipDeleteModal from "./PaymentSlipModal/PaymentSlipDeleteModal";
import { Link } from "react-router-dom";

const PaymentSlip = () => {
  const [addmodalShow, setAddModalShow] = useState(false);
  const [PaymentSlipModalShow, setPaymentSlipModalShow] = useState(false);
  const [paymentSlipDeleteModalShow, setPaymentSlipDeleteModalShow] =
    useState(false);

  const handleDownloadClick = () => {
    setPaymentSlipDeleteModalShow(true); // Show the modal

    // Hide modal after 2 seconds
    setTimeout(() => {
      setPaymentSlipDeleteModalShow(false);
    }, 3000);
  };

  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <Row>
          <Col xxl={8} xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className="main-table-head">Payment Slip</p>
          </Col>
          <Col xxl={4} xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className="add d-md-flex justify-content-lg-end">
              <Dropdown>
                <Dropdown.Toggle
                  className="custom-apply-leaves-btn"
                  id="dropdown-basic"
                >
                  Column Selection
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-0">
                    {" "}
                    <li>
                      <Link className="dropdown-item">Sr.No </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-1">
                    {" "}
                    <li>
                      <Link className="dropdown-item">Employee Id </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    {" "}
                    <li>
                      <Link className="dropdown-item">Name </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    {" "}
                    <li>
                      <Link className="dropdown-item">Designation </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-4">
                    {" "}
                    <li>
                      <Link className="dropdown-item">Employee Type </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-5">
                    {" "}
                    <li>
                      <Link className="dropdown-item">Total Salary </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-6">
                    {" "}
                    <li>
                      <Link className="dropdown-item">Action </Link>
                    </li>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <div className="grey-bggg">
          <Row>
            <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={12}>
              <div class="show-entries mt-2">
                <label for="entries">Show</label>
                <select id="entries" name="entries">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <label for="entries" className="ms-2">
                  Entries
                </label>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="text-lg-end">
                <p className="text-end showing-txttt mt-2">
                  Showing 1 to 5 Of 5 Entries
                </p>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12}>
              <div className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search Here"
                    aria-label="Search Here"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </div>
            </Col>
            <Col xxl={3} xl={6} lg={6} md={12} sm={6} xs={12}>
              <div className="">
                <button className="btn reset-btnn">Reset</button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="common-table-hrms">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>Employee Id</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Employee Type</th>
                <th>Total Salary</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              <tr>
                <td>01.</td>
                <td>0012</td>
                <td>Komal Nathe</td>
                <td>Frontend Devloper</td>
                <td>Intern</td>
                <td>₹ 19,200/-</td>
                <td>
                  <div>
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => setPaymentSlipModalShow(true)}
                      className="table-iconnnn "
                    />
                    <FontAwesomeIcon
                      icon={faDownload}
                      onClick={handleDownloadClick}
                      className="table-iconnnn ms-2"
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <td>01.</td>
                <td>0012</td>
                <td>Komal Nathe</td>
                <td>Frontend Devloper</td>
                <td>Intern</td>
                <td>₹ 19,200/-</td>
                <td>
                  <div>
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => setPaymentSlipModalShow(true)}
                      className="table-iconnnn "
                    />
                    <FontAwesomeIcon
                      icon={faDownload}
                      onClick={handleDownloadClick}
                      className="table-iconnnn ms-2"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>01.</td>
                <td>0012</td>
                <td>Komal Nathe</td>
                <td>Frontend Devloper</td>
                <td>Intern</td>
                <td>₹ 19,200/-</td>
                <td>
                  <div>
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => setPaymentSlipModalShow(true)}
                      className="table-iconnnn "
                    />
                    <FontAwesomeIcon
                      icon={faDownload}
                      onClick={handleDownloadClick}
                      className="table-iconnnn ms-2"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          <PaymentSlipModal
            show={PaymentSlipModalShow}
            onHide={() => setPaymentSlipModalShow(false)}
          />
          <PaymentSlipDeleteModal
            show={paymentSlipDeleteModalShow}
            onHide={() => setPaymentSlipDeleteModalShow(false)}
          />
        </div>
      </div>
    </section>
  );
};

export default PaymentSlip;
