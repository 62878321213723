import React from "react";
import { Form } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function TaxModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Tax</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Tax Name</th>
                <th>Rate %</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              <tr id="cat-1">
                <td>1</td>
                <td>xyz</td>
                <td>0.00</td>
              </tr>
            </tbody>
          </Table>

          <div className="row  mt-5">
            <div className="col-md-6">
              <Form>
                <Form.Group controlId="Tax_Name">
                  <Form.Label>Tax Name</Form.Label>
                  <Form.Control type="text" placeholder="Tax Name" />
                </Form.Group>
              </Form>
            </div>

            <div className="col-md-6">
              <Form>
                <Form.Group controlId="Rate">
                  <Form.Label>Rate %</Form.Label>
                  <Form.Control type="text" placeholder="Rate" />
                </Form.Group>
              </Form>
            </div>
          </div>

          <Form>
            <Button className="btn btn-success save-btn mt-5" type="submit">
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TaxModal;
