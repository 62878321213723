import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import SuccessTransferModal from "./SuccessTransferModal";
import { useForm, Controller } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import Select from "react-select";
const CompalintTransferModal = (props) => {
  const [modalTransfer, setModalTransfer] = useState(false);

  const { getData, postData, Select2Data } = useContext(Context);

  const [complaint_status, setcomplaint_status] = useState([]);
  const [service_engineer, setservice_engineer] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    reset({ ids: props?.ids });
  }, [props?.ids]);

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const response = await postData(
        `/complaint-management/complaints/complaint-transfer`,
        data
      );
      // console.log(response);
      if (response?.success) {
        await setModalTransfer(response?.success);
      }
      reset();
      setTimeout(() => {
        setModalTransfer(0);
        props.onHide();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllData = async () => {
    {
      const res = await getData(`/masters/all-complaint-status`);
      setcomplaint_status(await Select2Data(res.data, "complaint_status_id"));
    }
    {
      const res = await getData(`/masters/all-service-engineers`);
      setservice_engineer(res.data);
    }
  };

  useEffect(() => {
    getAllData();
    reset();
  }, [props.show]);

  return (
    <>
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-class-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h6 className="mt-2">Complaint Transfer</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Transfer to</Form.Label>
                  <Controller
                    name="service_engineer_id" // name of the field
                    {...register("service_engineer_id", {
                      required: "service engineer is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.service_engineer_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={service_engineer}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Status</Form.Label>
                  <Controller
                    name="complaint_status_id" // name of the field
                    {...register("complaint_status_id", {
                      required: "complaint status is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.complaint_status_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={
                          props.show === true
                            ? [{ value: "1", label: "New" }]
                            : complaint_status
                        }
                      />
                    )}
                  />
                  {/* <Form.Select aria-label="Default select example">
                    <option>Hold</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select> */}
                </Form.Group>
              </Col>
            </Row>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className={classNames("text-holder-area", {
                  "is-invalid": errors?.remark,
                })}
                {...register("remark", {
                  required: "remark is required",
                })}
                placeholder="Add remark here..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            // onClick={() => {
            //   setModalTransfer(true);
            //   props.onHide();
            // }}
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Transfer
          </Button>
        </Modal.Footer>
      </Modal>
      <SuccessTransferModal
        show={modalTransfer}
        onHide={() => setModalTransfer(false)}
      />
    </>
  );
};

export default CompalintTransferModal;
