import React from "react";
import Modal from "react-bootstrap/Modal";

const SucessfullyStatusModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-success-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="success-button">
            <p>Status Changed Successfully</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SucessfullyStatusModal;
