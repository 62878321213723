import React from "react";
import ComplaintListTable from "./compliant-list-table/ComplaintListTable";

const ComplaintList = () => {
  return (
    <>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <ComplaintListTable />
      </div>
    </>
  );
};

export default ComplaintList;
