import React, { useContext, useState, useEffect } from "react";
import "./HrmsProfile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import AddedLottieModal from "../commonLottie/AddedLottieModal";
import HrmsLogout from "./HrmsLogout";

const HrmsProfile = () => {

  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();
  const {
    postData,
    signin,
    setSignin,
    usertype,
    setUsertype,
    IMG_URL,
    setUserData,
    userdata,
    getData,
  } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const LogOut = async () => {
    Cookies.remove("it_pulse_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };



  const [showLottie, setShowLottie] = useState({
    show: false,
    msg: "",
    success: false,
  });

  const [data, setData] = useState({});
  const GetEditData = async () => {
    const response = await getData(`/hrms/profile/`);
    setData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  console.log("userdata:---", userdata);

  return (
    <>
      <section className="main-common-class-hrms">
        <div className="white-bggg">
          <p className="main-table-head">
            <FontAwesomeIcon
              onClick={() =>
                navigate("/hrms-admin/hrms-dashboard", { replace: true })
              }
              icon={faArrowLeft}
              className="arrow-iconnn me-3"
            />
            Profile
          </p>
          <Row className="d-flex justify-content-center mt-5">
            <Col xxl={7} xl={9} lg={10} md={12} sm={12} xs={12}>
              <div className="cream-bg">
                <div className="text-center">
                  {/* <FontAwesomeIcon icon={faCircleUser} className="user-iconnn" /> */}
                  <img
                    style={{
                      borderRadius: "100px",
                      height: "100px",
                      border: "3px solid #F42B2D",
                      backgroundImage:
                        "radial-gradient(circle at center, rgba(255, 0, 127, 1), rgba(255, 0, 127, 0) 70%), radial-gradient(circle at center, rgba(0, 128, 255, 1), rgba(0, 128, 255, 0) 70%), radial-gradient(circle at center, rgba(255, 204, 0, 1), rgba(255, 204, 0, 0) 70%)",
                      padding: "6px", // Space between the image and the border
                    }}
                    src={IMG_URL + data?.image}
                    alt="User Avatar"
                  />
                </div>
                <div className="mt-4">
                  <Row>
                    <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                      <p className="user-title">Name</p>
                    </Col>
                    <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                      <p className="user-subtitle">{data?.name}</p>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Employee ID</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">00012</p>
                  </Col>
                </Row> */}
                  <Row>
                    <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                      <p className="user-title">Email Id</p>
                    </Col>
                    <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                      <p className="user-subtitle">{data?.email}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                      <p className="user-title">Mobile No</p>
                    </Col>
                    <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                      <p className="user-subtitle">+91 {data?.mobile_number}</p>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Designation</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">Hr</p>
                  </Col>
                </Row> */}
                  <Row>
                    <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                      <p className="user-title">Department</p>
                    </Col>
                    <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                      <p className="user-subtitle">
                        {data?.department_id?.label}
                      </p>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col xl={5} lg={5} md={5} sm={5} xs={12}>
                    <p className="user-title">Joining date</p>
                  </Col>
                  <Col xl={7} lg={7} md={7} sm={7} xs={12}>
                    <p className="user-subtitle">12/10/2018</p>
                  </Col>
                </Row> */}
                </div>
              </div>
              <div className="text-center mt-3">
                <button type="button"
                  // onClick={LogOut}
                  onClick={() => setModalShow(true)}
                  className="logout-btnnnn">
                  Logout
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <HrmsLogout
        show={modalShow}
        onHide={() => setModalShow(false)}
        LogOut={LogOut}
      />
    </>
  );
};

export default HrmsProfile;
