import "../../CommonCss/CommonCss.css";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import {
  faArrowUpWideShort,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddModal from "./DepartmentModal/AddModal";
import EditModal from "./DepartmentModal/EditModal";
import DeleteModal from "../../../common/DeleteModal";
import Dropdown from "react-bootstrap/Dropdown";

import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../CommonCss/CommonCss.css";

import { Context } from "../../../../utils/context";

import { Link } from "react-router-dom";

const Department = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
  } = useContext(Context);

  const [addmodalShow, setAddModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(0);
  const [deleteModalShow, setDeleteModalShow] = useState({ show: false, heading: "", id: "" });

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [changeStatus, setChangeStatus] = useState();
  const [option, setOption] = useState();

  //  column hide and show.....
  const [short, setShort] = useState({
    col1: false,
    col2: false,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setShort((prev) => ({
      col1: columnName === "col1" ? !prev.col1 : false,
      col2: columnName === "col2" ? !prev.col2 : false,
    }));
  };

  const handleClose1 = () => setEditModalShow(0);
  const handleShow1 = (id) => {
    setEditModalShow(id);
  };

  const getDataAll = async () => {
    const response = await getData(
      `/hrms-masters/department?page=${currentPage}&per_page=${perPage}&term=${search}&short_name=${short.col1}&short_created=${short.col2}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, editmodalShow, addmodalShow, short]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/hrms-masters/department/${id}`);
    setChangeStatus(response);
  };

  const handleDeleteRecord = async (id) => {
    const response = await deleteData(
      `/hrms-masters/department/${id}`
    );
    setDeleteModalShow({ show: false, heading: "", id: "" })
    setChangeStatus(response);
  }


  return (
    <section className="main-common-class-hrms">
      <div className="white-bggg">
        <Row>
          <Col xxl={7} xl={6} lg={4} md={12} sm={12} xs={12}>
            <p className="main-table-head">Department</p>
          </Col>
          <Col xxl={5} xl={6} lg={8} md={12} sm={12} xs={12}>
            <div className="add d-md-flex justify-content-lg-end">
              <Button
                className="custom-apply-leaves-btn me-2"
                onClick={() => setAddModalShow(true)}
              >
                Add Department
                <span className="icon-container">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              </Button>
              <Dropdown>
                <Dropdown.Toggle
                  className="custom-apply-leaves-btn"
                  id="dropdown-basic"
                >
                  Sort By
                  <span className="icon-container">
                    <FontAwesomeIcon icon={faArrowUpWideShort} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col1")}
                        href="#"
                      >
                        Department
                        {short?.col1 ? (
                          <>
                            <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" /> Zz-Aa
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />  Aa-Zz
                          </>
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    {" "}
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={(event) => toggleColumn(event, "col2")}
                        href="#"
                      >
                        Date
                        {short?.col2 ? (
                          <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />
                        ) : (
                          // <FontAwesomeIcon className="ms-2" icon="fa-solid fa-arrow-down" />
                          <></>
                        )}
                      </Link>
                    </li>
                  </Dropdown.Item>
                </Dropdown.Menu>

              </Dropdown>
            </div>
          </Col>
          <AddModal show={addmodalShow} onHide={() => setAddModalShow(false)} />
        </Row>
        <div className="grey-bggg">
          <Row>
            <Col xxl={3} xl={4} lg={7} md={7} sm={12} xs={12}>
              <div class="show-entries mt-2">
                <label for="entries">Show</label>
                <select
                  className="form-select form-select-sm"
                  aria-label=".form-select-sm example"
                  onChange={(e) => setperPage(e.target.value)}
                >
                  {option?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <label for="entries" className="ms-2">
                  Entries
                </label>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={12} xs={12}>
              <div className="sowing me-2">
                <p className="text-end showing-txttt mt-2">{`Showing ${Math.min(
                  (currentPage - 1) * perPage + 1
                )} to ${Math.min(
                  currentPage * perPage,
                  data?.data?.totalEntries
                )} of ${data?.data?.totalEntries} Entries`}</p>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12}>
              <div className="d-flex">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search Here"
                    aria-label="Search Here"
                    aria-describedby="basic-addon1"
                    id=""
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
            <Col xxl={3} xl={4} lg={6} md={6} sm={6} xs={12}>
              <button
                type="button"
                onClick={getDataAll}
                className="btn btn-search mb-3 me-2"
              >
                Search
              </button>
              <button
                type="button"
                onClick={() => {
                  setSearch("");
                  setReset(!reset);
                }}
                className="btn reset-btnn mb-3"
              >
                Reset
              </button>
            </Col>
          </Row>
        </div>
        <div className="common-table-hrms">
          <Table striped bordered responsive>
            <thead>
              <tr className="">
                <th className="sr">Sr. No.</th>
                <th className="tax-name">Department</th>
                <th className="tax-name">Status</th>
                <th className="active">Action</th>
              </tr>
            </thead>
            <tbody className="table-body-holder">
              {isAllow.includes(73) ? (
                data?.data?.data?.map((d, index) => {
                  return (
                    <tr className="" key={index}>
                      <td>{++index}.</td>
                      <td>{d?.name}</td>
                      <td>
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={d.status}
                            onChange={() => {
                              ChangeStatus(d.id);
                            }}
                            id={`flexSwitchCheckDefault${d?.id}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`flexSwitchCheckDefault${d?.id}`}
                          >
                            {d.status ? "Active" : "Inactive"}
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          {isAllow.includes(75) ? (
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className="table-iconnnn "
                              onClick={() => handleShow1(d?.id)}
                              // onClick={() => handleShow1(d?.id)}
                              type="button"
                            />
                          ) : (
                            // <img src={pen} className="pen" alt="" />

                            <></>
                          )}
                          {isAllow.includes(76) ? (
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="table-iconnnn ms-2"
                              onClick={() => setDeleteModalShow({ show: true, heading: "Department", id: d?.id })}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {editmodalShow ? (
        <EditModal
          show={editmodalShow}
          handleClose={handleClose1}
          setShow={setEditModalShow}
        />
      ) : (
        ""
      )}

      <DeleteModal
        heading={deleteModalShow?.heading}
        show={deleteModalShow?.show}
        onHide={() => setDeleteModalShow({ show: false, heading: "", id: "" })}
        handleDelete={() => handleDeleteRecord(deleteModalShow?.id)}
      />
    </section>
  );
};

export default Department;
