import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select';



const EmploymentDetails = ({
    getValues, register, classNames, errors, sifts, departments, designations, employeeTypes, employmentTypes,
    Controller, control, disabled, setModalDepartment, setModalDesignation, watch
}) => {
    return (
        <div>
            <div className='below-formmmmm'>
                <Form>
                    <Row>
                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Department</Form.Label>
                                <div className='d-flex justify-content-between select-add-div'>
                                    <Controller
                                        className="select-contoller"
                                        name={`employementdata.department_id`}
                                        {...register(`employementdata.department_id`, {
                                            required: "Select Department",
                                        })}
                                        control={control}
                                        rules={{
                                            required: "Select Department",
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors?.employementdata?.department_id ? "red" : baseStyles.borderColor,
                                                    }),
                                                }}
                                                // {...field} 
                                                options={departments}
                                                onChange={(selectedValue) => {
                                                    onChange(selectedValue);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                ref={ref}
                                                isDisabled={disabled}
                                            />
                                        )}
                                    />
                                    {!disabled &&
                                        <button className='add-button-with-select' type='button' width="10"
                                            onClick={() => setModalDepartment(true)}
                                        ><FontAwesomeIcon icon={faPlus} width="10" /></button>
                                    }
                                </div>

                                {errors?.employementdata?.department_id && (
                                    <span className="text-danger">
                                        {errors?.employementdata?.department_id.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Designation</Form.Label>
                                <div className='d-flex justify-content-between select-add-div'>
                                    <Controller
                                        className="select-contoller"
                                        name={`employementdata.designation_id`}
                                        {...register(`employementdata.designation_id`, {
                                            required: "Select Designation",
                                        })}
                                        control={control}
                                        rules={{
                                            required: "Select Department",
                                        }}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Select
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        borderColor: errors?.employementdata?.designation_id ? "red" : baseStyles.borderColor,
                                                    }),
                                                }}
                                                // {...field} 
                                                options={designations}
                                                onChange={(selectedValue) => {
                                                    onChange(selectedValue);
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                ref={ref}
                                                isDisabled={disabled}
                                            />
                                        )}
                                    />
                                    {!disabled &&
                                        <button className='add-button-with-select' type='button' width="10"
                                            onClick={() => setModalDesignation(true)}
                                        ><FontAwesomeIcon icon={faPlus} width="10" /></button>
                                    }
                                </div>

                                {errors?.employementdata?.designation_id && (
                                    <span className="text-danger">
                                        {errors?.employementdata?.designation_id.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Emp Type</Form.Label>
                                <Controller

                                    name={`employementdata.employee_type_id`}
                                    {...register(`employementdata.employee_type_id`, {
                                        required: "Select Employee Type",
                                    })}
                                    control={control}
                                    rules={{
                                        required: "Select Emp Type",
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors?.employementdata?.employee_type_id ? "red" : baseStyles.borderColor,
                                                }),
                                            }}
                                            // {...field} 
                                            options={employeeTypes}
                                            onChange={(selectedValue) => {
                                                onChange(selectedValue);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            ref={ref}
                                            isDisabled={disabled}
                                        />
                                    )}
                                />
                                {errors?.employementdata?.employee_type_id && (
                                    <span className="text-danger">
                                        {errors?.employementdata?.employee_type_id.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Employment Type</Form.Label>
                                <Controller

                                    name={`employementdata.employment_type_id`}
                                    {...register(`employementdata.employment_type_id`, {
                                        required: "Select Employment Type",
                                    })}
                                    control={control}
                                    rules={{
                                        required: "Select Employment Type",
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors?.employementdata?.employment_type_id ? "red" : baseStyles.borderColor,
                                                }),
                                            }}
                                            // {...field} 
                                            options={employmentTypes}
                                            onChange={(selectedValue) => {
                                                onChange(selectedValue);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            ref={ref}
                                            isDisabled={disabled}
                                        />
                                    )}
                                />
                                {errors?.employementdata?.employment_type_id && (
                                    <span className="text-danger">
                                        {errors?.employementdata?.employment_type_id.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>

                        {/* <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Employee Id</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`employementdata.employee_id`}
                                    placeholder="Passing Year"
                                    className={classNames("", {
                                        "is-invalid": errors?.employementdata?.employee_id,
                                    })}
                                    {...register(`employementdata.employee_id`, {
                                        required: "Employee id required",
                                    })}
                                />
                                {errors?.employementdata?.employee_id && (
                                    <div className="invalid-feedback">
                                        {errors.employementdata.employee_id.message} 
                                    </div>
                                )}
                            </Form.Group>
                        </Col> */}

                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Login Email Id</Form.Label>
                                <Form.Control
                                    disabled={disabled}
                                    type="text"
                                    name={`userdata.email`}
                                    placeholder="Email"
                                    className={classNames("", {
                                        "is-invalid": errors?.userdata?.email,
                                    })}
                                    {...register(`userdata.email`, {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/,
                                            message: "Invalid Email address",
                                        },
                                        validate: (value) => {
                                            // Extract the domain and TLD
                                            const domainPattern = /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z0-9_\-\.]+)\.\2)([A-Za-z0-9_\-\.]+\.)+([A-Za-z]{2,4})$/;
                                            const match = value.match(domainPattern);
                                            if (match) {
                                                const domainParts = match[1].split('.');
                                                const tld = match[2];

                                                // Ensure the domain and TLD are not the same
                                                if (domainParts[domainParts.length - 1] === tld) {
                                                    return "Domain and top-level domain must be different";
                                                }
                                            }
                                            return true;
                                        }
                                    })}

                                />
                                {errors?.userdata?.email && (
                                    <span className="text-danger">
                                        {errors?.userdata?.email.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>


                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    disabled={disabled}
                                    type="password"
                                    name={`userdata.password`}
                                    placeholder="Password"
                                    className={classNames("", {
                                        "is-invalid": errors?.userdata?.password,
                                    })}
                                    {...register(`userdata.password`, {
                                        required: "Password is required",
                                    })}
                                />
                                {errors?.userdata?.password && (
                                    <span className="text-danger">
                                        {errors?.userdata?.password.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Shift</Form.Label>
                                <Controller

                                    name={`employementdata.shift_id`}
                                    {...register(`employementdata.shift_id`, {
                                        required: "Select Shift",
                                    })}
                                    control={control}
                                    rules={{
                                        required: "Select Department",
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors?.employementdata?.shift_id ? "red" : baseStyles.borderColor,
                                                }),
                                            }}
                                            // {...field} 
                                            options={sifts}
                                            onChange={(selectedValue) => {
                                                onChange(selectedValue);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            ref={ref}
                                            isDisabled={disabled}
                                        />
                                    )}
                                />
                                {errors?.employementdata?.shift_id && (
                                    <span className="text-danger">
                                        {errors?.employementdata?.shift_id.message}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Joining Date</Form.Label>
                                <Form.Control
                                    disabled={disabled}
                                    type="date"
                                    name={`employementdata.joining_date`}
                                    placeholder="Select Date"
                                    className={classNames("", {
                                        "is-invalid": errors?.employementdata?.joining_date, // Adjusted error checking
                                    })}
                                    {...register(`employementdata.joining_date`, {
                                        required: "Date  required",
                                    })}
                                />
                                {errors?.employementdata?.joining_date && (
                                    <div className="invalid-feedback">
                                        {errors.employementdata.joining_date.message} {/* Display the error message */}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Leaving Date</Form.Label>
                                <Form.Control
                                    disabled={disabled}
                                    type="date"
                                    min={watch('employementdata.joining_date')}
                                    name={`employementdata.leaving_date`}
                                    placeholder="Select Date"
                                    className={classNames("", {
                                        "is-invalid": errors?.employementdata?.leaving_date, // Adjusted error checking
                                    })}
                                    {...register(`employementdata.leaving_date`, {
                                        // required: "Date  required",
                                        validate: {
                                            // Custom validation to ensure to_date is greater than from_date
                                            greaterThanFromDate: (value) => {
                                                const fromDate = watch('employementdata.joining_date');
                                                if (fromDate && value && new Date(value) <= new Date(fromDate)) {
                                                    return "Leaving Date must be greater than Jioning Date."; // Error message if to_date is not greater than from_date
                                                }
                                                return true; // Validation passed
                                            },
                                        }
                                    })}
                                />
                                {errors?.employementdata?.leaving_date && (
                                    <div className="invalid-feedback">
                                        {errors.employementdata.leaving_date.message} {/* Display the error message */}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xl={6} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Reporting Manager Name</Form.Label>
                                <Form.Control
                                    disabled={disabled}
                                    type="text"
                                    name={`employementdata.reporting_to`}
                                    placeholder="Reporting Manager"
                                    className={classNames("", {
                                        "is-invalid": errors?.employementdata?.reporting_to, // Adjusted error checking
                                    })}
                                    {...register(`employementdata.reporting_to`, {
                                        required: "Reporting Manager required",
                                        pattern: {
                                            value: /^[A-Za-z\s]*$/,
                                            message: "Only letters and spaces are allowed",
                                        },
                                    })}
                                    onKeyDown={(e) => {
                                        // Allow only letters and spaces
                                        if (!/^[A-Za-z\s]$/.test(e.key) && e.key !== "Backspace") {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                {errors?.employementdata?.reporting_to && (
                                    <div className="invalid-feedback">
                                        {errors.employementdata.reporting_to.message} {/* Display the error message */}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                        {/* <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>Shift Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Shift name" />
                            </Form.Group>
                        </Col>
                        <Form.Label>Shift Time</Form.Label>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>From Time</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label>To Time</Form.Label>
                                <Form.Control type="date" />
                            </Form.Group>
                        </Col> */}
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default EmploymentDetails