import React, { useContext, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Row, Col, Modal, Spinner } from "react-bootstrap"; // Added Spinner import
import CustomDatePicker from "../Admin/CustomDatePicker";
import Select from "react-select";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";

const PersonalContacts = (props) => {
  const { postData, getData } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state
  const handleSubmitClose = () => setSubmit(false);
  const handleSubmitShow = () => {
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
    }, 5000);
  };
  const [formData, setFormData] = useState({
    id: "",
    lead_id: props?.lead_id,
    country_id: "",
    state_id: "",
    city_id: "",
    pincode_id: "",
    f_name: "",
    m_name: "",
    l_name: "",
    mobile_no: "",
    alt_mobile_no: "",
    gender: "",
    email: "",
    alt_email: "",
    dob: "",
    address: "",
    alt_address: "",
  });

  // handle change
  const handleChange = (e) => {
    if (
      e?.name === "country_id" ||
      e?.name === "state_id" ||
      e?.name === "city_id" ||
      e?.name === "pincode_id"
    ) {
      if (e?.name === "country_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          state_id: "",
          city_id: "",
          pincode_id: "",
        });
        getSelectData(e.name, e.value);
      } else if (e?.name === "state_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          city_id: "",
          pincode_id: "",
        });
        getSelectData(e.name, e.value);
      } else if (e?.name === "city_id") {
        setFormData({
          ...formData,
          [e.name]: e,
          pincode_id: "",
        });

        getSelectData(e.name, e.value);
      } else if (e?.name === "pincode_id") {
        setFormData({
          ...formData,
          [e.name]: e,
        });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader
    try {
      const response = await postData(
        "/lead_management/lead-personal-contacts",
        formData
      );
      if (response?.success) {
        setFormData({
          ...formData,
          id: response?.data?.id,
        });
        handleSubmitShow();
        setErrors({});
      } else {
        if (response?.message?.errors?.id) {
          props.handleErrorShow();
        } else {
          setErrors(response?.message?.errors);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setLoading(false); // Hide loader
  };

  // getselecteddata
  const getSelectData = async (name = null, id = null) => {
    if (name === "country_id") {
      const response = await getData(`/masters/allstate/${id}`);

      const option = [];
      if (response?.success) {
        response?.data?.map((data) => {
          option.push({
            value: data.id,
            name: "state_id",
            label: data.name,
          });
        });
        setState(option);
      }
    } else if (name === "state_id") {
      const response = await getData(`/masters/allcity/${id}`);

      const option = [];
      if (response?.success) {
        response?.data?.map((data) => {
          option.push({
            value: data.id,
            name: "city_id",
            label: data.name,
          });
        });
        setCity(option);
      }
    } else if (name === "city_id") {
      const response = await getData(`/masters/allpincode/${id}`);

      const option = [];
      if (response?.success) {
        response?.data?.map((data) => {
          option.push({
            value: data.id,
            name: "pincode_id",
            label: data.name,
          });
        });
        setPincode(option);
      }
    } else {
      const res = await getData("/masters/allcountry");

      const option = [];
      if (res?.success) {
        res?.data?.map((data) => {
          option.push({
            value: data.id,
            name: "country_id",
            label: data.name,
          });
        });
      }
      setCountry(option);
    }
  };

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const getEditData = async () => {
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      const response = await getData(
        `/lead_management/lead-personal-contacts/${lead_id}`
      );
      setFormData(response?.data);
    }
  };

  useEffect(() => {
    getSelectData();
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      getEditData();
    } else {
      setFormData({
        id: "",
        country_id: "",
        state_id: "",
        city_id: "",
        pincode_id: "",
        f_name: "",
        m_name: "",
        l_name: "",
        mobile_no: "",
        alt_mobile_no: "",
        gender: "",
        email: "",
        alt_email: "",
        dob: "",
        address: "",
        alt_address: "",
      });
    }
  }, [props?.formStatus]);

  const dobDate = formData?.dob ? new Date(formData.dob) : null;

  return (
    <div className="col-lg-4">
      <div className="main-box ">
        <div className="box-title-div">
          <div className="row">
            <div className="col-md-10 pe-0">
              <div className="sec-1">
                <p className="call-statusp">Personal & Contacts </p>
              </div>
            </div>
            <div className="col-md-2 ps-0">
              <div className="sec-2">
                <div className="d-flex justify-content-center">
                  <Button
                    className="save-fafa p-0 btn"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="save-fafa"
                        icon="fa-solid fa-floppy-disk"
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="box-content-div pc-sec seleee">
          <Form>
            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <Form.Label className="label-name">
                First Name <span>*</span>{" "}
              </Form.Label>

              <Form.Control
                type="text"
                name="f_name"
                value={formData?.f_name}
                onChange={handleChange}
                placeholder="Enter First Name"
              />
              {errors ? <p>{errors?.f_name?.msg}</p> : null}
            </Form.Group>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <Form.Label className="label-name-blur">Middle Name </Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter Middle Name"
                name="m_name"
                value={formData?.m_name}
                onChange={handleChange}
              />
              {errors ? <p>{errors?.m_name?.msg}</p> : null}
            </Form.Group>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <Form.Label className="label-name">
                Last Name <span>*</span>{" "}
              </Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                value={formData?.l_name}
                name="l_name"
                onChange={handleChange}
              />
              {errors ? <p>{errors?.l_name?.msg}</p> : null}
            </Form.Group>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <div className="row mt-2">
                <div className="col-md-4">
                  <label className="label-name">Gender </label>
                </div>

                <div className="col-md-4">
                  <Form.Check
                    inline
                    label="Male"
                    name="gender"
                    value="male"
                    type={"radio"}
                    checked={formData?.gender == "male" ? true : false}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4">
                  <Form.Check
                    inline
                    label="Female"
                    name="gender"
                    value="female"
                    type={"radio"}
                    checked={formData?.gender == "female" ? true : false}
                    onChange={handleChange}
                  />
                </div>
                {errors ? <p>{errors?.gender?.msg}</p> : null}
              </div>
            </Form.Group>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <div className="row">
                <div className="col-md-6">
                  <Form.Label className="label-name">
                    Contact 1 <span>*</span>{" "}
                  </Form.Label>

                  <Form.Control
                    type="tel"
                    pattern="[0-9]{10}"
                    maxLength="10"
                    placeholder="Enter contact no"
                    name="mobile_no"
                    onKeyPress={handleKeyPressContact}
                    onChange={handleChange}
                    value={formData?.mobile_no}
                    required
                  />
                  {errors ? <p>{errors?.mobile_no?.msg}</p> : null}
                </div>

                <div className="col-md-6">
                  <Form.Label className="label-name-blur">Contact 2</Form.Label>

                  <Form.Control
                    type="tel"
                    pattern="[0-9]{10}"
                    maxLength="10"
                    placeholder="Enter contact no"
                    name="alt_mobile_no"
                    onKeyPress={handleKeyPressContact}
                    value={formData?.alt_mobile_no}
                    onChange={handleChange}
                  />
                  {errors ? <p>{errors?.alt_mobile_no?.msg}</p> : null}
                </div>
              </div>
            </Form.Group>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <Form.Label className="label-name">
                Email<span>*</span>{" "}
              </Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter email"
                onChange={handleChange}
                value={formData?.email}
                name="email"
              />
              {errors ? <p>{errors?.email?.msg}</p> : null}
            </Form.Group>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <Form.Label className="label-name-blur">
                Email (optional)
              </Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter email"
                onChange={handleChange}
                value={formData?.alt_email}
                name="alt_email"
              />
              {errors ? <p>{errors?.alt_email?.msg}</p> : null}
            </Form.Group>

            <div className="form-group">
              <label className="label-name">Date of Birth</label>

              <CustomDatePicker
                wrapperClassName="datepicker"
                className="form-control ms-4"
                selected={dobDate}
                placeholder="Date Of Birth"
                onChange={(e) => {
                  const selectedDate = new Date(e);
                  const currentDate = new Date();
                  currentDate.setHours(0, 0, 0, 0);
                  if (selectedDate <= currentDate) {
                    setFormData({
                      ...formData,
                      dob: e,
                    });
                  } else {
                    const newerrors = { ...errors };
                    newerrors.dob = {
                      msg: "Please select a current or past date.",
                    };
                    setErrors(newerrors);
                  }
                }}
                minDate={new Date()}
              />
              {/* Uncomment this line to display error message if needed */}
              {/* {errors ? <p>{errors?.dob?.msg}</p> : null} */}
            </div>

            <div className="form-group mb-1">
              <div className="row ">
                <div className="col-md-6">
                  <Select
                    placeholder="Select Country"
                    className="custom-select"
                    name="country_id"
                    value={formData?.country_id}
                    onChange={handleChange}
                    options={country}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    placeholder="Select State"
                    className="custom-select "
                    name="state_id"
                    value={formData?.state_id}
                    onChange={handleChange}
                    options={state}
                  />
                </div>

                <div className="col-md-6 ">
                  <Select
                    placeholder="Select City"
                    className="custom-select "
                    name="city_id"
                    value={formData?.city_id}
                    onChange={handleChange}
                    options={city}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    placeholder="Select Pincode"
                    className="custom-select "
                    name="pincode_id"
                    value={formData?.pincode_id}
                    onChange={handleChange}
                    options={pincode}
                  />
                </div>
              </div>
            </div>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <Form.Label className="label-name">
                Address 1 <span>*</span>{" "}
              </Form.Label>

              <Form.Control
                type="text"
                name="address"
                placeholder=""
                value={formData?.address}
                onChange={handleChange}
              />
              {errors ? <p>{errors?.address?.msg}</p> : null}
            </Form.Group>

            <Form.Group className="form-group" controlId="formPlaintextEmail">
              <Form.Label className="label-name-blur">Address 2 </Form.Label>

              <Form.Control
                type="text"
                name="alt_address"
                placeholder=""
                value={formData?.alt_address}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </div>
      </div>

      <section>
        <Modal
          show={submit}
          onHide={handleSubmitClose}
          backdrop="static"
          keyboard={false}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="addproduct-pop-section"
        >
          <Modal.Header closeButton>
            <Modal.Title>Personal & Contacts Details Submitted</Modal.Title>
          </Modal.Header>
          <div className="box">
            <div className="success alert">
              <div className="alert-body">Success !</div>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
};

export default PersonalContacts;
