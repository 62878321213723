import axiosClient from "./ApiInstance";
import Cookies from "js-cookie";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(
      process.env.REACT_APP_BASE_URL + url,
      data
    );
    return response.data;
  } catch (error) {
    // Cookies.remove("it_pulse_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("it_pulse_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const editStatusData = async (url, data = {}) => {
  try {
    const response = await axiosClient.put(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("it_pulse_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axiosClient.delete(
      process.env.REACT_APP_BASE_URL + url
    );

    return response.data;
  } catch (error) {
    // Cookies.remove("it_pulse_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};

// Download Apis
export const getDownloadDataExcel = async (aurl, data, name) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + aurl,
      data,
      {
        responseType: "blob", // Set responseType to "blob"
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${name + " " + new Date().toLocaleDateString()}.xlsx`
    );
    document.body.appendChild(link);
    link.click();

    // Clean up after the download
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Cookies.remove("it_pulse_security", { path: "/" });
    // window.location.href = "/";
    console.log(error);
  }
};
