import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import './ApplyLeaveModal.css';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'; // Import the specific icon
import ApplyLeaveLottieModal from './ApplyLeaveLottieModal';
import { Controller, useForm } from "react-hook-form";
import { Context } from "../../../utils/context";
import AddedLottieModal from "../commonLottie/AddedLottieModal";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { DatePicker } from "antd";
import { format } from "date-fns";
import Select from 'react-select';
import classNames from "classnames";

const { RangePicker } = DatePicker;
const ApplyLeaveModal = (props) => {


    const [showLottie, setshowLottie] = useState(false);

    const { postData, getData, Select2Data, userdata } = useContext(Context);
    // const [showLottie, setShowLottie] = useState({ show: false, msg: "", success: false });

    const minDate = new Date();
    const duration = [
        { value: 'Single', name: 'duration', label: 'Single' },
        { value: 'Multiple', name: 'duration', label: 'Multiple' },
        { value: 'Half Day', name: 'duration', label: 'Half Day' }
    ]

    const [dates, setDates] = useState([]);

    const [leaveTypes, setLeaveTypes] = useState([]);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        watch,
        setValue,
        reset,
        setError,
        clearErrors
    } = useForm();

    useEffect(() => {
        setValue("date", dates);
        clearErrors("date");
    }, [dates]);

    useEffect(() => {
        clearErrors("date");
    }, [getValues('date')]);


    const onSubmit = async (data) => {
        if (!dates.length || !getValues("date")) {
            setError("date", { type: "manual", message: "Date is required" });
            return; // Prevent submission if date is not valid
        }
        try {
            const sendData = new FormData();
            sendData.append("leave_type_id", data?.leave_type_id?.value);
            sendData.append("user_id", userdata?.id);
            sendData.append("duration", data?.duration);
            sendData.append("reason", data?.reason);
            if (data?.duration === "Single" || data?.duration === "Half Day") {
                sendData.append("start", data.date[0]);
                sendData.append("end", data.date[0]);
            } else {
                sendData.append("start", data.date[0]);
                sendData.append("end", data.date[1]);
            }

            console.log("sendData", sendData);

            const response = await postData(`/hrms-masters/leave`, sendData);
            if (response?.success) {
                setshowLottie(true);
                reset();
            } else {
                setshowLottie(true);
            }
            setTimeout(() => {
                setshowLottie(false);
                props?.onHide();
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    };


    const GetAllLeaveTypes = async () => {
        const response = await getData("/masters/leave_type");

        if (response?.success) {
            setLeaveTypes(await Select2Data(response?.data, "leave_type_id"));
        }
    };

    useEffect(() => {
        GetAllLeaveTypes();
    }, []);


    return (
        <div >
            <Modal
                {...props}
                size="md"
                className='ApplyLeaveModal'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Body >
                    <Form onSubmit={handleSubmit(onSubmit)}>

                        <div className="ApplyLeaveModal-div">
                            <Col >
                                <div className="main-form-section mt-5">
                                    <Row className="justify-content-center">
                                        <Form.Label>
                                            Select Leave Type
                                        </Form.Label>
                                        <Controller
                                            name={`leave_type_id`}
                                            {...register(`leave_type_id`, {
                                                required: "Select Leave Type",
                                            })}
                                            control={control}
                                            rules={{
                                                required: "Select Leave Type",
                                            }}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Select
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors?.leave_type_id ? "red" : baseStyles.borderColor,
                                                        }),
                                                    }}
                                                    // {...field} 
                                                    options={leaveTypes}
                                                    onChange={(selectedValue) => {
                                                        onChange(selectedValue);
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                    ref={ref}

                                                />
                                            )}
                                        />
                                        {errors?.leave_type_id && (
                                            <span className="text-danger">
                                                {errors?.leave_type_id.message}
                                            </span>
                                        )}
                                    </Row>
                                </div>
                            </Col>
                            <Col >
                                <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">
                                        <Form.Label>
                                            Select Duration
                                        </Form.Label>
                                        <Col>
                                            <div className="d-flex">
                                                {duration && duration?.map((item) =>
                                                    <Form.Check

                                                        inline
                                                        label={item?.label}
                                                        value={item?.value}
                                                        name="duration"
                                                        type="radio"
                                                        {...register(`duration`, {
                                                            required: "Select Duration",
                                                        })}
                                                    />
                                                )}
                                            </div>
                                            {errors?.duration && (
                                                <span className="text-danger">
                                                    {errors?.duration.message}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col >
                                <div className="main-form-section mt-3">
                                    <Row className="justify-content-center">
                                        <Form.Label>
                                            Date
                                        </Form.Label>
                                        <Col>
                                            {watch("duration") === "Single" ||
                                                watch("duration") === "Half Day" ? (
                                                <DatePicker
                                                    disabledDate={(current) => current && current < minDate}
                                                    className="input-mandatory"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            const formattedDate = format(new Date(date), "yyyy-MM-dd");
                                                            setDates([formattedDate]);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <RangePicker
                                                    disabledDate={(current) => current && current < minDate}
                                                    className="input-mandatory"
                                                    // disabledDate={disabledDate}
                                                    onChange={(values) => {
                                                        if (values && values.length > 0) {
                                                            const formattedDates = values.map(
                                                                (item) =>
                                                                    `${item.$y}-${++item.$M}-${item.$D
                                                                    }`
                                                            );
                                                            setDates(formattedDates);
                                                        }
                                                    }}
                                                />
                                            )}

                                        </Col>
                                        {errors?.date && (
                                            <span className="text-danger">
                                                {errors?.date.message}
                                            </span>
                                        )}
                                    </Row>
                                </div>
                            </Col>

                            <div className='ApplyLeaveModal-reason'>
                                <label>Leave Reason</label>
                                <div className='reason-div'>
                                    <Form.Control
                                        as="textarea"
                                        name={`reason`}
                                        placeholder="Reason For Leave...."
                                        className={classNames("", {
                                            "is-invalid": errors?.reason,
                                        })}
                                        {...register(`reason`, {
                                            required: "Reason is required",
                                        })}
                                    />
                                    {errors?.reason && (
                                        <span className="text-danger">
                                            {errors?.reason?.message}
                                        </span>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className='button-div'>
                            <button type="submit" className='btn'>Apply Leave</button>
                        </div>
                    </Form>
                </Modal.Body>


                <div className='cross-div' onClick={props.onHide}>
                    <FontAwesomeIcon className='icon' icon={faCircleXmark} />
                </div>
            </Modal>

            <ApplyLeaveLottieModal pageTitle="Designation" show={showLottie}
                onHide={() => setshowLottie(false)} />

        </div>
    );
};

export default ApplyLeaveModal;


