import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import SucessfullyStatusModal from "./SucessfullyStatusModal";
import { useForm, Controller } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
const StatusChangemodal = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { getData, postData, Select2Data } = useContext(Context);

  const [complaint_status, setcomplaint_status] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    reset({ ids: props?.ids });
  }, [props?.ids]);

  const onSubmit = async (data) => {
    try {
      const response = await postData(
        `/complaint-management/complaints/status-change`,
        data
      );
      // console.log(response);
      if (response?.success) {
        await setModalStatus(response?.success);
      }
      reset();
      setTimeout(() => {
        setModalStatus(0);
        props.onHide();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllData = async () => {
    {
      const res = await getData(`/masters/all-complaint-status`);
      setcomplaint_status(await Select2Data(res.data, "complaint_status_id"));
    }
  };

  useEffect(() => {
    getAllData();
    reset();
  }, [props.show]);

  return (
    <>
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-class-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h6 className="mt-2"> Status Change</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Select Status</Form.Label>
              <Controller
                name="complaint_status_id" // name of the field
                {...register("complaint_status_id", {
                  required: "complaint status is required",
                })}
                control={control}
                render={({ field }) => (
                  <Select
                    isDisabled={props.view}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: errors.complaint_status_id
                          ? "red"
                          : baseStyles,
                      }),
                    }}
                    {...field}
                    options={complaint_status}
                  />
                )}
              />
              {/* <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
              /> */}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className={classNames("text-holder-area", {
                  "is-invalid": errors?.remark,
                })}
                {...register("remark", {
                  required: "remark is required",
                })}
                placeholder="Add remark here..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            type="button"
            onClick={handleSubmit(onSubmit)}
            // onClick={() => {
            //   setModalStatus(true);
            //   props.onHide();
            // }}
          >
            Change Status
          </Button>
        </Modal.Footer>
      </Modal>
      <SucessfullyStatusModal
        show={modalStatus}
        onHide={() => setModalStatus(false)}
      />
    </>
  );
};

export default StatusChangemodal;
