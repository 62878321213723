import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row, } from 'react-bootstrap'
import PersonalDetails from './AllEmployeeDetails/PersonalDetails';
import EducationalDetails from './AllEmployeeDetails/EducationalDetails';
import EmploymentDetails from './AllEmployeeDetails/EmploymentDetails';
import ResidentialAddress from './AllEmployeeDetails/ResidentialAddress'
import PreviousEmployer from './AllEmployeeDetails/PreviousEmployer'
import './AddEmployee.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import { Form } from 'react-bootstrap'

const ViewEmployee = () => {

    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

    const { id } = useParams();
    console.log("IMG_URL", IMG_URL);

    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currentStates, setCurrentStates] = useState([]);
    const [currenCities, setCurrenCities] = useState([]);
    const [currentPincodes, setCurrentPincodes] = useState([]);
    const [permanentStates, setPermanentStates] = useState([]);
    const [permanentCities, setPermanentCities] = useState([]);
    const [permanentPincodes, setPermanentPincodes] = useState([]);
    const [sifts, setSifts] = useState([]);
    const [employeeTypes, setEmployeTypes] = useState([]);
    const [employmentTypes, setEmploymentTypes] = useState([]);

    const navigate = useNavigate();

    const {
        control,
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        watch,
        setValue,
        reset
    } = useForm({
        defaultValues: {
            educations: [{}],
            previouses: [{}],
        },
    });


    const { fields: educationFields, append: appendEducation, remove: removeEducation } = useFieldArray({
        control,
        name: "educations",
    });

    const { fields: previousFields, append: appendPrevious, remove: removePrevious } = useFieldArray({
        control,
        name: "previouses",
    });

    console.log(getValues());

    const GetAllDepartments = async () => {
        const response = await getData("/masters/alldepartments");

        if (response?.success) {
            setDepartments(await Select2Data(response?.data, "department_id"));
        }
    };

    const GetAllDesignations = async () => {
        const response = await getData("/masters/alldesignation");

        if (response?.success) {
            setDesignations(await Select2Data(response?.data, "designation_id"));
        }
    };

    const GetAllQualifications = async () => {
        const response = await getData("/masters/allqualification");

        if (response?.success) {
            setQualifications(await Select2Data(response?.data, "qualification_id"));
        }
    };

    const GetAllUniversities = async () => {
        const response = await getData("/masters/alluniversity");

        if (response?.success) {
            setUniversities(await Select2Data(response?.data, "university_id"));
        }
    };

    const GetAllCourses = async () => {
        const response = await getData("/masters/allcourse");

        if (response?.success) {
            setCourses(await Select2Data(response?.data, "course_id"));
        }
    };

    const GetAllSift = async (id) => {
        const response = await getData(`/masters/allshifts`);

        if (response?.success) {
            setSifts(await Select2Data(response?.data, "shift_id"));
        }
    };

    const GetAllEmployeTypes = async (id) => {
        const response = await getData(`/masters/allemployeetypes`);

        if (response?.success) {
            setEmployeTypes(await Select2Data(response?.data, "employee_type_id"));
        }
    };

    const GetAllEmploymentTypes = async (id) => {
        const response = await getData(`/masters/allemploymenttypes`);

        if (response?.success) {
            setEmploymentTypes(await Select2Data(response?.data, "employment_type_id"));
        }
    };

    const GetAllCountries = async () => {
        const response = await getData("/masters/allcountry");

        if (response?.success) {
            setCountries(await Select2Data(response?.data, "country_id"));
        }
    };
    const GetAllState = async (id, isPermanent = false) => {
        const response = await getData(`/masters/allstate/${id}`);
        if (response?.success) {
            if (isPermanent) {
                setPermanentStates(await Select2Data(response?.data, "state_id"));
            } else {
                setCurrentStates(await Select2Data(response?.data, "state_id"));
            }
        }
    };
    const GetAllCities = async (id, isPermanent = false) => {
        const response = await getData(`/masters/allcity/${id}`);
        if (response?.success) {
            if (isPermanent) {
                setPermanentCities(await Select2Data(response?.data, "city_id"));
            } else {
                setCurrenCities(await Select2Data(response?.data, "city_id"));
            }
        }
    };
    const GetAllPincodes = async (id, isPermanent = false) => {
        const response = await getData(`/masters/allpincode/${id}`);
        if (response?.success) {
            if (isPermanent) {
                setPermanentPincodes(await Select2Data(response?.data, "pincode_id"));
            } else {
                setCurrentPincodes(await Select2Data(response?.data, "pincode_id"));
            }
        }
    };


    const GetEditData = async () => {
        console.log("ididid", id);
        const response = await getData(`/hrms/employee/${id}`);
        reset(response?.data)

    }

    useEffect(() => {
        GetAllDepartments();
        GetAllDesignations();
        GetAllQualifications();
        GetAllUniversities();
        GetAllCourses();
        GetAllCountries();
        GetAllSift();
        GetAllEmployeTypes();
        GetAllEmploymentTypes();
        // GetAllState();
        // GetAllCities();
        // GetAllPincodes();
        GetEditData()
    }, []);

    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        GetEditData()
    }, [id]);

    useEffect(() => {
        const currentCountryId = watch('currentadd.country_id');
        if (currentCountryId?.value) {
            GetAllState(currentCountryId?.value);
        }
        const permanentCountryId = watch('permanentadd.country_id');
        if (permanentCountryId?.value) {
            GetAllState(permanentCountryId?.value, true); // Flag to distinguish between current and permanent
        }
    }, [watch('currentadd.country_id'), watch('permanentadd.country_id')]);

    // Fetch cities when state changes for both current and permanent addresses
    useEffect(() => {
        const currentStateId = watch('currentadd.state_id');
        if (currentStateId?.value) {
            GetAllCities(currentStateId?.value);
        }
        const permanentStateId = watch('permanentadd.state_id');
        if (permanentStateId?.value) {
            GetAllCities(permanentStateId?.value, true);
        }
    }, [watch('currentadd.state_id'), watch('permanentadd.state_id')]);

    // Fetch pincodes when city changes for both current and permanent addresses
    useEffect(() => {
        const currentCityId = watch('currentadd.city_id');
        if (currentCityId?.value) {
            GetAllPincodes(currentCityId?.value);
        }
        const permanentCityId = watch('permanentadd.city_id');
        if (permanentCityId?.value) {
            GetAllPincodes(permanentCityId?.value, true);
        }
    }, [watch('currentadd.city_id'), watch('permanentadd.city_id')]);

    return (
        <section className='main-common-class-hrms'>
            <div className='add-employee-table'>
                <div className='white-bggg mt-3'>
                    <p className='main-table-head'><FontAwesomeIcon onClick={() => navigate("/hrms-admin/hrms-employee", { replace: true })}
                        icon={faArrowLeft} className='arrow-iconnn me-3' />View Employee</p>
                    <Form  >
                        <Row>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                {/* Personal Details */}
                                <div className='sub-white-bgg'>
                                    <h4 className='grey-txtt'>Personal Details</h4>
                                    <PersonalDetails
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        watch={watch}
                                        setValue={setValue}
                                        IMG_URL={IMG_URL}
                                        disabled={disabled}
                                        Controller={Controller}
                                        control={control}
                                    />
                                </div>
                                {/* Educational Details */}
                                <div className='sub-white-bgg mt-3'>
                                    <h4 className='grey-txtt'>Educational Details</h4>
                                    <EducationalDetails
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        educationFields={educationFields}
                                        appendEducation={appendEducation}
                                        removeEducation={removeEducation}
                                        courses={courses}
                                        universities={universities}
                                        qualifications={qualifications}
                                        Controller={Controller}
                                        control={control}
                                        disabled={disabled}
                                    />
                                </div>
                                {/* Employment Details */}
                                <div className='sub-white-bgg mt-3'>
                                    <h4 className='grey-txtt'>Employment Details</h4>
                                    <EmploymentDetails
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        sifts={sifts}
                                        departments={departments}
                                        designations={designations}
                                        employeeTypes={employeeTypes}
                                        employmentTypes={employmentTypes}
                                        Controller={Controller}
                                        control={control}
                                        disabled={disabled}
                                        watch={watch}
                                    />
                                </div>
                            </Col>
                            <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                {/* Residential address */}
                                <div className='sub-white-bgg'>
                                    <h4 className='grey-txtt'>Residential address</h4>
                                    <ResidentialAddress
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        Controller={Controller}
                                        control={control}
                                        countries={countries}
                                        currentStates={currentStates}
                                        currenCities={currenCities}
                                        currentPincodes={currentPincodes}
                                        permanentStates={permanentStates}
                                        permanentCities={permanentCities}
                                        permanentPincodes={permanentPincodes}
                                        GetAllState={GetAllState}
                                        GetAllCities={GetAllCities}
                                        GetAllPincodes={GetAllPincodes}
                                        setValue={setValue}
                                        disabled={disabled}
                                        watch={watch}
                                    />
                                </div>
                                {/* Previous Employer */}
                                <div className='sub-white-bgg mt-3'>
                                    <h4 className='grey-txtt'>Previous Employer</h4>
                                    <PreviousEmployer
                                        getValues={getValues}
                                        register={register}
                                        classNames={classNames}
                                        errors={errors}
                                        Controller={Controller}
                                        control={control}
                                        setValue={setValue}
                                        previousFields={previousFields}
                                        appendPrevious={appendPrevious}
                                        removePrevious={removePrevious}
                                        designations={designations}
                                        departments={departments}
                                        employeeTypes={employeeTypes}
                                        employmentTypes={employmentTypes}
                                        disabled={disabled}
                                        watch={watch}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </section >
    )
}

export default ViewEmployee