import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

function FilesTab() {
  return (
    <>
      <div className="row">
        <div class="col-md-12">
          <h6 className="page-title ">Files</h6>
        </div>

        <div class="col-md-12">
          <li>
            <ul class="list-group b-t" id="sub-task-list"></ul>
          </li>
        </div>
      </div>
    </>
  );
}

export default FilesTab;
