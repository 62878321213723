import React from "react";
import { Bar } from "react-chartjs-2";
import { Canvas } from "react-three-fiber";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);
const BarChartnew = () => {
  const data = {
    labels: [
      "Day1",
      "Day2",
      "Day3",
      "Day4",
      "Day5",
      "Day6",
      "Day7",
      "Day8",
      "Day9",
      "Day10",
      "Day11",
      "Day12",
      "Day13",
      "Day14",
      "Day15",
      "Day16",
      "Day17",
      "Day18",
      "Day19",
      "Day20",
      "Day21",
      "Day22",
      "Day23",
      "Day24",
      "Day25",
      "Day26",
      "Day27",
      "Day28",
      "Day29",
      "Day30",
      "Day31",
    ],
    datasets: [
      {
        label: "Example Dataset",
        data: [10, 19, 8, 12, 10, 14, 6, 17, 5, 11, 9, 6, 2, 14, 17, 18, 19, 20, 21, 22, 24, 26, 1, 12, 10, 18, 25, 5, 10, 8, 2],
        backgroundColor: [
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
        ],
        innerWidth: [100],
        outerHeight: [80],
      },
    ],
  };
  return (
    <>

      <div className="row">
        <div className="col-md-9 col-xl-9  mt-2">
          <div className="graph">
            <div>
              <Bar data={data} />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default BarChartnew;