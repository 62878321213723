import React, { useState, useContext } from "react";
import "./Hsidebar.css";
import { Accordion } from "react-bootstrap";

import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";

const Hsidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const { isAllow } = useContext(Context);
  return (
    <>
      <section className={`hrms-sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Link to="/hrms-admin/hrms-dashboard">
              <Accordion.Header
                onClick={isCollapsed ? toggleSidebar : null}
                className="dashboard-btn"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/hrms-dashboard/Image/icon/dashboard.png"
                  }
                  className="main-accordian-icon-img"
                />{" "}
                {!isCollapsed && "Dashboard"}
              </Accordion.Header>
            </Link>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header
              className="accordion-header-holder "
              onClick={isCollapsed ? toggleSidebar : null}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/hrms-dashboard/Image/icon/XMLID_3791_.png"
                }
                className="main-accordian-icon-img"
              />{" "}
              {!isCollapsed && "HRMS Setup"}
            </Accordion.Header>

            <Accordion.Body>
              {/* nested accordian */}
              <div className="sub-accordian-main ms-3">
                <Accordion className="sub-accordian">
                  {/* <Accordion.Item eventKey="00">
                    <Link to='/hrms-admin/hrms-department'> <Accordion.Header className="accordion-header-holder "><img src={process.env.PUBLIC_URL + '/assets/hrms-dashboard/Image/icon/1.png'} className='accordian-icon-img' />{!isCollapsed && 'Dashboard'}</Accordion.Header></Link>
                  </Accordion.Item> */}
                  {isAllow.includes(241) ? (
                    <Accordion.Item eventKey="12">
                      <Link to="/hrms-admin/hrms-prefix">
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/Designation.png"
                              // <i class="fa-brands fa-autoprefixer"></i>
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Prefix"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(65) ? (
                    <Accordion.Item eventKey="01">
                      <Link to="/hrms-admin/hrms-designation">
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/Designation.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Designation"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(205) ? (
                    <Accordion.Item eventKey="08">
                      <Link to="/hrms-admin/hrms-setup-qualification">
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/qualification.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Qualification"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(209) ? (
                    <Accordion.Item eventKey="09">
                      <Link to="/hrms-admin/hrms-setup-university">
                        <Accordion.Header className="accordion-header-holder active">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/university.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "University"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(213) ? (
                    <Accordion.Item eventKey="10">
                      <Link to="/hrms-admin/hrms-setup-course">
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/course.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Course"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(61) ? (
                    <Accordion.Item eventKey="11">
                      <Link to="/hrms-admin/hrms-department">
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/Department.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Department"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(69) ? (
                    <Accordion.Item eventKey="02">
                      <Link to="/hrms-admin/hrms-skills">
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/skills.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Skills"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}

                  {isAllow.includes(229) ? (
                    <Accordion.Item eventKey="03">
                      <Link to="/hrms-admin/hrms-calendar">
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/Calender.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Calender"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}

                  {isAllow.includes(73) ? (
                    <Accordion.Item eventKey="04">
                      <Link to="/hrms-admin/hrms-leave-type">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/Leave-Type.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Leave Type"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}

                  {isAllow.includes(217) ? (
                    <Accordion.Item eventKey="12">
                      <Link to="/hrms-admin/hrms-leave-setup">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/leave-setup.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Leave Setup"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}

                  {isAllow.includes(193) ? (
                    <Accordion.Item eventKey="05">
                      <Link to="/hrms-admin/hrms-employee-type">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/employee-type.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Employee Type"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}

                  {isAllow.includes(197) ? (
                    <Accordion.Item eventKey="06">
                      <Link to="/hrms-admin/hrms-setup-assets">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/assets.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Assets"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(201) ? (
                    <Accordion.Item eventKey="07">
                      <Link to="/hrms-admin/hrms-setup-shifts">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/shift.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Shift"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(237) ? (
                    <Accordion.Item eventKey="08">
                      <Link to="/hrms-admin/hrms-employment-type">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/employee-type.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Employment Type"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header
              className="accordion-header-holder "
              onClick={isCollapsed ? toggleSidebar : null}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/hrms-dashboard/Image/icon/hr-management.png"
                }
                className="main-accordian-icon-img"
              />{" "}
              {!isCollapsed && "HRMS"}
            </Accordion.Header>
            <Accordion.Body>
              {/* nested accordian */}
              <div className="sub-accordian-main">
                <Accordion className="sub-accordian">
                  {isAllow.includes(5) ? (
                    <Accordion.Item eventKey="06">
                      <Link to="/hrms-admin/hrms-employee">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/Employee.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Employee"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(77) ? (
                    <Accordion.Item eventKey="07">
                      <Link to="/hrms-admin/hrms-leave">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/Leave.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Leave"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(233) ? (
                    <Accordion.Item eventKey="08">
                      <Link to="/hrms-admin/hrms-attendance">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/hrms-attendance.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Attendance"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}

                  {/* {isAllow.includes(65) ? ( */}
                  {/* <Accordion.Item eventKey="09">
                    <Link to="/hrms-admin/hrms-payment-slip">
                      {" "}
                      <Accordion.Header className="accordion-header-holder ">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/hrms-dashboard/Image/icon/Payment-Slip.png"
                          }
                          className="accordian-icon-img"
                        />
                        {!isCollapsed && "Payment Slip"}
                      </Accordion.Header>
                    </Link>
                  </Accordion.Item> */}
                  {/* ) : (
                    <></>
                  )} */}
                  {isAllow.includes(221) ? (
                    <Accordion.Item eventKey="010">
                      <Link to="/hrms-admin/hrms-assets">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/assets.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Assets"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                  {isAllow.includes(225) ? (
                    <Accordion.Item eventKey="011">
                      <Link to="/hrms-admin/hrms-shift">
                        {" "}
                        <Accordion.Header className="accordion-header-holder ">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/hrms-dashboard/Image/icon/shift.png"
                            }
                            className="accordian-icon-img"
                          />
                          {!isCollapsed && "Shift"}
                        </Accordion.Header>
                      </Link>
                    </Accordion.Item>
                  ) : (
                    <></>
                  )}
                </Accordion>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div
          className={`hrms-sidebar-footer ${isCollapsed ? "collapsed" : ""}`}
        >
          <div className="toggle-btn" onClick={toggleSidebar}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/hrms-dashboard/Image/icon/arrow.png"
              }
              className="arrow-icon"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Hsidebar;
