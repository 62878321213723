import React from "react";
import { Routes, Route } from "react-router-dom";
import ComplaintManagement from "./com-dashboard/ComplaintManagement";
import { Row, Col } from "react-bootstrap";
import ComSidebar from "./com-sidebar/ComSidebar";
import ComHeader from "./com-header/ComHeader";
import ComplaintList from "./compliant-list/ComplaintList";
import ComplaintReport from "./complaint-report/ComplaintReport";

const ComplaintRoutes = () => {
  const style = {
    height: "100vh",
    overflowY: "auto",
  };
  return (
    <>
      <section>
        <Row className="g-0">
          <Col lg={2} md={2}>
            <ComSidebar />
          </Col>
          <Col lg={10} md={10} className="dashboard-part" style={style}>
            <ComHeader />
            <Routes>
              <Route
                path="/complaint-manage"
                element={<ComplaintManagement />}
              />
              <Route path="/complaint-list-table" element={<ComplaintList />} />
              <Route
                path="/complaint-report-table"
                element={<ComplaintReport />}
              />
            </Routes>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default ComplaintRoutes;
