import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
const History = (props) => {
  const { getData } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async (id) => {
    const response = await getData(`/lead_management/history-lead/${id}`);
    await setData(response?.data);
  };

  useEffect(() => {
    getDataAll();
  }, []);
  useEffect(() => {
    const lead_id = Cookies.get("lead_id");
    if (lead_id) {
      getDataAll(lead_id);
    } else {
      setData([]);
    }
  }, [props?.formStatus]);

  return (
    <div className="main-box mt-4 ">
      <div className="box-title-div">
        <div className="row">
          <div className="col-md-10 pe-0">
            <div className="sec-1">
              <p className="call-statusp">History </p>
            </div>
          </div>
          <div className="col-md-2 ps-0">
            <div className="sec-2">
              <div className="d-flex justify-content-center">
                <Button className="save-fafa p-0 btn">
                  <FontAwesomeIcon
                    className=" save-fafa"
                    icon="fa-solid fa-up-right-and-down-left-from-center"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="box-content-div">
        <div className="tabs-main-sec tabs-sec-hight">
          {data?.map((data, index) => (
            <div className="row mb-1" key={index}>
              <div className="col-md-3">
                <p className="unit-pr-ppp">
                  {data?.user?.userroles[0]?.role.name}{" "}
                </p>
              </div>
              <div className="col-md-3">
                <p className="unit-pr-ppp">{data?.date} </p>
              </div>
              <div className="col-md-3 text-end">
                <p className="unit-pr-p"> {data?.time}</p>
              </div>
              <div className="col-md-3 text-end">
                <p className="unit-pr-p"> {data?.remark}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default History;
