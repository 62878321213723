import React, { useState } from "react";
import { SketchPicker } from "react-color"; // Import the color picker component from react-color
import { Form, InputGroup } from "react-bootstrap";
import { Button, Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
function UpdateTaskModal(props) {
  const [color, setColor] = useState("#000000"); // Initial color state
  const [showColorPicker, setShowColorPicker] = useState(false); // State to manage color picker visibility

  const handleChange = (newColor) => {
    setColor(newColor.hex); // Update color state with the selected color
  };

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker); // Toggle the color picker visibility
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        className="table_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Task Status{" "}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="field_top_space">
            <div className="row">
              <div className="col-md-12">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="">Column Name</Form.Label>

                    <Form.Control
                      type="text"
                      name="Column Name"
                      placeholder="Column Name"
                    />
                    <Form.Control type="hidden" name="Column Name" />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="field_top_space">
            <div className="row">
            <div className="col-md-6">
                <Form>
                  <Form.Group controlId="Color">
                    <Form.Label className="required">Color</Form.Label>
                    <div className="asColorPicker-wrap">
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          className="colorpicker form-control asColorPicker-input"
                          name="label_color"
                          value={color}
                          readOnly // Make the input readonly to prevent direct input
                          onClick={toggleColorPicker} // Show color picker on click
                        />

                        <InputGroup.Text
                          id="basic-addon1"
                          style={{ background: color }}
                        ></InputGroup.Text>
                      </InputGroup>
                      {showColorPicker && (
                        <SketchPicker color={color} onChange={handleChange} />
                      )}{" "}
                      {/* Conditional rendering of SketchPicker */}
                    </div>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form>
                  <Form.Group controlId="">
                    <Form.Label className="Position">Position</Form.Label>
                    <Form.Select as="select" name="Position">
                      <option value="1">Incomplete</option>
                      <option value="2">Complete</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <Form>
            <Button
              className="btn btn-success save-btn my-2 me-2"
              type="submit"
            >
              <FontAwesomeIcon icon="fa-solid fa-check" /> Save
            </Button>

            <Button className="btn btn-danger save-btn my-2 me-2" type="close">
              <FontAwesomeIcon icon="fa-solid fa-times" /> Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdateTaskModal;
