import React from 'react'
import { Modal } from 'react-bootstrap'

const HrmsLogout = (props) => {
    return (
        <section className='modal-common-class-hrms'>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="add-modal"
            >
                <Modal.Body>
                    <div className='text-center'>
                        <h4 className='modal-title-common'>Logout</h4>
                    </div>
                    <div className='below-bodyy'>
                        <div className='text-center'>
                            <p>Do You Want to
                                Logout ?</p>
                        </div>
                        <div className='text-center mt-5 mb-5'>
                            <button className='cancel-button me-2 ' onClick={props.onHide}>Cancel</button>
                            <button className='save-button' onClick={props.LogOut}>Yes</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default HrmsLogout